import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Link as MaterialLink, Box } from '@mui/material';
import { PermIdentity, HelpOutline } from '@mui/icons-material';
import { USER_PROFILE_PATH } from 'utils/configuration/links';
import {
  USER_MENU_MANAGE_BUTTON_TEXT,
  USER_MENU_GET_HELP_TEXT,
  USER_MENU_SIGN_OUT_TEXT,
  USER_MENU_MYHW_BUTTON_TEXT,
} from 'utils/dictionary/overview';
import { REACT_APP_FRONTDOOR_ISSUER } from 'utils/dictionary/env';
import { LOGOUT_PATH } from 'utils/configuration/links';
import { AppsIcon, LogoutIcon } from 'components/icons/icons';
import style from './userInfo.module.scss';

const UserInfo = ({ user, handleClose }) => {
  const { name, email } = user;

  return (
    <div className={style.styledDiv}>
      <p className={style.hdName}>
        <strong>{name}</strong>
      </p>
      {email ? <p className={style.email}>{email}</p> : ''}

      <MaterialLink
        className={`${style.menuLink} ${style.myHWDiv}`}
        onClick={handleClose}
        href={REACT_APP_FRONTDOOR_ISSUER}
        tabIndex={0}
      >
        <Box alignItems="center" display="flex">
          <Box>
            <AppsIcon className={`${style.icon} ${style.iconGreen}`} />
          </Box>
          <Box>{USER_MENU_MYHW_BUTTON_TEXT}</Box>
        </Box>
      </MaterialLink>

      <Divider className={style.dividr} variant="middle" />

      <MaterialLink
        className={style.menuLink}
        onClick={handleClose}
        href={USER_PROFILE_PATH}
        tabIndex={0}
      >
        <Box alignItems="center" display="flex">
          <Box>
            <PermIdentity className={`${style.icon} ${style.iconGreen}`} />
          </Box>
          <Box> {USER_MENU_MANAGE_BUTTON_TEXT}</Box>
        </Box>
      </MaterialLink>

      <MaterialLink
        className={style.menuLink}
        component={Link}
        to=""
        tabIndex={0}
      >
        <Box alignItems="center" display="flex">
          <Box>
            <HelpOutline className={`${style.icon} ${style.iconGreen}`} />
          </Box>
          <Box> {USER_MENU_GET_HELP_TEXT}</Box>
        </Box>
      </MaterialLink>

      <Divider className={style.dividr} variant="middle" />

      <MaterialLink
        className={`${style.signOutLink} ${style.menuLink}`}
        component={Link}
        to={LOGOUT_PATH}
        tabIndex={0}
      >
        <Box alignItems="center" display="flex">
          <Box>
            <LogoutIcon className={`${style.icon} ${style.iconRed}`} />
          </Box>
          <Box> {USER_MENU_SIGN_OUT_TEXT}</Box>
        </Box>
      </MaterialLink>
    </div>
  );
};

export default UserInfo;
