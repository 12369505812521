import React, { useState, useEffect } from 'react';
import { MenuItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import { Form } from 'formik';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/dialog';
import { GeneralCancelButton } from 'components/buttons/button';
import { NotificationContext } from 'contexts/notificationContext';
import { BaselineTextInput } from 'components/textInputV2/textInput';
import { CheckCircleIcon, BlockIcon } from 'components/icons/icons';
import style from './subscriptionSwitchModal.module.scss';
import buttonStyle from 'components/buttons/button.module.scss';

const SwitchModal = props => {
  const {
    active,
    handleOpen,
    open,
    handleClose,
    isSubmitting,
    isValid,
    dirty,
    status,
    subscriptionName,
  } = props;

  const notificationContext = React.useContext(NotificationContext);

  const buttonColor = active ? buttonStyle.buttonRed : buttonStyle.greenButton;

  const dialogText = active
    ? 'Deactivate this subscription?'
    : 'Activate this subscription?';

  const confirmationButtonText = active
    ? 'Deactivate Subscription'
    : 'Activate Subscription';

  const { isError, message = null, date } = status ?? {};

  useEffect(() => {
    if (message) {
      let errorMsg = '';
      if (message === 'activated.') {
        errorMsg =
          'Error activating subscription. Please try again or contact your Healthwise developers.';
      } else if (message === 'deactivated.') {
        errorMsg =
          'Error deactivating subscription. Please try again or contact your Healthwise developers.';
      } else {
        errorMsg =
          'Error renaming subscription. Please try again or contact your Healthwise developers.';
      }
      notificationContext.showNotification(
        isError ? errorMsg : message,
        isError,
        5000,
        date,
      );
      status.message = null;
      errorMsg = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, message, date]);

  return (
    <>
      {active ? (
        <MenuItem
          className={`${style.menuItem} ${style.deactivate}`}
          onClick={handleOpen}
        >
          <ListItemIcon>
            <BlockIcon style={{ fill: '#dd372f' }} />
          </ListItemIcon>
          <ListItemText primary="Deactivate Subscription" />
        </MenuItem>
      ) : (
        <MenuItem
          className={`${style.menuItem} ${style.activate}`}
          onClick={handleOpen}
        >
          <ListItemIcon>
            <CheckCircleIcon style={{ fill: '#0D8484' }} />
          </ListItemIcon>
          <ListItemText primary="Activate Subscription" />
        </MenuItem>
      )}

      <Dialog
        aria-labelledby="activeSubscriptionModalTitle"
        aria-describedby="activeSubscriptionModalDescription"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle>{dialogText}</DialogTitle>
          {active ? (
            <DialogContent className={style.dialogContent}>
              <p className={style.highlightedText}>
                Deactivating a subscription will immediately remove access to
                any {'\n'}
                applications and content within that subscription.
              </p>
              <p className={style.modalText}>
                Are you sure you want to deactivate{' '}
                <strong>{subscriptionName}</strong>?{'\n'}
                Type 'confirm' below.
              </p>
              <div className={style.textFieldContainer}>
                <BaselineTextInput
                  id="subscriptionSwitch"
                  name="subscriptionSwitch"
                  fullWidth
                />
              </div>
            </DialogContent>
          ) : (
            <DialogContent className={style.dialogContent}>
              <p className={style.modalText}>
                Activating <strong>{subscriptionName}</strong> will provide
                immediate access to any {'\n'}applications and content within
                that subscription.
              </p>
            </DialogContent>
          )}

          <DialogActions className={style.modalBtnsContainer}>
            <GeneralCancelButton
              handleClick={handleClose}
              disabled={isSubmitting}
              disableRipple
            >
              Cancel
            </GeneralCancelButton>
            {active ? (
              <Button
                type="submit"
                className={`${buttonStyle.button} ${buttonColor}`}
                disabled={!(isValid && dirty)}
              >
                {confirmationButtonText}
              </Button>
            ) : (
              <Button
                type="submit"
                className={`${buttonStyle.button} ${buttonColor}`}
              >
                {confirmationButtonText}
              </Button>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};
const SubscriptionSwitchModal = props => {
  const [open, setOpen] = useState(false);
  const { closeModal, handleReset, closeMenu } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (handleReset) handleReset();
    setOpen(false);
    closeMenu();
  };

  //Close modal when form is submitted successfully
  //Couldn't think of a better way to implement this
  if (closeModal?.current?.close) {
    closeModal.current = { close: false };
    setOpen(false);
  }

  return (
    <SwitchModal
      {...props}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
    />
  );
};

export default SubscriptionSwitchModal;
