import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Padding from 'components/padding/padding';
import SalesforceDetailPanel from './salesforceDetailPanel';
import EditableOrganizationHeader from './editableOrganizationHeader';

import { OrgCard, StyledCardContent } from 'components/card/card';
import { OrganizationAdministratorPanel } from 'components/organizationManagement/organizationAdministratorPanel/organizationAdministratorPanel';
import { SubscriptionPanel } from 'components/organizationManagement/subscriptionPanel/subscriptionPanel';
import InternalNotesPanel from 'components/organizationManagement/internalNotesPanel/internalNotesPanel.js';
import { HeaderWithItemListSkeleton } from 'components/skeletons/skeletons';
import { BackToPrevPageLink } from 'components/users/backToPrevPageLink';

import { ORGANIZATION_LIST } from 'utils/configuration/links';

// TODO THIS RENDERS _WAY_ TO MANY TIMES
const OrganizationManagementEditOrganization = props => {
  const {
    administratorsData,
    organizationToEdit = {},
    organizationEtag,
    entitlementData = {},
    subscriptionTypesData,
    renderEditOrganizationSubscriptionModal,
    renderSubscriptionDetailPanel,
    renderOrganizationNotes,
    renderDeleteOrganization,
    renderDeleteSaveOrganizationNotes,
  } = props;

  const { entitlementPacketLoading = true, entitlementPacket = {} } =
    entitlementData;

  const { organizationToEditLoading = true, organization = {} } =
    organizationToEdit;

  const productionSubscriptionsLimit =
    entitlementPacket?.subscriptionsLimits?.production ?? 0;

  const { canProvision, organizationId, organizationName } = organization;

  return (
    <main>
      <BackToPrevPageLink
        text="Back to Organization Dashboard"
        path={ORGANIZATION_LIST}
      />
      <EditableOrganizationHeader
        organizationId={organizationId}
        organizationName={organizationName}
        renderDeleteOrganization={() =>
          renderDeleteOrganization({ organizationId })
        }
      />
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={12}>
              {entitlementPacketLoading || organizationToEditLoading ? (
                <Padding>
                  <HeaderWithItemListSkeleton />
                </Padding>
              ) : (
                <SalesforceDetailPanel
                  organizationToEdit={organization}
                  organizationEtag={organizationEtag}
                  entitlementData={entitlementPacket}
                />
              )}
              {organizationToEditLoading || canProvision ? (
                <>
                  <OrganizationAdministratorPanel
                    administratorsData={administratorsData}
                    organizationToEdit={organizationToEdit}
                    readOnly={false}
                  />
                  <div style={{ paddingTop: '50px' }}></div>
                  <SubscriptionPanel
                    organization={organizationToEdit}
                    subscriptionTypesData={subscriptionTypesData}
                    productionSubscriptionsLimit={productionSubscriptionsLimit}
                    readOnly={false}
                    renderEditOrganizationSubscriptionModal={
                      renderEditOrganizationSubscriptionModal
                    }
                    renderSubscriptionDetailPanel={
                      renderSubscriptionDetailPanel
                    }
                  />
                </>
              ) : (
                <OrgCard>
                  <StyledCardContent>
                    Entitlement Packet is incomplete, some functionality is
                    unavailable
                  </StyledCardContent>
                </OrgCard>
              )}
              <InternalNotesPanel
                organizationToEditLoading={organizationToEditLoading}
                organization={organization}
                props={props}
                renderDeleteSaveOrganizationNotes={
                  renderDeleteSaveOrganizationNotes
                }
                renderOrganizationNotes={renderOrganizationNotes}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
};

OrganizationManagementEditOrganization.propTypes = {
  administratorsData: PropTypes.object.isRequired,
  organizationToEdit: PropTypes.object,
  subscriptionTypesData: PropTypes.object.isRequired,
  renderEditOrganizationSubscriptionModal: PropTypes.func.isRequired,
  renderSubscriptionDetailPanel: PropTypes.func.isRequired,
  renderDeleteOrganization: PropTypes.func.isRequired,
  renderDeleteSaveOrganizationNotes: PropTypes.func.isRequired,
};

export default OrganizationManagementEditOrganization;
