/* FEATURE TOGGLE NAMES */
/* These are the possible feature toggles that can be used via adding the query
    parameter `features=<name>,<name>,<name>`. There's nothing special about 
    these strings, other than that they are shared keys. Note: Features hidden
    behind feature toggles ARE NOT SECURE and SHOULD NOT BE TREATED AS SUCH. */
export const FEATURE_TOGGLE_DEBUG = 'debug';

export const FEATURE_TOGGLE_PASSWORD = 'password';

export const FEATURE_TOGGLE_REDESIGN = 'redesign';

export const FEATURE_TOGGLE_AMT_TOOLS = 'amttools';

export const FEATURE_TOGGLE_MANAGE_SUBSCRIPTION = 'manageSubscription';
