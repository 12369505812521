import React, { forwardRef, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import VerifyModal from 'components/verifyModal/verifyModal';
import Notification from 'components/notificationV2/notification';
import { RedOutlinedButtonLink } from 'components/buttons/button';

import { deleteOrganization } from 'utils/api/organizations';

const DeleteOrganization = forwardRef((props, ref) => {
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [redirect, setRedirect] = useState({ isRedirect: false });
  const { accessToken } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [deleteModalProperties, setDeleteModalProperties] = useState({});

  const { organizationEtag, organization } = props;

  const handleClose = () => {
    setDeleteModelOpen(false);
  };

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: '/organizations',
        }}
      />
    );
  }

  const configureDeleteModal = () => {
    let modalProperties = {
      title: 'Delete this organization?',
      text: 'Are you sure you want to delete this organization?',
      confirmText: 'Delete Organization',
      warningText: (
        <>
          <strong>{organization.organizationName}</strong> and all associated
          subscriptions, administrators, and users will no longer be available.
          <br />
          <br />
          This is an immediate and descructive action that cannot be undone.
        </>
      ),
      hideConfirmInstructions: false,
      alarmStyle: true,
      handleConfirm: async () => {
        const organizationId = organization.organizationId;
        const { response } = await deleteOrganization(
          organizationId,
          organizationEtag,
          accessToken,
        );

        if (response?.status === 200) {
          setSuccessMessage('Organizaton has been successfully deleted.');
          setRedirect({
            isRedirect: true,
            data: { organizationId },
          });
        } else {
          setError(
            'Error deleting organization. Please try again or contact your Healthwise developers.',
          );
        }
        handleClose();
      },
    };
    setDeleteModalProperties(modalProperties);
    setDeleteModelOpen(true);
  };

  return (
    <>
      <RedOutlinedButtonLink
        buttonText="Delete Organization"
        handleClick={() => configureDeleteModal()}
      />
      {deleteModelOpen ? (
        <VerifyModal
          open={deleteModelOpen}
          handleClose={handleClose}
          handleCancel={handleClose}
          {...deleteModalProperties}
        />
      ) : null}
      {error ? (
        <Notification error message={error} duration={5000} />
      ) : successMessage ? (
        <Notification message={successMessage} duration={5000} />
      ) : null}
    </>
  );
});

export default DeleteOrganization;
