import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Tooltip } from '@mui/material';
import { InfoIcon } from 'components/icons/icons';

import style from './tooltip.module.scss';

const ToolTip = ({ title, buttonSize, ...props }) => {
  let btnSize = buttonSize === 'medium' ? 'medium' : 'small';
  return (
    <Tooltip
      title={title}
      PopperProps={{
        className: `${style.subscriptionTooltip} MuiTooltip-popper`,
      }}
      {...props}
    >
      <IconButton
        className={style.iconButton}
        variant="contained"
        color="primary"
        size={btnSize}
      >
        <InfoIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

ToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  buttonSize: PropTypes.string,
};

export default ToolTip;
