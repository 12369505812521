import * as Yup from 'yup';

// TODO: https://healthwise.atlassian.net/browse/P40-670 - identity and only
//  populate the minimum viable _required_ payload for the create organization
//  endpoint.
export const CreateOrganizationSchema = Yup.object().shape({
  organizationName: Yup.string()
    .max(120, 'Must be 120 characters or less')
    .required('Required'),
  primarySalesforceAccount: Yup.object()
    .shape({
      id: Yup.string().required('Missing Salesforce Account ID'),
      name: Yup.string().required('Missing Salesforce Account Name'),
      organizationTypeId: Yup.number().required('Missing organizationTypeId'),
      archetypeId: Yup.number().required('Missing archetypeId'),
      channel: Yup.string(),
      organizationCategoryId: Yup.number().required(
        'Missing Organization Category',
      ),
    })
    .required('Missing Salesforce Account Information'),
  organizationCategoryId: Yup.number().required(
    'Missing Organization Category',
  ),
});

export const FormInitialValues = {
  organizationName: '',
  primarySalesforceAccount: {},
  organizationCategoryId: '',
};
