import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import MaterialTable, { MTableToolbar } from '@material-table/core';

import { Menu, MenuItem, Link, Typography } from '@mui/material';
import Notification from 'components/notificationV2/notification';
import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import VerifyModal from 'components/verifyModal/verifyModal';
import { getDeleteUserProperties } from 'pages/users/modalConfiguration';

import {
  EditIcon,
  DeleteIcon,
  PersonAddIcon,
  RemoveIcon,
  SendIcon,
} from 'components/icons/icons';

import { PageHeader } from 'components/pageHeader/pageHeader';

import { AuthContext } from 'contexts/authContext';

import {
  getUsers,
  deactivateUser,
  deleteUser,
  reactivateUser,
} from 'utils/api/users';
import { DATE_ONLY } from 'utils/dictionary';
import { FormatISO } from 'utils/timeAndDate';
import { CREATE_USER_PATH, EDIT_USER_PATH } from 'utils/configuration/links';

import style from './userManagementList.module.scss';
import { GeneralIconButton } from 'components/buttons/button';
import StatusChip from 'components/users/statusChip/statusChip';

function transformResult(users) {
  return users?.map((value, index) => {
    return {
      id: index,
      name: `${value.firstName} ${value.lastName}`,
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      status: value.status,
      lastLogin: value.lastLogin
        ? FormatISO(value.lastLogin, DATE_ONLY)
        : undefined,
      healthwiseId: value.healthwiseId,
      userId: value.userId,
      referenceId: value.referenceId,
      originalUser: value,
      allowDeactivation: [
        'ACTIVE',
        'RECOVERY',
        'PROVISIONED',
        'LOCKED_OUT',
        'PASSWORD_EXPIRED',
      ].includes(value.status),
    };
  });
}

const UserManagementList = () => {
  const authContext = useContext(AuthContext);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [isDeactivateVisible, setDeactivateVisible] = useState(false);
  const [isDeleteVisible, setDeleteVisible] = useState(false);
  const [isResendVisible, setResendVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [modalProperties, setModalProperties] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [deleteModalProperties, setDeleteModalProperties] = useState(null);

  const tableRef = useRef();

  let history = useHistory();

  let columnDefs = [
    {
      title: 'Last Name',
      field: 'lastName',
      defaultSort: 'asc',
      cellStyle: {
        fontWeight: '500',
      },
    },
    {
      title: 'First Name',
      field: 'firstName',
      cellStyle: {
        fontWeight: '500',
      },
    },
    { title: 'Email', field: 'email' },
    {
      title: 'Status',
      field: 'status',
      render: ({ status }) => <StatusChip status={status} />,
    },
    { title: 'Last Login', field: 'lastLogin' },
  ];

  const handleClick = (event, row) => {
    setCurrentRow(row);
    setAnchorEl(event.currentTarget);

    setDeleteVisible(row.status === 'SUSPENDED');
    setDeactivateVisible(row.allowDeactivation);
    setResendVisible(row.status === 'STAGED');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setDeleteModelOpen(false);
  };

  async function handleUserEvent(asyncApiInvocation, successMessage) {
    const { error } = await asyncApiInvocation();

    const [message, success] = error
      ? [error.message, false]
      : [successMessage, true];

    success ? setSuccess(message) : setError(message);

    handleClose();
    handleModalClose();
  }

  const deactivate = async (userId, accessToken) => {
    const apiInvocation = async () => await deactivateUser(userId, accessToken);
    await handleUserEvent(apiInvocation, 'User has been suspended.');
  };

  const remove = async (id, accessToken) => {
    const apiInvocation = async () => await deleteUser(id, accessToken);
    await handleUserEvent(apiInvocation, 'User has been deleted.');
  };

  const resendInvitation = async (id, accessToken) => {
    const apiInvocation = async () => await reactivateUser(id, accessToken);
    await handleUserEvent(apiInvocation, 'An invitation email has been sent.');
  };

  async function populateTable(query) {
    const top = query.pageSize;
    let skip = query.page * query.pageSize;

    if (query.search && searchText !== query.search) {
      skip = 0;
      setSearchText(query.search);
    }

    const orderByField =
      query.orderBy?.field === undefined ? 'lastName' : query.orderBy?.field;

    const { response, pagination, data, error } = await getUsers(
      skip,
      top,
      authContext.accessToken,
      query.search || '',
      orderByField || 'lastName',
      query.orderDirection || 'asc',
    );

    if (error || response.status !== 200) {
      setError(error || response);
      throw new Error('Failed to load users');
    }

    const users = transformResult(data);

    return {
      data: users,
      page: pagination.page - 1,
      totalCount: pagination?.available,
    };
  }

  const tableProps = {
    columns: columnDefs,
    data: populateTable,
    options: {
      actionsColumnIndex: -1,
      pageSize: 10,
      emptyRowsWhenPaging: false,
      pageSizeOptions: [10, 20, 50, 100],
      debounceInterval: 600,
      search: true,
      sorting: true,
      thirdSortClick: false,
      draggable: false,
      headerStyle: {
        backgroundColor: '#f9f9f9',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#424242',
      },
      rowStyle: {
        fontSize: '1rem',
        color: '#424242',
        fontFamily: 'Roboto',
      },
    },
    isLoading: authContext.loading,
    title: '',
    IconComponent: 'more_horiz',
    actions: [
      {
        icon: 'more_horiz',
        onClick: (event, rowData) => {
          handleClick(event, rowData);
        },
      },
    ],
  };

  function renderDeactivate() {
    return isDeactivateVisible ? (
      <MenuItem
        onClick={() => configureConfirmationModal('deactivateUser', currentRow)}
        className={style.redMenuItem}
      >
        <RemoveIcon />
        <Typography>Deactivate User</Typography>
      </MenuItem>
    ) : null;
  }

  function renderDelete() {
    return isDeleteVisible ? (
      <MenuItem
        onClick={() => configureConfirmationModal('deleteUser', currentRow)}
        className={style.redMenuItem}
      >
        <DeleteIcon />
        <Typography>Delete User</Typography>
      </MenuItem>
    ) : null;
  }

  function renderResend() {
    return isResendVisible ? (
      <MenuItem
        onClick={() => configureConfirmationModal('resendInvite', currentRow)}
        className={style.greyMenuItem}
      >
        <SendIcon />
        <Typography>Resend Invite</Typography>
      </MenuItem>
    ) : null;
  }

  const configureConfirmationModal = (action, userInfo) => {
    let modalProperties;
    let userId = userInfo.userId;

    let accessToken = authContext.accessToken;
    if (action === 'resendInvite') {
      modalProperties = {
        title: 'Resend invitation to this user?',
        text: ` will be emailed account set up instructions.`,
        confirmText: 'Resend Invitation',
        personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
        email: ` at ${userInfo.email}`,
        handleConfirm: async () => await resendInvitation(userId, accessToken),
      };
      setModalProperties(modalProperties);
      setModalOpen(true);
    }

    if (action === 'deactivateUser') {
      modalProperties = {
        title: 'Deactivate this user?',
        text: ` will lose the ability to access all applications.`,
        personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
        email: ` at ${userInfo.email}`,
        confirmText: 'Deactivate User',
        color: 'red',
        handleConfirm: async () => {
          await deactivate(userId, accessToken);
          tableRef.current.onQueryChange();
        },
      };
      setModalProperties(modalProperties);
      setModalOpen(true);
    }
    if (action === 'deleteUser') {
      modalProperties = getDeleteUserProperties(userInfo, async () => {
        await remove(userId, accessToken);
        tableRef.current.onQueryChange();
      });
      setDeleteModalProperties(modalProperties);
      setDeleteModelOpen(true);
    }
  };

  return (
    <>
      <PageHeader
        title="Manage Users"
        secondary={
          " Create or modify your organization's users and assign application roles."
        }
      />
      <Link color="inherit" href={CREATE_USER_PATH}>
        <GeneralIconButton
          tabIndex={0}
          icon={<PersonAddIcon />}
          className={style.createUser}
          buttonText="Create User"
        ></GeneralIconButton>
      </Link>
      {error ? <Notification error message={error} duration={8000} /> : null}
      {success ? <Notification message={success} /> : null}
      {modalOpen ? (
        <ConfirmationModal
          open={modalOpen}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          {...modalProperties}
        />
      ) : null}
      {deleteModelOpen ? (
        <VerifyModal
          open={deleteModelOpen}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          {...deleteModalProperties}
        />
      ) : null}
      {authContext.accessToken ? (
        <div id="userTable">
          <MaterialTable
            style={{ boxShadow: '0px 3px 6px #00000029' }}
            tableRef={tableRef}
            {...tableProps}
            components={{
              Toolbar: props => (
                <div className={style.toolbar}>
                  <MTableToolbar
                    {...props}
                    searchFieldStyle={{
                      backgroundColor: 'white',
                      borderRadius: '8px 8px 0px 0px',
                      paddingLeft: '10px',
                      height: '42px',
                      marginTop: '2px',
                    }}
                  />
                </div>
              ),
            }}
          />
        </div>
      ) : null}
      <Menu
        id="simple-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={style.menu}
      >
        <MenuItem
          onClick={() => {
            history.push(EDIT_USER_PATH, { userToEdit: currentRow.userId });
          }}
          className={style.greenMenuItem}
        >
          <EditIcon />
          <Typography>Manage User</Typography>
        </MenuItem>
        {renderDeactivate()}
        {renderDelete()}
        {renderResend()}
      </Menu>
    </>
  );
};

export default UserManagementList;
