import React, { useContext, useState, useEffect } from 'react';
import {
  AppBar,
  Grid,
  Toolbar,
  Hidden,
  Tabs,
  Tab,
  Link as MaterialLink,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { WhiteLogoIcon } from 'components/icons/icons';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import * as PATHS from 'utils/configuration/links';

import { AuthContext } from 'contexts/authContext';
import { OrganizationContext } from 'contexts/organizationContext';
import UserMenu from 'components/userMenu/userMenu';
import Banner from 'components/banner/banner';
import { ProfileContext } from 'contexts/profileContext';
import style from './headerV2.module.scss';

import {
  ORGANIZATION_ADMINISTRATOR_ROLE,
  HEALTHWISE_ORG,
  APPLICATION_ADMINISTRATOR_ROLE,
} from 'utils/dictionary/overview';

import { REACT_APP_FRONTDOOR_ISSUER } from 'utils/dictionary/env';

function currentTab(location) {
  let path = location.pathname.toLowerCase();
  if (path.includes('user')) return 1;
  else if (path === '/organizations/overview') return 2;
  else if (path === '/delegates') return 3;
  else if (path === '/organizations' || path === '/edit-organization') return 4;
  else if (path === '/applications' || path === '/edit-application') return 5;
  else return 0;
}

const HeaderV2 = () => {
  const location = useLocation();
  const { organization } = useContext(OrganizationContext);
  const { managementRoles, isProvisioningManager } = useContext(AuthContext);
  const { profile } = useContext(ProfileContext);
  const [tabValue, setTabValue] = useState(currentTab(location));
  const [showBanner, setShowBanner] = useState(true);

  const noBanner = [
    '/organizations',
    '/edit-organization',
    '/applications',
    '/edit-application',
  ];

  useEffect(() => {
    setShowBanner(!noBanner.includes(location.pathname.toLowerCase()));
    setTabValue(currentTab(location));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  let userInfo = {
    firstName: `${profile.firstName}`,
    lastName: `${profile.lastName}`,
    email: profile.email,
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <AppBar className={style.appBar} position="sticky">
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item container xs={1} alignItems="center">
              <MaterialLink
                aria-label="Open My Healthwise landing page"
                className={style.logoWrapper}
                href={REACT_APP_FRONTDOOR_ISSUER}
              >
                <WhiteLogoIcon className={style.logo} />
              </MaterialLink>
            </Grid>
            <Grid item container xs={4} sm={6} md={8} justifyContent="center">
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="nav tabs"
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                  style: {
                    height: '4px',
                    display: tabValue === 4 ? 'none' : '',
                    backgroundColor: 'red',
                  },
                }}
              >
                {managementRoles.includes(ORGANIZATION_ADMINISTRATOR_ROLE) && (
                  <Tab
                    className={style.tab}
                    label="Home"
                    to={PATHS.HOME_PATH}
                    component={Link}
                    value={0}
                    tabIndex={0}
                    style={{ minWidth: '100px' }}
                    disableFocusRipple
                  />
                )}
                {managementRoles.includes(ORGANIZATION_ADMINISTRATOR_ROLE) && (
                  <Tab
                    className={style.tab}
                    label="Manage Users"
                    component={Link}
                    to={PATHS.USER_MANAGEMENT_PATH}
                    value={1}
                    tabIndex={0}
                    disableFocusRipple
                  />
                )}
                {managementRoles.includes(ORGANIZATION_ADMINISTRATOR_ROLE) && (
                  <Tab
                    className={style.tab}
                    label="Organization Overview"
                    to={PATHS.ORGANIZATION_OVERVIEW_PATH}
                    component={Link}
                    value={2}
                    tabIndex={0}
                    disableFocusRipple
                  />
                )}
                {Object.keys(organization).length !== 0 &&
                  organization?.organizationName !== HEALTHWISE_ORG && (
                    <Tab
                      className={style.tab}
                      label="Delegated Access"
                      to={PATHS.DELEGATED_ACCESS_PATH}
                      component={Link}
                      value={3}
                      tabIndex={0}
                      disableFocusRipple
                    />
                  )}
                {isProvisioningManager && (
                  <Tab
                    className={`${style.tabButton}
                      ${
                        tabValue === 4
                          ? style.tabButtonSelected
                          : style.tabButtonUnselected
                      }`}
                    label="Organization Dashboard"
                    component={Link}
                    to={PATHS.ORGANIZATION_LIST}
                    value={4}
                    tabIndex={0}
                    style={{ height: '42px' }}
                    disableFocusRipple
                  />
                )}
                {managementRoles.includes(APPLICATION_ADMINISTRATOR_ROLE) && (
                  <Tab
                    className={style.tab}
                    label="Applications"
                    to={PATHS.APPLICATIONS_PATH}
                    component={Link}
                    value={5}
                    style={{ minWidth: '100px' }}
                    tabIndex={0}
                    disableFocusRipple
                  />
                )}
              </Tabs>
            </Grid>
            <div className={style.profile}>
              <div className={style.account}>
                <Grid container direction="row">
                  <Hidden xsDown>
                    <Grid item>
                      <AccountCircleIcon className={style.accountIcon} />
                    </Grid>
                  </Hidden>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <span className={style.name}>{userInfo.firstName}</span>
                        <UserMenu user={userInfo} />
                      </Grid>
                      <Hidden xsDown>
                        <Grid item>
                          <div className={style.tool}>User Management</div>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
      {showBanner && <Banner organization={organization} />}
    </>
  );
};

export default HeaderV2;
