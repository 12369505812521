import React, { useState, useEffect } from 'react';
import styles from './inlineedit.module.scss';
import { BaselineTextField } from 'components/textInputV2/textInput';

const InlineEdit = ({ value, inputLabel, setValue, enabled = true }) => {
  const [editingValue, setEditingValue] = useState('');

  const onChange = event => setEditingValue(event.target.value);

  const onKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  };

  const onBlur = event => {
    if (event.target.value.trim() === '') {
      setEditingValue(value);
    } else {
      setValue(event.target.value);
    }
  };
  useEffect(() => {
    setEditingValue(value);
  }, [value]);
  return (
    <>
      {enabled ? (
        <BaselineTextField
          className={styles.inlineEdit}
          label={inputLabel}
          value={editingValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          disabled={false}
        />
      ) : (
        <BaselineTextField
          className={styles.inlineEdit}
          label={inputLabel}
          value={editingValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          disabled={true}
        />
      )}
    </>
  );
};

export default InlineEdit;
