/* BANNER and SIDEBAR */
export const HEALTHWISE = 'My Healthwise';

export const HEALTHWISE_ORG = 'Healthwise';

/* ACCOUNT OVERVIEW TEXT */
export const ACCOUNT_PROFILE_INFORMATION_TITLE =
  'Account and Profile Information';
export const ACCOUNT_PROFILE_INFORMATION_BUTTON_TEXT = 'Edit Profile';
export const ADMIN_SETTINGS = 'Administration & Settings';
export const USER_MANAGEMENT_SECTION_TITLE = 'Manage Users';
export const ACCOUNT_MANAGEMENT_SECTION_TITLE = 'Account Management';
export const ACCOUNT_MANAGEMENT_SECTION_ORGANIZATION_TITLE =
  'Organization Dashboard';
export const ACCOUNT_MANAGEMENT_SECTION_ORGANIZATION_DESCRIPTION =
  'Edit organizations, subscriptions, and administrators.';
export const ACCOUNT_MANAGEMENT_SECTION_ORGANIZATION_BUTTON_TEXT =
  'Manage Organizations';

export const APPLICATION_MANAGEMENT_SECTION_TITLE = 'Applications';
export const APPLICATION_MANAGEMENT_SECTION_DESCRIPTION =
  'Create, edit, and remove applications.';

export const ORGANIZATION_OVERVIEW_CARD_TITLE = 'Organization Overview';
export const ORGANIZATION_OVERVIEW_CARD_DESCRIPTION =
  'View your organization’s administrators and subscriptions.';
export const DELEGATED_ACCESS_CARD_TITLE = 'Delegated Access';
export const DELEGATED_ACCESS_CARD_DESCRIPTION =
  "Provide a Healthwise Employee access to your organization's application(s).";
export const USER_MANAGEMENT_SECTION_USERS_TITLE = 'Users';
export const USER_MANAGEMENT_SECTION_USERS_DESCRIPTION =
  'Create, edit, and remove users. Assign application roles.';
export const USER_MANAGEMENT_SECTION_USERS_BUTTON_TEXT = 'Manage Users';
export const USER_MENU_MYHW_BUTTON_TEXT = 'MyHealthwise';
export const USER_MENU_MANAGE_BUTTON_TEXT = 'My Profile';
export const USER_MENU_GET_HELP_TEXT = 'Get Help';
export const USER_MENU_RESET_PASSWORD_TEXT = 'Reset Password';
export const USER_MENU_SIGN_OUT_TEXT = 'Sign Out';
export const INCLUDED_APPLICATIONS_SERVICES_SECTION_TITLE =
  'Included applications and services';
export const INCLUDED_APPLICATIONS_SERVICES_SECTION_DESCRIPTION =
  'Here are all of the applications and services that you and your organization have access to.';
export const NO_APPLICATIONS_TEXT =
  'You have not been granted sufficient access to any administrative applications';
export const ACCOUNT_PROFILE_INFORMATION_PROFILE_IMAGE_ALT = 'edit profile';
export const ACCOUNT_PROFILE_INFORMATION_CLIENT_IMAGE_ALT = 'your organization';
// ROLES
export const PROVISIONING_MANAGER_ROLE = 'ProvisioningManager';
export const ORGANIZATION_ADMINISTRATOR_ROLE = 'OrganizationAdministrator';
export const APPLICATION_ADMINISTRATOR_ROLE = 'ApplicationAdministrator';
// ROLE NAMES
export const PROVISIONING_MANAGER_ROLE_NAME = 'Provisioning Manager';
export const ORGANIZATION_ADMINISTRATOR_ROLE_NAME =
  'Organization Administrator';
export const APPLICATION_ADMINISTRATOR_ROLE_NAME = 'Application Administrator';
// EXTERNAL RELOAD LOGIN PATH
export const EXTERNAL_RELOAD_LOGIN_PATH = '/login/reload';

// CONFIRMATION MODAL BUTTON COLOR
export const DEFAULT_BTN_COLOR_CONFIRM_GREEN = 'green';
export const REVOKE_ACCESS_BTN_COLOR = 'red';

// REVOKE ACCESS
export const REVOKE_ACCESS_BTN_TEXT = 'Revoke Access';
export const REVOKE_ACCESS_MODAL_TITLE =
  'Revoke access for this Healthwise employee?';
export const REVOKE_ACCESS_MODAL_TEXT =
  " will be removed as a delegated user and will no longer have access to any of your organization's applications.";

//RESET PASSWORD
export const RESET_PASSWORD_BTN_TEXT = 'Reset Password';
export const RESET_PASSWORD_MODAL_TITLE = 'Reset password?';
