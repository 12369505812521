import React, { useState, useContext, forwardRef } from 'react';
import { Formik } from 'formik';

import { AuthContext } from 'contexts/authContext';
import { SendIcon } from 'components/icons/icons';
import MenuItemModalAndFeedbackModal from 'components/organizationManagement/organizationManagementEditOrganization/menuItemModalAndFeedbackModal/menuItemModalAndFeedbackModal';

import { reactivateOrganizationAdministrator } from 'utils/api/organizations';

const OrganizationAdministratorResendInvitation = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { accessToken } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { organization = {}, rowData = {}, closeMenu } = props ?? {};

  const { firstName, lastName, email, status, userId } = rowData;
  const { organizationId } = organization;

  const content = (
    <>
      Instructions to activate the account will be sent to{' '}
      <b>
        {firstName} {lastName}
      </b>
      {' \n '}
      at {email}.
    </>
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = evt => {
    setOpen(false);
    setIsSubmitting(false);
    closeMenu();
  };

  const resendAdministratorInvitation = async (values, { setStatus }) => {
    setIsSubmitting(true);
    const { response } = await reactivateOrganizationAdministrator(
      organizationId,
      userId,
      accessToken,
    );

    if (response?.status === 204) {
      setStatus({
        isError: false,
        date: Date.now(),
        message:
          'Successfully sent invitation to ' + firstName + ' ' + lastName,
      });
    } else {
      setStatus({
        isError: true,
        date: Date.now(),
        message:
          'Error resending invite. Please try again or contact your Healthwise developer.',
      });
    }

    handleClose();
  };

  return status === 'PROVISIONED' ? (
    <>
      <Formik
        initialValues={{}}
        onSubmit={resendAdministratorInvitation}
        children={props => (
          <MenuItemModalAndFeedbackModal
            {...props}
            icon={<SendIcon />}
            handleOpen={handleOpen}
            open={open}
            handleClose={handleClose}
            confirmationButtonText="Send"
            cancelButtonText="Cancel"
            labelledBy="administratorResendInvitationModalTitle"
            describedBy="administratorResendInvitationModalDescription"
            modalTitle="Resend invitation?"
            menuItemText="Resend Invite"
            menuItemColor={'#424242'}
            isSubmitting={isSubmitting}
            isDelete={false}
            content={content}
          />
        )}
      />
    </>
  ) : null;
});

export default OrganizationAdministratorResendInvitation;
