import React, { useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import CreateUserForm from 'pages/users/createUser/createUserForm';
import { AuthContext } from 'contexts/authContext';
import { createUser } from 'utils/api';
import { CREATE_USER } from 'utils/configuration/links';
import { OrganizationContext } from 'contexts/organizationContext';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

import {
  emptyUser,
  UserValidationSchema,
  generateFlattenedRoles,
} from './user';

const userReducerFunction = (user, newInformation) => {
  return { ...user, ...newInformation };
};

export const CreateUser = () => {
  // Get permissions of current user
  const authContext = useContext(AuthContext);
  const { organization } = useContext(OrganizationContext);

  let history = useHistory();

  // Setup the access token; this is the primary effect that triggers all others.
  useEffect(() => {
    if (authContext?.accessToken) {
      setSubAppInfo({ accessToken: authContext.accessToken });
    }
    if (organization?.organizationId) {
      setSubAppInfo({ organizationId: organization?.organizationId });
    }
    if (authContext?.permissions?.userId) {
      setSubAppInfo({ id: authContext.permissions.userId });
    }
    if (organization?.identityProviders) {
      setSubAppInfo({
        identityProviderId:
          organization?.identityProviders[0]?.identityProviderId,
      });
    }
  }, [authContext, organization]);

  const addUser = async (values, { setSubmitting, setStatus }) => {
    const roles = generateFlattenedRoles(
      values.organizationId,
      values.selectedRoles,
    );

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roles: roles,
      organizationId: subAppInfo.organizationId,
      identityProviderId: subAppInfo.identityProviderId,
    };

    return createUser(
      `${REACT_APP_API_BASE_URL + CREATE_USER}`,
      subAppInfo.accessToken,
      payload,
    )
      .then(newUser => {
        values.created = newUser.created;
        setStatus({ success: true, lastSetDate: new Date() });
        setSubmitting(false);
        setTimeout(() => {
          history.push('/users');
        }, 2500);
      })
      .catch(error => {
        setStatus({ success: false, error: error, lastSetDate: new Date() });
        setSubmitting(false);
      });
  };

  // Setup the user information, see userSchema for the fields we can leverage.
  const [subAppInfo, setSubAppInfo] = useReducer(
    userReducerFunction,
    emptyUser,
  );

  return (
    <Formik
      enableReinitialize
      initialValues={subAppInfo}
      validationSchema={UserValidationSchema}
      onSubmit={addUser}
      children={props => <CreateUserForm userInfo={subAppInfo} {...props} />}
    />
  );
};

export default CreateUser;
