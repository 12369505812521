import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import queryString from 'query-string';
import { HOME_PATH } from 'utils/configuration/links';
import { AuthContext } from 'contexts/authContext';

/**
 * Reference Implementation of the reload logic
 *
 * MyHealthwise looks for this component on the route '/login/reload' only.
 *
 * ReloadLogin resets the user's session to a known state to trigger
 * reauthentication. This is to protect against getting into the state where
 * Okta thinks the user is logged in but they aren't.
 *
 * If this file is modified:
 * 1.) update the documentation in MyHealthwise as needed.
 * 2.) include a changelog in this header.
 * 3.) notify stakeholders of the change.
 */
const ReloadLogin = () => {
  const location = useLocation();
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const [authenticating, setAuthenticating] = useState(false);
  const { customAuthHandler } = React.useContext(AuthContext);

  useEffect(() => {
    const { subscription_id } = queryString.parse(location.search);

    const reauthenticate = async () => {
      setAuthenticating(true);

      if (subscription_id) {
        if (authState?.idToken || authState?.accessToken) {
          oktaAuth.tokenManager.clear();
          oktaAuth.authStateManager.updateAuthState();
        }

        await customAuthHandler(oktaAuth, location.search);
      } else {
        history.push(HOME_PATH);
      }
    };

    if (authState && !authenticating) {
      reauthenticate();
    }
  }, [authState, oktaAuth, authenticating, location.search, history, customAuthHandler]);

  return <></>;
};

export default ReloadLogin;
