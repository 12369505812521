import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  GeneralCancelButton,
  RedButton,
  TrashButton,
} from 'components/buttons/button';
import { NotificationContext } from 'contexts/notificationContext';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import style from 'components/confirmationModal/confirmationModal.module.scss';

const ActionResponse = props => {
  const notificationContext = React.useContext(NotificationContext);
  const { status } = props;

  React.useEffect(() => {
    if (status) {
      let message = '';
      let isError = false;
      if (status.success) {
        message = `Note deleted successfully.`;
      } else {
        message = `Error deleting note. Please try again or contact your Healthwise developers. - (Error message: ${status?.error?.error?.message})`;
        isError = true;
      }

      notificationContext.showNotification(
        message,
        isError,
        status.lastSetDate,
        10000,
      );
    }
  }, [status, notificationContext]);

  return null;
};

const IconModal = props => {
  const {
    icon,
    handleOpen,
    open,
    handleClose,
    confirmationText,
    title,
    name,
    date,
    isSubmitting,
  } = props;

  return (
    <>
      <TrashButton onClick={handleOpen}>{icon}</TrashButton>
      <ActionResponse {...props} />
      <Dialog
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        closeAfterTransition
        open={open}
        onClose={handleClose}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle className={style.title} id="dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              className={style.bodyText}
              id="dialog-description"
            >
              This note from <span className={style.fullName}>{name}</span>{' '}
              posted on{date} will be removed. This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions className={style.rightAlignedButtonGroup}>
            <GeneralCancelButton
              handleClick={handleClose}
              disableRipple
              disabled={isSubmitting}
            ></GeneralCancelButton>
            {isSubmitting ? (
              <ProgressIndicator />
            ) : (
              <RedButton
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                buttonText={confirmationText}
              >
                {confirmationText}
              </RedButton>
            )}
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

IconModal.propTypes = {
  icon: PropTypes.node.isRequired,
  handleOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  confirmationText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

IconModal.defaultProps = {
  icon: <></>,
  handleOpen: () => {},
  open: false,
  handleClose: () => {},
  confirmationText: '',
  title: '',
  date: '',
  name: '',
};

export default IconModal;
