export const ORGANIZATIONS_ROOT = 'v1/organizations';
export const ORGANIZATIONS_AVAILABLE_APPLICATIONS = 'applications';

// The rolename for organization admins
export const ORGANIZATION_ADMIN_ROLE = 'OrganizationAdministrator';

export const ORGANIZATION_ADMINISTRATOR = 'administrators';
export const ORGANIZATION_ADMINISTRATOR_REACTIVATE = 'reactivate';
export const ORGANIZATION_ADMINISTRATOR_DEACTIVATE = 'deactivate';

export const ORGANIZATION_ROLES = 'roles';
export const ORGANIZATION_DISABLE = 'disable';

export const ORGANIZATION_EXPIRE_PASSWORD = 'expirePassword';
export const ORGANIZATION_RESET_PASSWORD = 'resetPassword';

export const ORGANIZATION_USERS = 'users';

export const ORGANIZATION_CATEGORY_INTERNAL_VALUE = 1;
export const ORGANIZATION_CATEGORY_CLIENT_VALUE = 3;
export const ORGANIZATION_CATEGORY_INTERNAL = 'Internal';
