import React from 'react';
import { Checkbox } from '@mui/material';

const ModuleCheckbox = props => {
  const {
    onChange,
    onBlur,
    module = {},
    name,
    setChecked,
    checked,
    className,
    //selectedModules = {},
  } = props;
  const {
    id: moduleId,
    applications = [],
    solutionLevel,
    name: moduleName,
  } = module;

  const moduleApplications = applications.reduce((appObj, app) => {
    const { id } = app;
    appObj[id] = {
      ...app,
      features: {
        ...app.features,
        optional:
          props.values?.modules[moduleId]?.[id]?.features?.optional ?? [],
      },
      solutionLevel,
      modules: [{ id: moduleId }],
    };
    return appObj;
  }, {});

  const handleChange = e => {
    const checkedValue = e.target.checked;
    setChecked(currentState => {
      return {
        ...currentState,
        [moduleId]: {
          checked: checkedValue,
        },
      };
    });
    if (onBlur) {
      onBlur(`${name}.${moduleId}`, true, false);
    }

    let modules = Object.assign({}, props.values.modules);

    if (!checkedValue) {
      delete modules[moduleId];
    } else {
      modules[moduleId] = moduleApplications;
    }

    onChange(`modules`, modules);
  };
  return (
    <Checkbox
      onChange={handleChange}
      color="primary"
      inputProps={{ 'aria-label': moduleName }}
      checked={checked[moduleId]?.checked ?? false}
      className={className}
    />
  );
};

export default ModuleCheckbox;
