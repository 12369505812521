import React, { useState, useContext, forwardRef } from 'react';

import { AuthContext } from 'contexts/authContext';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { deleteSubscription, getSubscription } from 'utils/api/subscriptions';
import Notification from 'components/notificationV2/notification';
import VerifyModal from 'components/verifyModal/verifyModal';
import { DeleteIcon } from 'components/icons/icons';
import { Redirect } from 'react-router-dom';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import style from './deleteOrganizationSubscription.module.scss';

const DeleteOrganizationSubscription = forwardRef((props, ref) => {
  const { accessToken } = useContext(AuthContext);
  const { rowData, organization, closeMenu } = props;

  const [deleteModalProperties, setDeleteModalProperties] = useState({});
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setsuccessMessage] = useState(null);
  const [redirect, setRedirect] = useState({ isRedirect: false });

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const handleClose = () => {
    setDeleteModelOpen(false);
    closeMenu();
  };

  const configureDeleteModal = () => {
    let modalProperties = {
      title: 'Delete this subscription?',
      text: (
        <>
          Are you sure you want to delete{' '}
          <strong>{rowData.subscriptionName}</strong>?
        </>
      ),
      confirmText: 'Delete Subscription',
      warningText: (
        <>
          Deleting a subscription will immediately remove any applications and
          content within that subscription. This action cannot be undone.
        </>
      ),
      handleConfirm: async () => {
        const { etag: subscriptionEtag } = await getSubscription(
          organization.organizationId,
          rowData.subscriptionId,
          accessToken,
        );
        const { response } = await deleteSubscription(
          organization.organizationId,
          rowData.subscriptionId,
          accessToken,
          subscriptionEtag,
        );
        if (response?.status === 200) {
          setsuccessMessage(
            rowData.subscriptionName + ' has been successfully deleted.',
          );
          const organizationId = organization.organizationId;
          setRedirect({
            isRedirect: true,
            data: { organizationId },
          });
        } else {
          setError(
            'Error deleting subscription. Please try again or contact your Healthwise developers.',
          );
        }
        handleClose();
      },
    };
    setDeleteModalProperties(modalProperties);
    setDeleteModelOpen(true);
  };

  return (
    <>
      <MenuItem
        className={style.menuItem}
        onClick={() => configureDeleteModal()}
      >
        <ListItemIcon>
          <DeleteIcon style={{ fill: '#dd372f' }} />
        </ListItemIcon>
        <ListItemText primary="Delete Subscription" />
      </MenuItem>
      {deleteModelOpen ? (
        <VerifyModal
          open={deleteModelOpen}
          handleClose={handleClose}
          handleCancel={handleClose}
          {...deleteModalProperties}
        />
      ) : null}
      {error ? (
        <Notification error message={error} duration={5000} />
      ) : successMessage ? (
        <Notification message={successMessage} duration={5000} />
      ) : null}
    </>
  );
});

export default DeleteOrganizationSubscription;
