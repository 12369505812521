import { createGlobalStyle } from 'styled-components';

const colors = {
  red: '#dd372f',
  darkRed: '#a03123',
  yellow: '#ffb71b',
  orange: '#ff6c37',
  green: '#00a887',
  white: '#ffffff',
  lightPurple: 'e0c7e6',
  purple: '#6c3175',
  lightBlue: '#63cfe3',
  blue: '#00a6ce',
  teal: '#309a99',
  lighterGrey: '#f4f4f4',
  lightGrey: '#d8d8d8',
  neutralGrey: '#727272',
  darkGrey: '#676767',
  darkestGrey: '#424242',
  black: '#262626',
  lightPrimary: '#aae2d0',
  primary: '#0d8484',
  darkPrimary: '#006272',
  skylight: '#00a2c7',
  stormy: '#003a47',
  client: '#d4f0e7',
  demo: '#d8d8d880',
  internal: '#c3c3c3',
  darkerGrey: '#595959',
};

const spacingScale = {
  0: '0.25rem',
  1: '0.5rem',
  2: '0.75rem',
  3: '1rem',
  4: '1.5rem',
  5: '2rem',
  6: '3rem',
  7: '4rem',
  8: '6rem',
  9: '8rem',
  10: '12rem',
  11: '16rem',
  12: '24rem',
  13: '32rem',
  14: '40rem',
  15: '48rem',
};

const typeScale = {
  0: '0.75rem',
  1: '0.875rem',
  2: '1rem',
  3: '1.125rem',
  4: '1.25rem',
  5: '1.5rem',
  6: '1.875rem',
  7: '2.25rem',
  8: '3rem',
  9: '4rem',
};

// Values that roughly match up with the h1..h6 font size progression
const headerScale = {
  1: typeScale[7],
  2: typeScale[5],
  3: typeScale[3],
  4: typeScale[2],
  5: typeScale[1],
  6: typeScale[0],
};

const tokens = {
  background: colors.lighterGrey,
  backgroundLight: colors.white,
  backgroundDark: colors.lightGrey,
  info: colors.darkPrimary,
  success: colors.lightPrimary,
  error: colors.red,
  warning: colors.yellow,
  primaryText: colors.darkestGrey,
  secondaryText: colors.neutralGrey,
  disabledText: colors.lightGrey,
  accentText: colors.darkPrimary,
};

export const theme = {
  colors: {
    ...colors,
    ...tokens,
  },
  font: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;`,
  spacingScale: {
    ...spacingScale,
  },
  typeScale: {
    ...typeScale,
  },
  headerScale: {
    ...headerScale,
  },
};

//Being used for storybook for now
export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => `
  html, body{   
    height: 100%;
  }
  body {
    margin: 0;
    font-family: ${theme.font}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${theme.colors.white};
    color: ${theme.colors.primaryText};     
  }
  #root {
    height: 100%;
  }
 
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .MuiTooltip-tooltip {
    font-size: ${theme.typeScale[1]};
  }

`}
`;
