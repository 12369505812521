import React, { useEffect, useState } from 'react';

import { Checkbox } from '@mui/material';

const FeatureCheckbox = props => {
  const {
    selectedModules = {},
    onChange,
    onBlur,
    module,
    feature,
    applicationId,
    name,
    className,
  } = props;

  const [optFeatureChecked, setOptFeatureChecked] = useState({});
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    let modules = props.values.modules[module.id];

    if (!modules || Object.keys(modules).length === 0) {
      setDisabled(true);
      setOptFeatureChecked({});
    } else {
      setDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.modules]);

  useEffect(() => {
    Object.values(selectedModules).forEach(item => {
      Object.values(item).forEach(app => {
        app?.features?.optional?.forEach(opt => {
          setOptFeatureChecked(currentState => {
            return {
              ...currentState,
              [opt.id]: { checked: true },
            };
          });
        });
      });
    });
  }, [selectedModules, setOptFeatureChecked]);

  const handleChange = e => {
    const checked = e.target.checked;
    setOptFeatureChecked(currentState => {
      return {
        ...currentState,
        [feature.id]: { checked: checked },
      };
    });

    let copy = JSON.parse(JSON.stringify(props.values.modules[module.id]));

    if (checked) {
      copy[applicationId]?.features.optional.push(feature);
    } else {
      let index = copy[applicationId].features.optional.findIndex(
        x => x.id === feature.id,
      );
      copy[applicationId]?.features.optional.splice(index, 1);
    }

    let modules = Object.assign({}, props.values.modules);
    modules[module.id] = copy;

    onBlur(`modules[${module.id}].[${applicationId}].features.optional`);
    onChange('modules', modules);
  };

  return (
    <Checkbox
      color="primary"
      onChange={handleChange}
      inputProps={{ 'aria-label': name }}
      checked={optFeatureChecked[feature.id]?.checked || false}
      className={className}
      disabled={disabled}
    />
  );
};

export default FeatureCheckbox;
