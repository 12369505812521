import React from 'react';

import { Chip } from '@mui/material';
import { getStatusName } from 'utils/statusNames';

import style from './statusChip.module.scss';

const StatusChip = ({ status }) => {
  const accountStatus = getStatusName(status);

  return (
    <Chip
      className={`${style.statusChip} ${status.trim().toLowerCase()}`}
      label={accountStatus}
    />
  );
};

export default StatusChip;
