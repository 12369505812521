import React, { useState, useEffect, useCallback } from 'react';
import { Menu, IconButton } from '@mui/material';
import MaterialTable from '@material-table/core';

import { SectionHeader } from 'components/sectionHeader/sectionHeader';
import OrganizationAdministratorResendInvitation from 'containers/organizationManagement/editOrganization/organizationAdministratorResendInvitation/organizationAdministratorResendInvitation';
import ForceResetPasswordWorkflow from 'components/passwordWorkflows/forceResetPasswordWorkflow';
import ResetPasswordWorkflow from 'components/passwordWorkflows/resetPasswordWorkflow';
import RemoveOrganizationAdministrator from 'containers/organizationManagement/editOrganization/removeOrganizationAdministrator/removeOrganizationAdministrator';
import CreateOrganizationAdministrator from 'containers/organizationManagement/createOrganizationAdministrator';

import { MoreHorizIcon } from 'components/icons/icons';

import { TableSkeleton } from 'components/skeletons/skeletons';

import Padding from 'components/padding/padding';

import style from './organizationAdministratorPanel.module.scss';

/**
 * Render the Organization Administrator's table with or without action buttons.
 * Note: if `readOnly` is `false`, `organizationToEdit`,
 * `renderOrganizationAdministratorResendInvitation`, and
 * `renderRemoveOrganizationAdministrator` _must_ be defined. Those fields are
 * optional if `readOnly` is `true`.
 */
const AdministratorsTable = props => {
  const { readOnly, administratorsData, organizationToEdit } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [columns, setColumns] = useState([]);
  const [administrators, setAdministrators] = useState([]);

  useEffect(() => {
    //table row needs id to mount the menu correctly
    administratorsData.forEach(element => {
      element.id = element.userId;
    });
    setAdministrators(administratorsData);
  }, [administratorsData]);

  // update state
  const removeAdministratorFromState = useCallback(
    val => {
      const adminData = [...administrators.filter(item => item.userId !== val)];
      setAdministrators(adminData);
    },
    [administrators],
  );

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Columns for when readOnly is false
  const editColumns = [
    {
      title: 'Actions',
      sorting: false,
      render: rowData => {
        return (
          <>
            <IconButton onClick={evt => handleClick(evt, rowData)}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              className={style.menu}
              id="action-menu"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {currentRow && rowData.status !== 'SUSPENDED' && (
                <OrganizationAdministratorResendInvitation
                  rowData={currentRow}
                  organization={organizationToEdit}
                  closeMenu={handleClose}
                />
              )}
              {currentRow && rowData.status !== 'SUSPENDED' && (
                <ResetPasswordWorkflow
                  forOrgAdmin={true}
                  userInfo={currentRow}
                  organization={organizationToEdit}
                />
              )}
              {currentRow && rowData.status !== 'SUSPENDED' && (
                <ForceResetPasswordWorkflow
                  forOrgAdmin={true}
                  userInfo={currentRow}
                  organization={organizationToEdit}
                />
              )}

              {currentRow && (
                <RemoveOrganizationAdministrator
                  rowData={currentRow}
                  organization={organizationToEdit}
                  closeMenu={handleClose}
                  removeAdministratorFromState={removeAdministratorFromState}
                />
              )}
            </Menu>
          </>
        );
      },
      align: 'center',
      width: '12%',
    },
  ];

  // Default columns to show
  const infoColumns = [
    {
      title: 'Name',
      field: 'firstName',
      defaultSort: 'asc',
      render: props => {
        const { firstName, lastName } = props;
        return <>{`${firstName} ${lastName}`}</>;
      },
      cellStyle: {
        fontWeight: '500',
      },
    },
    { title: 'Email', field: 'email' },
    {
      title: 'Status',
      field: 'status',
      width: '15%',
      render: rowData => {
        const { status } = rowData;
        return (
          <>
            {status === 'ACTIVE' ? (
              <span className={`${style.status} ${style.active}`}>ACTIVE</span>
            ) : status === 'PROVISIONED' ? (
              <span className={`${style.status} ${style.sent}`}>
                INVITE SENT
              </span>
            ) : status === 'SUSPENDED' ? (
              <span className={`${style.status} ${style.inactive}`}>
                INACTIVE
              </span>
            ) : (
              <span className={`${style.status} ${style.other}`}>{status}</span>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    // Only show the info columns if `readOnly` is set
    const tableColumns = readOnly
      ? [...infoColumns]
      : [...infoColumns, ...editColumns];
    setColumns(tableColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly, currentRow, anchorEl]);

  return (
    <MaterialTable
      style={{ boxShadow: '0px 3px 6px #00000029' }}
      columns={columns}
      data={administrators}
      options={{
        actionsColumnIndex: -1,
        pageSize: 5,
        toolbar: readOnly ? false : true,
        search: true,
        sorting: true,
        thirdSortClick: false,
        draggable: false,
        headerStyle: {
          backgroundColor: '#f9f9f9',
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#424242',
        },
        rowStyle: {
          fontSize: '1rem',
          color: '#424242',
          fontFamily: 'Roboto',
        },
      }}
      components={{
        Toolbar: props => (
          <div className={style.toolbar}>
            <CreateOrganizationAdministrator
              organization={organizationToEdit}
            />
          </div>
        ),
      }}
    />
  );
};

const DisplayAdministrators = props => {
  const {
    administratorsData = {},
    organizationToEdit = {},
    readOnly = false,
  } = props;

  const { administrators = [] } = administratorsData;
  const { organization = {} } = organizationToEdit;

  const description = readOnly
    ? 'Organization administrators manage users and their roles at your organization. You can assign administrators on the Manage Users page.'
    : "Organization Administrators are provisioned by Healthwise. Administrators manage users and their roles at the client's organization.";

  return (
    <>
      <SectionHeader
        title="Organization Administrators"
        secondary={description}
      />
      <div>
        <AdministratorsTable
          readOnly={readOnly}
          administratorsData={administrators}
          organizationToEdit={organization}
        />
      </div>
    </>
  );
};

/**
 * Render the Organization Administrator panel.
 * Note: if `readOnly` is `false`, `organizationToEdit`,
 * `renderOrganizationAdministratorResendInvitation`, and
 * `renderRemoveOrganizationAdministrator` _must_ be defined. Those fields are
 * optional if `readOnly` is `true`.
 */
export const OrganizationAdministratorPanel = props => {
  const { administratorsData = {}, organizationToEdit = {} } = props;

  const { organizationAdministratorsLoading = false } = administratorsData;
  const { organizationToEditLoading = false } = organizationToEdit;

  const Loading = () =>
    organizationToEditLoading || organizationAdministratorsLoading;

  return (
    <section>
      {Loading() ? (
        <Padding>
          <TableSkeleton />
        </Padding>
      ) : (
        <DisplayAdministrators {...props} />
      )}
    </section>
  );
};

export default OrganizationAdministratorPanel;
