import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import routes from 'utils/routes';
import { Grid, Container } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import HeaderV2 from 'components/headerV2/headerV2';
import Footer from 'components/footer/footer';

import { AuthHandler } from 'contexts/authContext';
import { ProfileProvider } from 'contexts/profileContext';
import { NotificationProvider } from 'contexts/notificationContext';

import Overview from 'pages/overview/overview';
import DelegatedAccessPage from 'pages/delegates/delegatedAccessPage';
import NotFoundPage from 'pages/404';
import { theme } from 'utils/styles';
import { OrganizationProvider } from 'contexts/organizationContext';
import { ReferenceSubscriptionsProvider } from 'contexts/referenceSubscriptionsContext';
import ContentFooter from 'components/contentFooter/contentFooter';
import 'styles/_styles.scss';

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
            <OrganizationProvider>
              <ProfileProvider>
                <HeaderV2 />
              </ProfileProvider>

              <Container
                style={{ marginBottom: '5em', flex: '1 1 auto', zIndex: '900' }}
                maxWidth="xl"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignContent="center"
                  alignItems="center"
                  wrap="wrap"
                >
                  <Grid item xs={10}>
                    <Switch>
                      {routes.map((route, index) => (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={props => {
                            return <route.main {...props} />;
                          }}
                        />
                      ))}

                      <Route path="/delegates">
                        <DelegatedAccessPage />
                      </Route>

                      <Route path="/index.html">
                        <Redirect to="/" />
                      </Route>
                      <Route exact path="/">
                        <ReferenceSubscriptionsProvider>
                          <Overview />
                        </ReferenceSubscriptionsProvider>
                      </Route>

                      <Route path="*">
                        <NotFoundPage />
                      </Route>
                    </Switch>
                  </Grid>
                  <AuthHandler />
                </Grid>
              </Container>

              <div style={{ flex: '0 1 auto', marginTop: '-150px' }}>
                <ContentFooter />
                <Footer />
              </div>
            </OrganizationProvider>
          </div>
        </NotificationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
