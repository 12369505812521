import React, { useRef, useContext, useState } from 'react';
import {
  OrganizationSubscriptionSchema,
  FormInitialValues,
} from 'components/organizationManagement/createOrganizationSubscriptionModal/formSchemaAndInitialValues';
import CreateOrganizationSubscriptionModal from 'components/organizationManagement/createOrganizationSubscriptionModal/createOrganizationSubscriptionModal';
import { Formik } from 'formik';
import { createSubscription } from 'utils/api/subscriptions';
import { AuthContext } from 'contexts/authContext';
import { Redirect } from 'react-router-dom';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';

const CreateOrganizationSubscription = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken } = useContext(AuthContext);
  const closeModal = useRef({ close: false }); //Need to think about better way to handle this, this is the best I can think of at this time
  const {
    modalButtonText = '',
    identityProviderOptions = [],
    environmentOptions = [],
    organizationId,
    productionSubscriptionsLimitInfo,
  } = props;

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const createNewOrganizationSubscription = async (
    values,
    { setStatus, resetForm, setFieldError },
  ) => {
    const { selectEnvironment, subscriptionName } = values ?? {};

    const subscriptionDto = {
      identityProviders: [
        {
          identityProviderId: identityProviderOptions?.[0]?.identityProviderId,
          name: identityProviderOptions?.[0]?.name,
        },
      ],
      subscriptionName,
      subscriptionType: selectEnvironment.value,
      subscriptionTypeId: selectEnvironment.id,
    };

    const { response } = await createSubscription(
      organizationId,
      subscriptionDto,
      accessToken,
    );

    switch (response?.status) {
      case 201:
        //TODO: Think about if we will want to store both the id and name of both organization type and organization archetype in context
        setStatus({ success: true });
        closeModal.current = { close: true }; //Conditional to close modal in the component
        resetForm(FormInitialValues());
        setRedirect({ isRedirect: true, data: { organizationId } });
        break;
      case 400:
        setFieldError(
          'subscriptionName',
          'A subscription with that name already exist.',
        );
        break;
      case 500:
        setStatus({
          success: false,
          message:
            'Error adding new subscription. Please try again or contact your Healthwise developers.',
        });
        break;
      default:
        if (!response) {
          setStatus({ success: false, message: 'Network error.' });
        } else {
          setStatus({ success: false, message: 'Unknown error.' });
        }
        break;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={FormInitialValues()}
      validationSchema={OrganizationSubscriptionSchema}
      onSubmit={createNewOrganizationSubscription}
      children={props => (
        <CreateOrganizationSubscriptionModal
          {...props}
          modalButtonText={modalButtonText}
          closeModal={closeModal}
          environmentOptions={environmentOptions}
          productionSubscriptionsLimitInfo={productionSubscriptionsLimitInfo}
        />
      )}
    />
  );
};

export default CreateOrganizationSubscription;
