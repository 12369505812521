import React, { useRef, useState, useContext } from 'react';
import CreateOrganizationAdministratorModal from 'components/organizationManagement/createOrganizationAdministratorModal//createOrganizationAdministratorModal';
import {
  CreateOrganizationAdministratorSchema,
  FormInitialValues,
} from 'components/organizationManagement/createOrganizationAdministratorModal/formSchemaAndInitialValues';
import { Formik } from 'formik';
import { createOrganizationAdministrator } from 'utils/api/organizations';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import {
  ERROR_MESSAGE_BAD_REQUEST,
  ERROR_MESSAGE_INTERNAL_SERVER_ERROR,
  ERROR_MESSAGE_NETWORK_ERROR,
  ERROR_MESSAGE_UNKNOWN_ERROR,
} from 'utils/dictionary';

// Given a status, figure out the default message for the response
const getDefaultMessage = response => {
  switch (response?.status) {
    case 400:
      return ERROR_MESSAGE_BAD_REQUEST;
    case 500:
      return ERROR_MESSAGE_INTERNAL_SERVER_ERROR;
    default:
      if (response === null) {
        return ERROR_MESSAGE_NETWORK_ERROR;
      } else {
        return ERROR_MESSAGE_UNKNOWN_ERROR;
      }
  }
};

const CreateOrganizationAdministrator = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken } = useContext(AuthContext);
  const closeModal = useRef({ close: false }); //Need to think about better way to handle this, this is the best I can think of at this time

  const { organization = {} } = props ?? {};
  const { organizationId, identityProviders } = organization;

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const addOrganizationAdministrator = async (
    values,
    { setStatus, resetForm },
  ) => {
    const { email, firstName, lastName } = values;
    const { identityProviderId } = identityProviders?.[0] ?? {};

    const administratorDto = {
      email,
      firstName,
      identityProviderId,
      lastName,
      organizationId,
    };

    const { response, error } = await createOrganizationAdministrator(
      organizationId,
      administratorDto,
      accessToken,
    );

    if (response.ok) {
      setStatus({
        isError: false,
        message: `New Administrator ${firstName} ${lastName} added successfully.`,
      });
      closeModal.current = { close: true }; //Conditional to close modal in the component
      resetForm(FormInitialValues);
      setRedirect({ isRedirect: true, data: { organizationId } });
    } else {
      if (response?.status === 400) {
        setStatus({
          isError: true,
          message:
            'Error adding new Administrator. Please try again or contact your Healthwise developers.',
        });
      } else {
        setStatus({
          isError: true,
          message:
            error?.details?.error?.message ?? getDefaultMessage(response),
        });
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={FormInitialValues}
      validationSchema={CreateOrganizationAdministratorSchema}
      onSubmit={addOrganizationAdministrator}
      children={props => (
        <CreateOrganizationAdministratorModal
          {...props}
          organizationId={organizationId}
          modalButtonText="Add New Administrator"
          closeModal={closeModal}
        />
      )}
    />
  );
};

export default CreateOrganizationAdministrator;
