import React from 'react';
import { useField } from 'formik';
import { InputAdornment, TextField, InputLabel } from '@mui/material';

import { ErrorIcon, SuccessIcon } from 'components/icons/icons';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';

import style from './textInput.module.scss';
// TODO Implement: https://healthwise.atlassian.net/wiki/spaces/DP/pages/1513095584/Text+Field

// Non-formik controlled component
export const BaselineTextField = props => {
  // Set 'our' defaults so that they can be overriden
  const {
    fullWidth = true,
    variant = 'filled',
    label,
    className,
    externalLabel,
    placeholder,
    arialabel,
    ...otherProps
  } = props;

  return (
    <span>
      {externalLabel ? (
        <InputLabel className={style.inputLabel} htmlFor={otherProps.id}>
          {externalLabel}
        </InputLabel>
      ) : null}
      <TextField
        className={`${style.muiTextField} ${className} ${style.muiWrapper}`}
        label={label}
        aria-label={arialabel}
        placeholder={placeholder}
        fullWidth={fullWidth}
        variant={variant}
        {...otherProps}
      />
    </span>
  );
};

const determineEndStatusIcon = (isSubmitting, meta) => {
  if (meta.touched) {
    if (isSubmitting) {
      return (
        <InputAdornment position="start">
          <ProgressIndicator />
        </InputAdornment>
      );
    } else if (meta.error) {
      return (
        <InputAdornment position="start">
          <ErrorIcon fontSize="small" />
        </InputAdornment>
      );
    } else {
      // default success
      return (
        <InputAdornment position="start">
          <SuccessIcon fontSize="small" />
        </InputAdornment>
      );
    }
  } else {
    return null;
  }
};

export const BaselineTextInput = props => {
  const { formikprops, className, errorlabel } = props;
  const [field, meta] = useField(props);

  const isSubmitting = formikprops?.isSubmitting ?? false;
  const endAdornment = determineEndStatusIcon(isSubmitting, meta);

  return (
    <BaselineTextField
      {...field}
      {...props}
      className={`${style.baselineTextField} ${className}`}
      error={meta.touched && meta.error ? true : false}
      helperText={
        meta.touched && meta.error
          ? `${meta.error}: ${errorlabel} is a required field and must have a value`
          : null
      }
      InputProps={{
        endAdornment: endAdornment,
      }}
    />
  );
};
