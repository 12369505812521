import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from 'components/dialog/dialog';

import {
  GreenGeneralButton,
  GeneralCancelButton,
} from 'components/buttons/button';

import {
  BaselineTextField,
  BaselineTextInput,
} from 'components/textInputV2/textInput';

import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { searchUsers } from 'utils/api/organizations';
import { AuthContext } from 'contexts/authContext';
import { NotificationContext } from 'contexts/notificationContext';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';
import style from './createOrganizationAdministratorModal.module.scss';

const CreateOrganizationAdministratorModal = props => {
  const [open, setOpen] = useState(false);

  const {
    modalButtonText,
    isValid,
    dirty,
    isSubmitting,
    status,
    closeModal,
    resetForm,
    setFieldValue,
    setFieldTouched,
    organizationId,
  } = props;

  const [searchEmail, setSearchEmail] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [email, setEmail] = React.useState('');

  const { accessToken } = useContext(AuthContext);

  const notificationContext = React.useContext(NotificationContext);

  const { isError, message = null } = status ?? {};

  useEffect(() => {
    if (message) {
      notificationContext.showNotification(message, isError, 5000);
      status.message = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, message]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetForm();
    setOpen(false);
    setSearchResults([]);
    setSearchEmail('');
    setUsers([]);
    setEmail('');
  };

  const fetchData = useMemo(
    () =>
      debounce(async value => {
        let { response, data } = await searchUsers(
          organizationId,
          0,
          20,
          accessToken,
          value,
        );
        if (response?.ok && data) {
          let userInfo = data.reduce(
            (a, x) => ({
              ...a,
              [x.email + ' - ' + x.firstName + ' ' + x.lastName]: {
                email: x.email,
                firstName: x.firstName,
                lastName: x.lastName,
              },
            }),
            {},
          );
          setUsers(userInfo);

          let emails = Object.keys(userInfo);
          setSearchResults(emails);
        }
      }, 200),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (searchEmail.length > 0) {
      fetchData(searchEmail);
    } else {
      setSearchResults([]);
    }
  }, [searchEmail, fetchData]);

  useEffect(() => {
    closeModal.current = { close: false };
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal]);

  return (
    <>
      <GreenGeneralButton
        buttonText={modalButtonText}
        key="add"
        handleClick={handleOpen}
        className={style.createButton}
      />

      <Dialog
        aria-labelledby="CreateNewOrganizationAdministratorModalTitle"
        aria-describedby="CreateNewOrganizationAdministratorModalDescription"
        open={open}
        hidden={!open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
        data-testid="addAdminDialog"
      >
        <Form>
          <DialogTitle id="CreateNewOrganizationAdministratorModalTitle">
            Add New Administrator
          </DialogTitle>
          <DialogContent className={style.dialogContent}>
            <DialogContentText id="CreateNewOrganizationAdministratorModalDescription">
              Creating a new Organization Administrator will give this user
              access to all applications and subscriptions. They will have the
              ability to manage users and permissions.
            </DialogContentText>
            <Autocomplete
              freeSolo
              value={email}
              filterOptions={x => x}
              inputValue={searchEmail}
              onChange={(event, newValue) => {
                const user = users[newValue];
                if (user) {
                  setEmail(user.email);
                  setFieldValue('email', user.email);
                  setFieldValue('firstName', user.firstName);
                  setFieldValue('lastName', user.lastName);
                  setTimeout(() => {
                    setFieldTouched('email');
                    setFieldTouched('firstName');
                    setFieldTouched('lastName');
                  }, 100);
                }
              }}
              onInputChange={(event, newInputValue) => {
                if (newInputValue === '') {
                  setFieldValue('firstName', '');
                  setFieldValue('lastName', '');
                }
                setSearchEmail(newInputValue);
              }}
              onBlur={event => {
                setEmail(event.target.value);
                setFieldValue('email', event.target.value);
                setTimeout(() => {
                  setFieldTouched('email');
                }, 100);
              }}
              id="emailAddress"
              options={searchResults}
              renderInput={params => (
                <BaselineTextField
                  {...params}
                  externalLabel="Email Address"
                  errorlabel="Email Address"
                  name="emailAddress"
                  className={style.textField}
                />
              )}
            />
            <div className={style.nameInputs}>
              <BaselineTextInput
                id="firstName"
                externalLabel="First Name"
                errorlabel="First Name"
                name="firstName"
                className={style.nameInput}
              />
              <BaselineTextInput
                id="lastName"
                externalLabel="Last Name"
                errorlabel="Last Name"
                name="lastName"
                className={style.nameInput}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <GeneralCancelButton
              handleClick={handleClose}
              disabled={isSubmitting}
            >
              Cancel
            </GeneralCancelButton>
            <GreenGeneralButton
              variant="contained"
              type="submit"
              disabled={!(isValid && dirty) || isSubmitting}
              buttonText="Add New Administrator"
            >
              {isSubmitting ? <ProgressIndicator /> : null}
            </GreenGeneralButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

CreateOrganizationAdministratorModal.propTypes = {
  secondary: PropTypes.bool,
  modalButtonText: PropTypes.string.isRequired,
};

export default CreateOrganizationAdministratorModal;
