import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from 'contexts/authContext';

import { getUserProfile } from 'utils/api/userProfile';

// TODO Also consider a 'isLoaded' boolean?
const ProfileSchema = {
  created: '',
  email: '',
  firstName: '',
  identityProviderId: '',
  lastLogin: '',
  lastName: '',
  organizationId: '',
  referenceId: '',
  roles: [],
  status: '',
  userId: '',
};

export const ProfileContext = React.createContext();

/**
 * This converts the 'roles' returned by the user profile endpoint into a data
 * structure that is more easily leveraged in other components. Basically it
 * expands the role string into a fleshed out object.
 */
const parseSelectedRoles = profile => {
  const selectedRoles = profile.roles.map(role => {
    const parsedRole = role.referenceId.split('::');
    const subscriptionId = parsedRole[1];
    const applicationId = parsedRole[2];
    const roleName = parsedRole[3];

    return {
      name: roleName,
      id: `${subscriptionId}::${applicationId}::${roleName}`,
      value: roleName,
      subscriptionId: subscriptionId,
      applicationId: applicationId,
    };
  });
  return selectedRoles;
};

export const ProfileProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  const [profile, setProfile] = useState(ProfileSchema);

  useEffect(() => {
    async function fetchUserProfile(userId, accessToken) {
      try {
        let { response, data, error } = await getUserProfile(accessToken);
        if (response.ok && data.userId === userId) {
          data['selectedRoles'] = parseSelectedRoles(data);
          setProfile(data);
        } else {
          // We got a 4xx / 5xx in response
          throw new Error(`${response.status} - ${error.message}`);
        }
      } catch (err) {
        // TODO Render error to user
        // console.error('Unable to get the current user');
        // console.error(err);
      }
    }

    if (
      profile.userId === '' &&
      authContext &&
      authContext.accessToken &&
      authContext.permissions &&
      authContext.permissions.userId
    ) {
      fetchUserProfile(authContext.permissions.userId, authContext.accessToken);
    }
  }, [
    profile,
    profile.userId,
    authContext,
    authContext.accessToken,
    authContext?.permissions?.userId,
  ]);

  return (
    <ProfileContext.Provider value={{ profile }}>
      {children}
    </ProfileContext.Provider>
  );
};

/**
 * Return the profile as loaded by the context. Helper function so you don't have to import useContext.
 */
export const useProfileContext = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('User Profile Context must be used within a Provider');
  }
  return context;
};
