import React, { useEffect, useContext, useReducer, useState } from 'react';

import { AuthContext } from 'contexts/authContext';
import { NotificationContext } from 'contexts/notificationContext';
import ModifyUserFormV2 from 'pages/users/modifyUser/modifyUserForm';

import { getUser, modifyUser, modifyUserRoles } from 'utils/api/users';
import { useReferenceSubscriptions } from 'contexts/referenceSubscriptionsContext';
import { OrganizationContext } from 'contexts/organizationContext';

import {
  emptyUser,
  UserValidationSchemaDefinition,
  getCustomUserValidationSchema,
  generateFlattenedRoles,
  deduplicateArray,
} from './user';

const userReducerFunction = (user, newInformation) => {
  return { ...user, ...newInformation };
};

const ModifyUser = props => {
  const { userToEdit } = props;

  let userId = userToEdit;

  // Get permissions of current user
  const authContext = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const { referenceSubscriptions } = useReferenceSubscriptions();
  const { organization } = useContext(OrganizationContext);

  // Setup the access token
  useEffect(() => {
    if (authContext?.accessToken) {
      setUserInfo({ accessToken: authContext.accessToken });
    }
    if (organization?.organizationId) {
      setUserInfo({ organizationId: organization?.organizationId });
    }
    if (userId) {
      setUserInfo({ id: userId });
    }
    if (organization?.identityProviders) {
      setUserInfo({
        identityProviderId:
          organization?.identityProviders[0]?.identityProviderId,
      });
    }
  }, [authContext, userId, organization]);

  // Setup the user information, see userSchema for the fields we can leverage.
  const [userInfo, setUserInfo] = useReducer(userReducerFunction, emptyUser);
  const [statusChanged, setStatusChanged] = useState(false);

  const changeStatus = () => {
    setStatusChanged(!statusChanged);
  };

  useEffect(() => {
    async function getUserInformation() {
      try {
        const { response, data, error } = await getUser(
          userInfo.id,
          userInfo.accessToken,
        );

        if (response.ok && data.userId === userInfo.id) {
          // Format the roles to the expected format. Namely, the 'id' field
          const selectedRoles = data.roles.map(role => {
            const parsedRole = role.referenceId.split('::');
            const subscriptionId = parsedRole[1];
            const applicationId = parsedRole[2];
            const rolename = parsedRole[3];

            return {
              name: rolename,
              id: `${subscriptionId}::${applicationId}::${rolename}`,
              value: rolename,
              subscriptionId: subscriptionId,
              applicationId: applicationId,
            };
          });

          // Set the data for the user
          setUserInfo({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            id: userInfo.id,
            lastLogin: data.lastLogin,
            status: data.status,
            selectedRoles: [...new Set(selectedRoles)],
          });
        } else {
          // We got a 4xx / 5xx in response
          throw new Error(`${response.status} - ${error.message}`);
        }
      } catch (err) {
        // TODO Render Error to user
        // console.error('Unable to get the current user');
        // console.error(err);
      }
    }

    if (userInfo.id && userInfo.accessToken) {
      getUserInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.id, userInfo.accessToken, statusChanged]);

  useEffect(() => {
    if (userInfo.selectedRoles && referenceSubscriptions) {
      const existingRoles = userInfo.selectedRoles;

      let allUserSubscriptions = existingRoles
        .map(role => {
          const subscriptionLookup = referenceSubscriptions.find(
            referenceSubscription =>
              referenceSubscription.id === role.subscriptionId,
          );

          if (subscriptionLookup) {
            return {
              id: subscriptionLookup.id,
              name: subscriptionLookup.name,
              value: subscriptionLookup.id,
              description: subscriptionLookup.description,
            };
          }
          return undefined;
        })
        .filter((subscription, index, self) => {
          return subscription && self.indexOf(subscription) === index;
        });

      let userSubscriptions = deduplicateArray(allUserSubscriptions);

      let allUserApplications = existingRoles
        .map(role => {
          const subscriptionId = role.subscriptionId;

          const foundSubscription = referenceSubscriptions.find(
            referenceSubscription =>
              referenceSubscription.id === subscriptionId,
          );
          const foundApplication = foundSubscription?.applications.find(
            referenceApplication =>
              referenceApplication.id === role.applicationId,
          );

          if (foundApplication) {
            return {
              id: `${subscriptionId}::${foundApplication.id}`,
              name: foundApplication.name,
              value: foundApplication.id,
              clientId: foundApplication.clientId,
              roles: foundApplication.roles,
              subscriptionId: subscriptionId,
            };
          }
          return undefined;
        })
        .filter(application => {
          return application;
        });

      let userApplications = deduplicateArray(allUserApplications);

      setUserInfo({
        selectedSubscriptions: [...new Set(userSubscriptions)],
        selectedApplications: [...new Set(userApplications)],
      });
    }
  }, [userInfo.selectedRoles, referenceSubscriptions]);

  const modifyUserFields = async (values, { setSubmitting, setStatus }) => {
    const payload = {
      userId: userInfo.id,
      firstName: values.firstName,
      lastName: values.lastName,
    };

    const { response, error } = await modifyUser(
      userInfo.id,
      userInfo.accessToken,
      payload,
    );
    if (response.ok) {
      setStatus({ success: true });
      setSubmitting(false);
      notificationContext.showNotification(
        'User name updated successfully.',
        false,
        10000,
      );
      changeStatus();
      return true;
    } else {
      setStatus({ success: false, error: error });
      setSubmitting(false);
      notificationContext.showNotification(
        'Error updating user name. Please try again or contact your Healthwise administrator.',
        true,
        10000,
      );
      return false;
    }
  };

  const modifyUserRolesEntries = async (
    values,
    { setSubmitting, setStatus },
  ) => {
    const roles = generateFlattenedRoles(
      values.organizationId,
      values.selectedRoles,
    ).map(r => r.referenceId);

    const payload = {
      userId: userInfo.id,
      roles: roles,
    };

    const { response, error } = await modifyUserRoles(
      userInfo.id,
      userInfo.accessToken,
      payload,
    );
    if (response.ok) {
      setStatus({ success: true });
      setSubmitting(false);
      notificationContext.showNotification(
        'User permissions updated successfully.',
        false,
        10000,
      );
      changeStatus();
    } else {
      setStatus({ success: false, error: error });
      setSubmitting(false);
      notificationContext.showNotification(
        'Error updating user permissions. Please try again or contact your Healthwise administrator.',
        true,
        10000,
      );
    }
  };

  return (
    <ModifyUserFormV2
      userInfo={userInfo}
      validationSchemaDefinition={UserValidationSchemaDefinition}
      getCustomUserValidationSchema={getCustomUserValidationSchema}
      onSubmitUserFields={modifyUserFields}
      onSubmitUserRoles={modifyUserRolesEntries}
      changeStatus={changeStatus}
    />
  );
};
export default ModifyUser;
