/** Maintainer's Note:
 * When adding icons into this component, please make sure to keep the list alphabetical and add the component to the storybook.
 */

import {
  ArrowBack,
  ArrowBackIos,
  ArrowDropDown,
  ArrowRight,
  Block,
  Cancel,
  CheckCircle,
  ChevronRight,
  Clear,
  Close,
  Delete,
  Done,
  Edit,
  Email,
  Error,
  HomeRounded,
  Info,
  KeyboardArrowDown,
  Loop,
  MenuRounded,
  MoreHoriz,
  PersonAdd,
  PlayCircleOutline,
  RemoveCircleOutline,
  Search,
  Send,
  Settings,
} from '@mui/icons-material';

import { ReactComponent as Deactivate } from 'assets/icons/deactivate-user.svg';
import { ReactComponent as DomainAdd } from 'assets/icons/domain-add.svg';
import { ReactComponent as SwitchOff } from 'assets/icons/switch-off.svg';
import { ReactComponent as SwitchOn } from 'assets/icons/switch-on.svg';
import { ReactComponent as AngleDown } from 'assets/icons/angle-down.svg';
import { ReactComponent as AngleUp } from 'assets/icons/angle-up.svg';
import { ReactComponent as UserCogColor } from 'assets/icons/user-cog-c.svg';
import { ReactComponent as DiagramColor } from 'assets/icons/diagram-c.svg';
import { ReactComponent as DelegateColor } from 'assets/icons/delegate-c.svg';
import { ReactComponent as ForceReset } from 'assets/icons/force-reset.svg';
import { ReactComponent as WhiteLogo } from 'assets/icons/healthwise-logo-white.svg';
import { ReactComponent as Logo } from 'assets/icons/healthwise-logo.svg';
import { ReactComponent as Delegate } from 'assets/icons/delegate.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as LockReset } from 'assets/icons/lock_reset.svg';
import { ReactComponent as RemoveModerator } from 'assets/icons/remove_moderator.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Apps } from 'assets/icons/apps.svg';

import style from './icons.module.scss';

export const AngleDownIcon = props => (
  <AngleDown style={{ width: '1rem' }} {...props} />
);

export const AngleUpIcon = props => (
  <AngleUp style={{ width: '1rem' }} {...props} />
);

export const ArrowBackIcon = props => <ArrowBack {...props} />;

export const ArrowBackIosIcon = props => <ArrowBackIos {...props} />;

// TODO color doesn't match a theme reference
export const ArrowDropDownIcon = props => (
  <ArrowDropDown style={{ color: '#898989' }} {...props} />
);

export const ArrowRightIcon = props => <ArrowRight {...props} />;

export const BlockIcon = props => <Block {...props} />;

export const CancelIcon = props => <Cancel {...props} />;

export const CheckCircleIcon = props => <CheckCircle {...props} />;

export const ChevronRightIcon = props => <ChevronRight {...props} />;

export const ClearIcon = props => <Clear {...props} />;

export const CloseIcon = props => <Close {...props} />;

export const DeactivateIcon = props => (
  <Deactivate style={{ color: '#dd372f', width: '1.5rem' }} {...props} />
);

export const DelegateColorIcon = props => (
  <DelegateColor style={{ width: '1.5rem' }} {...props} />
);

export const DelegateIcon = props => <Delegate {...props} />;

export const DeleteIcon = props => {
  const { className = '', ...otherProps } = props;
  return (
    <Delete className={`${style.deleteIcon} ${className}`} {...otherProps} />
  );
};

export const DiagramColorIcon = props => <DiagramColor {...props} />;

export const DomainAddIcon = props => <DomainAdd {...props} />;

export const DoneIcon = props => <Done {...props} />;

export const EditIcon = props => <Edit {...props} />;

export const EmailIcon = props => <Email {...props} />;

export const ErrorIcon = props => (
  <Error style={{ color: '#dd372f', paddingBottom: '0.75rem' }} {...props} />
);

export const ForceResetIcon = props => (
  <ForceReset style={{ width: '1.5rem' }} {...props} />
);

export const HomeRoundedIcon = props => <HomeRounded {...props} />;

export const InfoIcon = props => (
  <Info style={{ color: '#898989' }} {...props} />
);

export const KeyboardArrowDownIcon = props => <KeyboardArrowDown {...props} />;

export const LockResetIcon = props => (
  <LockReset style={{ width: '1.5rem', fill: '#424242' }} {...props} />
);

export const LogoIcon = props => <Logo {...props} />;

export const MenuRoundedIcon = props => <MenuRounded {...props} />;

export const MoreHorizIcon = props => (
  <MoreHoriz style={{ color: '#424242' }} {...props} />
);

export const PersonAddIcon = props => <PersonAdd {...props} />;

export const PlayCircleOutlineIcon = props => <PlayCircleOutline {...props} />;

export const RemoveIcon = props => <RemoveCircleOutline {...props} />;

export const RemoveModeratorIcon = props => (
  <RemoveModerator style={{ width: '1.5rem', fill: '#DD372F' }} {...props} />
);

export const ResetIcon = props => (
  <Loop style={{ width: '1.5rem', fill: '#424242' }} {...props} />
);

export const SearchIcon = props => <Search {...props} />;

export const SendIcon = props => (
  <Send style={{ fill: '#424242' }} {...props} />
);

export const SettingsIcon = props => <Settings {...props} />;

export const SuccessIcon = props => (
  <Done style={{ color: '#006272', paddingBottom: '14px' }} {...props} />
);

export const ToggleOffIcon = props => (
  <SwitchOff style={{ width: '1.6rem' }} {...props} />
);

export const ToggleOnIcon = props => (
  <SwitchOn style={{ width: '1.6rem' }} {...props} />
);

export const TrashCanIcon = props => <DeleteIcon {...props} />;

export const UserCogColorIcon = props => <UserCogColor {...props} />;

export const UserIcon = props => <User {...props} />;

export const WhiteLogoIcon = props => <WhiteLogo {...props} />;

export const LogoutIcon = props => <Logout {...props} />;

export const AppsIcon = props => <Apps {...props} />;
