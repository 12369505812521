import React, { useRef, useContext, useState } from 'react';
import EditSubscriptionConfigureProductsModalV2 from 'components/organizationManagement/editSubscriptionConfigureProductsModal/editSubscriptionConfigureProductsModalV2';
import { Formik } from 'formik';
import {
  FormInitialValues,
  EditSubscriptionConfigureProductsSchema,
} from 'components/organizationManagement/editSubscriptionConfigureProductsModal/formSchemaAndInitialValues';
import { configureProductFeaturesAndContent } from 'utils/api/provisioning';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts/authContext';
import {
  getSelectedAssets,
  getSelectedModuleApplications,
  getSelectedAvailableModules,
  getSelectedAvailableAssets,
} from 'containers/organizationManagement/editSubscriptionConfigureProducts/utils';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';

const EditSubscriptionConfigureProducts = props => {
  const closeModal = useRef({ close: false }); //Need to think about better way to handle this, this is the best I can think of at this time
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken } = useContext(AuthContext);

  const { organizationId, rowData = {}, entitlementPacketData } = props ?? {};
  const { subscriptionId, subscriptionName } = rowData;

  //TODO: render notification
  // if (errorMessage) {}

  const { entitlementPacket = {}, subscriptionLicense = {} } =
    entitlementPacketData ?? {};

  //Entitlement Packet
  const { licensing = {} } = entitlementPacket;
  const { assets: availableAssets = {}, packages = [] } = licensing;

  //Subscription License
  const { licenseKeyId, licenseKey = {} } = subscriptionLicense;
  const { payload = {} } = licenseKey;
  const { applications: licenseApplications = [], assets: licenseAssets = {} } =
    payload;

  const availableModules = packages
    ?.flatMap(item => item?.modules)
    .filter(a => a.applications.length !== 0);

  const selectedModules = getSelectedAvailableModules(
    licenseApplications,
    availableModules,
  );

  let assets = getSelectedAvailableAssets(licenseAssets, availableAssets);

  if (
    Object.keys(selectedModules).length === 0 &&
    rowData?.subscriptionTypeId !== 5
  ) {
    assets = availableAssets;
  }

  const handleReset = resetForm => {
    resetForm(FormInitialValues(selectedModules, assets));
  };

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const editConfigureSubscriptionProducts = async (
    values,
    { setStatus, resetForm },
  ) => {
    const { validFrom, validTo } = entitlementPacket;

    const selectedAssets = getSelectedAssets(values?.assets);

    const selectedModuleApplications = getSelectedModuleApplications(
      values?.modules,
    );

    const ProvisioningProcessDto = {
      provisionRequest: {
        provisioningType: 'Add',
        contentProvisioning:
          Object.keys(selectedAssets ?? {})?.map(asset => {
            return { assetCategory: asset };
          }) ?? [],
        applicationProvisioning: selectedModuleApplications.map(app => ({
          applicationId: app.id,
        })),
      },
      licenseKey: {
        id: licenseKeyId,
        validFrom,
        validTo,
        applications: selectedModuleApplications,
        assets: selectedAssets,
      },
    };

    const { response, error } = await configureProductFeaturesAndContent(
      organizationId,
      subscriptionId,
      ProvisioningProcessDto,
      accessToken,
    );

    switch (response?.status) {
      case 201:
        //TODO: Think about if we will want to store both the id and name of both organization type and organization archetype in context
        setStatus({
          success: true,
          message: `Successfully modified ${subscriptionName}`,
        });
        closeModal.current = { close: true }; //Conditional to close modal in the component
        resetForm(FormInitialValues(selectedModules, assets));
        setRedirect({ isRedirect: true, data: { organizationId } });
        break;
      case 400:
        if (error.details.error.code === 'MalformedProvisionRequestException') {
          setStatus({
            success: false,
            message:
              'Error modifying subscription. Please try again or contact your Healthwise developers.',
          });
        } else if (error.details.error.code === 'CompositeError') {
          //TODO: Display specific error to the user
          setStatus({
            success: false,
            message: `${error.details.error.message}. Please refresh the page.`,
          });
        } else {
          setStatus({
            success: false,
            message: 'Bad request. Please try again.',
          });
        }
        break;
      case 404:
        setStatus({ success: false, message: 'Not found.' });
        break;
      case 500:
        setStatus({
          success: false,
          message:
            'Error modifying subscription. Please try again or contact your Healthwise developers.',
        });
        break;
      default:
        if (!response) {
          setStatus({ success: false, message: 'Network error.' });
        } else {
          setStatus({ success: false, message: 'Unknown error.' });
        }
        break;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={FormInitialValues(selectedModules, assets)}
      validationSchema={EditSubscriptionConfigureProductsSchema(
        rowData?.subscriptionTypeId,
      )}
      onSubmit={editConfigureSubscriptionProducts}
      children={props => (
        <>
          <EditSubscriptionConfigureProductsModalV2
            {...props}
            modalButtonText="Modify Subscription"
            closeModal={closeModal}
            selectedModules={selectedModules}
            availableAssets={availableAssets}
            availableModules={availableModules}
            licenseAssets={assets}
            subscription={rowData}
            resetFormOnClose={handleReset.bind(null, props.resetForm)}
          />
        </>
      )}
    />
  );
};

export default EditSubscriptionConfigureProducts;
