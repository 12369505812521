import { useState, useEffect } from 'react';
import styles from './inlineTextEdit.module.scss';
import { BaselineTextField } from 'components/textInputV2/textInput';

const MultilineEdit = ({ value, inputLabel, setValue, enabled = true }) => {
  const [editingValue, setEditingValue] = useState(value);

  const onChange = event => setEditingValue(event.target.value);

  const onKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  };

  const onBlur = event => {
    if (event.target.value.trim() === '') {
      setEditingValue(value);
    } else {
      setValue(event.target.value);
    }
  };

  useEffect(() => {
    setEditingValue(value);
  }, [value]);

  return (
    <>
      <div className={styles.inlineEditCheckWrapper}>
        <BaselineTextField
          className={styles.inlineEdit}
          label={inputLabel}
          value={editingValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          disabled={enabled}
          multiline
          rows={4}
          fullWidth={true}
        />
      </div>
    </>
  );
};

export default MultilineEdit;
