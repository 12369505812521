import * as Yup from 'yup';

export const SubscriptionSwitchSchema = Yup.object().shape({
  subscriptionSwitch: Yup.string()
    .lowercase()
    .matches(/^(confirm)$/, {
      message: 'Must match "confirm"',
    }),
});

export const SubscriptionSwitchEmptySchema = Yup.object().shape({});

export const SubscriptionSwitchInitialValues = { subscriptionSwitch: '' };

export const SubscriptionSwitchEmptyInitialValues = {};
