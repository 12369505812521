import React from 'react';
import { LoginCallback } from '@okta/okta-react';
import PropTypes from 'prop-types';
import * as Cookies from 'js-cookie';

const getFixedJsonString = (value, frontDoorUri, oktaIssuerUri) => {
  // JS string.replace() only replaces the first instance, and replaceAll() support is not widespread in browsers.
  // This split/join method is hacky but it'll work until replaceAll() becomes common.
  return value
    .split(`${frontDoorUri}/oauth2`)
    .join(oktaIssuerUri)
    .split(frontDoorUri)
    .join(oktaIssuerUri);
};

const fixCookie = (cookieName, frontDoorUri, oktaIssuerUri) => {
  const redirectCookie = Cookies.get(cookieName);

  if (redirectCookie) {
    const fixedCookie = getFixedJsonString(
      redirectCookie,
      frontDoorUri,
      oktaIssuerUri,
    );

    Cookies.set(cookieName, fixedCookie, {
      secure: true,
      sameSite: 'None',
      path: '/',
      expires: new Date('2200-01-01T00:00:00.000Z'),
    });
  }
};

const fixStorage = (storage, storageValueName, frontDoorUri, oktaIssuerUri) => {
  const storageValue = storage.getItem(storageValueName);

  if (storageValue) {
    const fixedStorageValue = getFixedJsonString(
      storageValue,
      frontDoorUri,
      oktaIssuerUri,
    );

    storage.setItem(storageValueName, fixedStorageValue);
  }
};

const CustomLoginCallback = ({ frontDoorUri, oktaIssuerUri }) => {
  if (frontDoorUri !== oktaIssuerUri) {
    fixCookie('okta-oauth-redirect-params', frontDoorUri, oktaIssuerUri);
    fixStorage(
      localStorage,
      'okta-shared-transaction-storage',
      frontDoorUri,
      oktaIssuerUri,
    );
    fixStorage(
      sessionStorage,
      'okta-transaction-storage',
      frontDoorUri,
      oktaIssuerUri,
    );
  }

  return <LoginCallback />;
};

CustomLoginCallback.propTypes = {
  frontDoorUri: PropTypes.string.isRequired,
  oktaIssuerUri: PropTypes.string.isRequired,
};

export default CustomLoginCallback;
