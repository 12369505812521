import React from 'react';

import { BaselineTextInput } from 'components/textInputV2/textInput';
import { FormControl } from '@mui/material';
import style from './userDetails.module.scss';

/**
 * The user fields provide a common arrangement of the requested user details
 * with some logic baked in. UserFields is just the raw arrangement of the
 * fields without styling or containering.
 */

const textProps = {
  className: style.userField,
  size: 'medium',
  variant: 'standard',
};

export const UserFields = props => {
  return (
    <FormControl>
      <label htmlFor="firstName">First Name</label>
      <BaselineTextInput
        id="firstName"
        name="firstName"
        errorlabel="First Name"
        {...textProps}
      />
      <label htmlFor="lastName">Last Name</label>
      <BaselineTextInput
        id="lastName"
        name="lastName"
        errorlabel="Last Name"
        {...textProps}
      />
    </FormControl>
  );
};

/**
 * UserDetails provides the common arrangement of the requested user details
 *  wrapped in a card for ease of use and styling.
 */

// For Refactoring, Can we just delete this and combine this with UserFields?
export const UserDetails = props => {
  return <UserFields {...props} />;
};

export default UserDetails;
