import React from 'react';
import { Grid } from '@mui/material';

import { TrashButton } from 'components/buttons/button';
import { ApplicationRoleSelectorSelectInput } from 'components/multipleSelectInputV2/multipleSelectInput';
import { SingleSelectInput } from 'components/singleSelectInputV2/singleSelectInput';

import style from './subscriptionSelectorRow.module.scss';

export const SubscriptionSelectorRow = props => {
  const {
    compact = false,
    subscriptions,
    selectedSubscription,
    selectedApplication,
    selectedRoles,
    touched,
    errors,
    setFieldTouched,
    subscriptionChangeHandler,
    applicationChangeHandler,
    roleChangeHandler,
    rowRemovedHandler,
    row,
  } = props;

  const [selectedSubscriptionInState, setSelectedSubscription] =
    React.useState(selectedSubscription);
  const [selectedApplicationInState, setSelectedApplication] =
    React.useState(selectedApplication);
  const [selectedRolesInState, setSelectedRoles] = React.useState(
    selectedRoles || [],
  );

  // Replace the selectedSubscription in state if it changes (e.g. if the "applications" array is different
  // because of sub + app exclusions in the subscriptionSelector)
  React.useEffect(() => {
    setSelectedSubscription(selectedSubscription);
    let inputElements = document.querySelectorAll('input');
    inputElements.forEach(ele => ele.setAttribute('required', 'true'));
  }, [setSelectedSubscription, selectedSubscription]);

  const handleSubscriptionSelected = value => {
    if (
      value &&
      (!selectedSubscriptionInState ||
        value?.id !== selectedSubscriptionInState?.id)
    ) {
      resetRoles();

      setSelectedSubscription(value);
      setSelectedApplication(undefined);
      subscriptionChangeHandler(value, row);
    }
  };

  const handleApplicationSelected = value => {
    if (
      value &&
      (!selectedApplicationInState || value?.id !== selectedRolesInState?.id)
    ) {
      resetRoles();

      setSelectedApplication(value);
      applicationChangeHandler(value, row);
    }
  };

  const resetRoles = () => {
    if (selectedRolesInState?.length > 0) {
      handleRoleChanged(
        [],
        selectedSubscriptionInState.id,
        selectedApplicationInState.id,
      );
    }
  };

  const handleSubscriptionRemoved = () => {
    rowRemovedHandler(row);
  };

  const handleRoleChanged = (value, subscriptionId, applicationId) => {
    setSelectedRoles(value);
    roleChangeHandler(value, subscriptionId, applicationId, row);
  };

  const renderSubscription = () => {
    return (
      <Grid
        item
        xs={compact ? 10 : 3}
        className={`${style.rowField} ${
          selectedSubscriptionInState ? style.rowFieldPopulated : ''
        }
        ${compact ? 'compact' : ''}
        `}
      >
        <SingleSelectInput
          name="subscriptionSelect"
          placeholder={
            selectedSubscriptionInState ? null : 'Add a subscription'
          }
          externalLabel="Subscription"
          variant="outlined"
          optionsList={subscriptions}
          handleChange={(event, value) => {
            handleSubscriptionSelected(value);
          }}
          errors={errors}
          handleBlur={setFieldTouched}
          touched={touched['subscriptionSelect']}
          errorMsg={errors['selectedRoles']}
          selectBlur={true}
          selectedValue={selectedSubscriptionInState}
          disableClearable={true}
          helpMsg=""
        />
      </Grid>
    );
  };

  const renderApplicationRoles = () => {
    return (
      <>
        <Grid
          item
          xs={compact ? 5 : 4}
          className={`${style.rowField} ${
            selectedApplicationInState ? style.rowFieldPopulated : ''
          } ${compact ? `${style.compact} compact` : ''}`}
        >
          <SingleSelectInput
            disabled={!selectedSubscriptionInState}
            name="applicationSelect"
            placeholder={
              selectedApplicationInState ? null : 'Add an application'
            }
            externalLabel="Application"
            variant="outlined"
            optionsList={selectedSubscriptionInState?.applications || []}
            handleChange={(_, value) => {
              handleApplicationSelected(value);
            }}
            handleBlur={setFieldTouched}
            touched={touched['applicationSelect']}
            errorMsg={errors['applicationSelect']}
            selectBlur={true}
            selectedValue={selectedApplicationInState}
            disableClearable={true}
            helpMsg=""
          />
        </Grid>
        <Grid
          name="subSelect"
          item
          xs={compact ? true : 3}
          className={`${style.rowField} ${
            selectedRolesInState.length > 0 ? style.rowFieldPopulated : ''
          }
          ${compact ? 'compact' : ''}
         `}
        >
          <ApplicationRoleSelectorSelectInput
            className={style.rowField}
            disabled={
              !selectedSubscriptionInState || !selectedApplicationInState
            }
            placeholder={
              selectedRolesInState && selectedRolesInState.length > 0
                ? null
                : 'Roles'
            }
            externalLabel="Application Roles"
            handleRoleChanged={handleRoleChanged}
            selectName="roleSelect"
            errors={errors}
            value={selectedRolesInState || []}
            disableClearable={true}
            touched={touched}
            optionsList={selectedApplicationInState?.roles || []}
            subscriptionId={selectedSubscriptionInState?.id}
            applicationId={selectedApplicationInState?.id}
            variant="outlined"
          />
        </Grid>
      </>
    );
  };

  const renderTrashIcon = compact => {
    return (
      <Grid item container justifyContent="flex-end" xs>
        <Grid item>
          <TrashButton
            className={`${style.trashIcon} ${compact ? 'compact' : ''}`}
            disabled={!selectedSubscriptionInState}
            tooltip="Remove subscription and any associated applications/roles"
            onClick={() => handleSubscriptionRemoved()}
          />
        </Grid>
      </Grid>
    );
  };

  if (compact) {
    // Render as two smaller rows
    return (
      <>
        <Grid
          container
          className={`${style.rowContainer} compact`}
          data-testid="fieldRow"
        >
          {renderSubscription()}
          {renderTrashIcon(compact)}
        </Grid>
        <Grid container data-testid="fieldRow">
          {renderApplicationRoles()}
        </Grid>
      </>
    );
  } else {
    // Render as a single wide row
    return (
      <Grid container className={style.rowContainer} data-testid="fieldRow">
        {renderSubscription()}
        {renderApplicationRoles()}
        {renderTrashIcon(compact)}
      </Grid>
    );
  }
};
