import React, { useState, useEffect } from 'react';
import styles from './inlinecheckbox.module.scss';

const InlineCheckbox = ({ value, inputLabel, setValue, enabled = true }) => {
  const [editingValue, setEditingValue] = useState('');

  const onChange = element => {
    setEditingValue(element.target.checked);
    setValue(element.target.checked);
  };

  useEffect(() => {
    setEditingValue(value);
  }, [value]);
  return (
    <>
      <div className={styles.inlineEditCheckWrapper}>
        {inputLabel}:
        {enabled ? (
          <input
            className={styles.inlineEditCheck}
            type="checkbox"
            aria-label={inputLabel}
            checked={editingValue}
            onChange={onChange}
          />
        ) : (
          <input
            className={styles.inlineEditCheck}
            type="checkbox"
            aria-label={inputLabel}
            checked={editingValue}
            onChange={onChange}
            disabled
          />
        )}
      </div>
    </>
  );
};

export default InlineCheckbox;
