import { PageHeader } from 'components/pageHeader/pageHeader';
import React, { useEffect, useContext, useState } from 'react';
import { getApplication, updateApplication } from 'utils/api/applications';

import { AuthContext } from 'contexts/authContext';
import InlineEdit from './components/inlineedit';
import { Card } from '@mui/material';

import InlineCheckbox from './components/inlinecheckbox';
import InlineTextEdit from './components/inlineTextEdit';
import EditApplicationRoles from './components/editRoles';
import { useFilePicker } from 'use-file-picker';
import { GreenOutlinedButtonLink } from 'components/buttons/button';
import { NotificationContext } from 'contexts/notificationContext';

import EditableList from 'react-list-editable';
import 'react-list-editable/lib/react-list-editable.css';
import styles from './editapplication.module.scss';

const EditApplication = props => {
  const { applicationId } = props;

  const authContext = useContext(AuthContext);
  const notificationContext = useContext(NotificationContext);
  const [etag, setEtag] = useState('');
  const [status, setStatus] = useState(null);

  const [readOnly, setReadOnly] = useState({
    applicationId: applicationId,
    sourceId: '',
    clientId: '',
    oktaId: '',
    versionNumber: '',
    availableFrom: '',
    active: '',
    available: '',
  });

  //editable fields
  const [newRoute, setNewRoute] = useState('');
  const [newName, setNewName] = useState('');
  const [newfullyQualifiedName, setNewFullyQualifiedName] = useState('');
  const [newOwner, setNewOwner] = useState('');
  const [newOwnerEmail, setNewOwnerEmail] = useState('');
  const [newRedirectUrls, setNewRedirectUrls] = useState([]);
  const [newDescription, setNewDescription] = useState('');
  const [newRoles, setNewRoles] = useState([]);
  const [newIcon, setNewIcon] = useState('');
  const [originalIcon, setOriginalIcon] = useState('');
  const [iconChangeDate, setIconChangeDate] = useState('');

  function setApplicationState(application) {
    application.redirectUrls.map(url => newRedirectUrls.push(url));
    setNewRedirectUrls(application.redirectUrls);

    setReadOnly({
      applicationId: applicationId ?? '',
      sourceId: application.sourceId ?? '',
      clientId: application.clientId ?? '',
      oktaId: application.oktaId ?? '',
      versionNumber: application.versionNumber ?? '',
      availableFrom: application.availableFrom ?? '',
      active: application.active ?? false,
      available: application.available ?? false,
    });

    setNewRoute(application.route ?? '');
    setNewName(application.name ?? '');
    setNewFullyQualifiedName(application.fullyQualifiedName ?? '');
    setNewOwner(application.owner ?? '');
    setNewOwnerEmail(application.ownerEmail ?? '');
    setNewDescription(application.description ?? '');
    setNewRoles(application.roles);
    setOriginalIcon(application.icon ? application.icon : '');
    setIconChangeDate(Date.now());
  }

  function getApplicationToEdit(applicationId, accessToken) {
    getApplication(applicationId, accessToken).then(
      (response, data, etag, error) => {
        if (response.error || response.response.status !== 200) {
          setStatus({
            success: false,
            message: 'Failed to load application',
          });
        } else {
          let application = response.data.data;
          setApplicationState(application);
          setEtag(response.etag);
        }
      },
    );
  }

  useEffect(() => {
    if (applicationId !== undefined && authContext && authContext.accessToken) {
      getApplicationToEdit(applicationId, authContext.accessToken);
    }
    // There's an interesting underlying bug with the EditableList that means we can't
    // list the exhaustive-deps, yet. Way forward is to replace the form handling logic
    // with Formik. See GLAC-308 for details.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, authContext, authContext.accessToken]);

  function onListUpdated(list) {
    setNewRedirectUrls(list);
  }

  const [openFileSelector, { filesContent, loading, errors, clear }] =
    useFilePicker({
      readAs: 'DataURL',
      accept: 'image/svg+xml',
      multiple: true,
      limitFilesConfig: { max: 1 },
      // minFileSize: 0.1, // in megabytes
      maxFileSize: 0.5,
      imageSizeRestrictions: {
        maxHeight: 100, // in pixels
        maxWidth: 130,
        minHeight: 35,
        minWidth: 35,
      },
    });

  useEffect(() => {
    if (filesContent && filesContent.length > 0) {
      setNewIcon(filesContent[0]?.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesContent]);

  if (loading) {
    //return <div>Loading...</div>;
  }

  function setIconToSave() {
    openFileSelector();
  }

  function clearNewIcon() {
    clear();
    setNewIcon(originalIcon);
  }
  function deleteIcon() {
    setNewIcon('');
    setOriginalIcon('');
  }

  function getIconbyteArray() {
    let splitArray = newIcon.split(',');
    return splitArray[1];
  }

  function handleSaveAction() {
    let appToSave = {
      name: newName,
      route: newRoute,
      description: newDescription,
      icon:
        newIcon === ''
          ? originalIcon === ''
            ? null
            : originalIcon
          : getIconbyteArray(),
      fullyQualifiedName: newfullyQualifiedName,
      owner: newOwner,
      ownerEmail: newOwnerEmail === '' ? null : newOwnerEmail,
      redirectUrls: newRedirectUrls,
    };

    updateApplication(
      applicationId,
      appToSave,
      etag,
      authContext.accessToken,
    ).then((response, data, error) => {
      if (response.error || response.response.status !== 200) {
        setStatus({
          success: false,
          message: 'Failed to update application',
        });
      } else {
        setStatus({
          success: true,
          message: 'Application updated successfully',
        });
        setEtag(response.etag);
      }
    });

    setNewIcon('');
  }

  useEffect(() => {
    if (status) {
      notificationContext.showNotification(
        status.message,
        !status.success,
        10000,
        Date.now(),
      );
      setStatus(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      <PageHeader title="Application Details" />

      <div>
        <Card className={styles.card}>
          <div className={styles.saveButtonWraper}>
            <GreenOutlinedButtonLink
              buttonText="Save Application"
              handleClick={() => handleSaveAction()}
            />
          </div>
          <div className={styles.applicationDetailsWrapper}>
            <div>
              <InlineEdit
                value={readOnly.applicationId}
                inputLabel={'ApplicationId'}
                enabled={false}
              />
            </div>
            <div>
              <InlineEdit
                value={readOnly.sourceId}
                inputLabel={'SourceId'}
                enabled={false}
              />
            </div>
            <div>
              <InlineEdit
                value={readOnly.clientId}
                inputLabel={'ClientId'}
                enabled={false}
              />
            </div>

            <div>
              <InlineEdit
                value={readOnly.oktaId}
                inputLabel={'OktaId'}
                enabled={false}
              />
            </div>
            <div>
              <InlineEdit
                value={readOnly.availableFrom}
                inputLabel={'Available From'}
                enabled={false}
              />
            </div>
            <div>
              <InlineEdit
                value={readOnly.versionNumber}
                inputLabel={'Version'}
                enabled={false}
              />
            </div>

            <div>
              <InlineEdit
                value={newName}
                setValue={setNewName}
                inputLabel={'Name'}
                enabled={true}
              />
            </div>

            <div>
              <InlineEdit
                value={newfullyQualifiedName}
                setValue={setNewFullyQualifiedName}
                inputLabel={'Fully Qualified Name'}
                enabled={true}
              />
            </div>
            <div>
              <InlineEdit
                value={newRoute}
                setValue={setNewRoute}
                inputLabel={'Route'}
                enabled={true}
              />
            </div>
            <div>
              <InlineEdit
                value={newOwner}
                setValue={setNewOwner}
                inputLabel={'Owner'}
                enabled={true}
              />
            </div>
            <div>
              <InlineEdit
                value={newOwnerEmail}
                setValue={setNewOwnerEmail}
                inputLabel={'Owner Email'}
                enabled={true}
              />
            </div>
            <div>
              <InlineCheckbox
                value={readOnly.active}
                inputLabel={'Active'}
                enabled={false}
              />{' '}
              <InlineCheckbox
                value={readOnly.available}
                inputLabel={'Available'}
                enabled={false}
              />
            </div>
            <div className={styles.descriptionInput}>
              <InlineTextEdit
                value={newDescription}
                setValue={setNewDescription}
                inputLabel={'Description'}
                enabled={true}
              ></InlineTextEdit>
            </div>

            <div>
              <div className={styles.iconsWrapper}>
                <div className={styles.originalIcon}>
                  Current Icon:
                  <div className={styles.originalIconImageWrapper}>
                    {originalIcon !== '' ? (
                      <img
                        className={styles.originalIconImage}
                        key={iconChangeDate}
                        alt="Application Icon"
                        src={'data:image/svg+xml;base64,' + originalIcon}
                      />
                    ) : (
                      <div>No image uploaded</div>
                    )}
                  </div>
                  {originalIcon !== '' ? (
                    <div className={styles.newIconButtonWrapper}>
                      <button onClick={() => deleteIcon()}>Delete Icon</button>
                    </div>
                  ) : null}
                </div>
                <div className={styles.newIcon}>
                  New Icon:
                  <div className={styles.newIconWrapper}>
                    <div className={styles.newIconImageWrapper}>
                      {filesContent.map((file, index) => (
                        <div key={index}>
                          <img
                            className={styles.newIconImage}
                            alt={file.name}
                            src={file.content}
                          ></img>
                        </div>
                      ))}
                    </div>
                    <div className={styles.newIconButtonWrapper}>
                      <button onClick={() => setIconToSave()}>New Icon</button>
                      <button onClick={() => clearNewIcon()}>Reset Icon</button>
                    </div>
                  </div>
                </div>
                <div className={styles.iconErrors}>
                  {errors[0]?.fileSizeTooSmall && ' File size is too small!'}
                  {errors[0]?.fileSizeToolarge && ' File size is too large!'}
                  {errors[0]?.imageHeightTooBig && ' File height is too large!'}
                  {errors[0]?.imageHeightTooSmall &&
                    ' File height is too small!'}
                  {errors[0]?.imageWidthTooBig && ' File width is too large!'}
                  {errors[0]?.imageWidthTooSmall && ' File width is too small!'}
                  {errors[0]?.readerError &&
                    ' Problem occured while reading file!'}
                  {errors[0]?.maxLimitExceeded && ' Too many files'}
                  {errors[0]?.minLimitNotReached && ' Not enough files'}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.applicationRedirectUrlsTitle}>
            Redirect Urls
          </div>
          <br />
          <div>
            <EditableList
              className={styles.urlInput}
              list={newRedirectUrls}
              onListChange={onListUpdated}
              placeholder="Enter a value"
            />
          </div>
          <div className={styles.saveButtonWraper}>
            <GreenOutlinedButtonLink
              buttonText="Save Application"
              handleClick={() => handleSaveAction()}
            />
          </div>
        </Card>
        <br />
        <EditApplicationRoles
          applicationId={applicationId}
          rolesList={newRoles}
          setRolesMethod={setNewRoles}
          setStatus={setStatus}
        />
      </div>
    </>
  );
};

export default EditApplication;
