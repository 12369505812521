import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Formik } from 'formik';

import ConfirmationModal from 'components/confirmationModal/confirmationModal';
import VerifyModal from 'components/verifyModal/verifyModal';
import {
  GreenOutlinedButtonLink,
  GreenFormSubmitButton,
  RedOutlinedButtonLink,
  EditIconButton,
  CancelButton,
} from 'components/buttons/button';
import {
  UserCardv2,
  UserCardHeaderv2,
  UserCardContentv2,
} from 'components/card/card';
import { USER_MANAGEMENT_PATH } from 'utils/configuration/links';

import Notification from 'components/notificationV2/notification';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';

import RoleAssignment from 'pages/users/modifyUser/components/roleAssignment/roleAssignment';
import { UserDetails } from 'components/userDetails/userDetails';

import { DATE_ONLY } from 'utils/dictionary';
import { FormatISO } from 'utils/timeAndDate';
import ForceResetPasswordWorkflow from 'components/passwordWorkflows/forceResetPasswordWorkflow';
import ResetPasswordWorkflow from 'components/passwordWorkflows/resetPasswordWorkflow';
import { BackToPrevPageLink } from 'components/users/backToPrevPageLink';
import StatusChip from 'components/users/statusChip/statusChip';
import Tooltip from 'components/tooltipV2/tooltip';
import { HorizontalRule } from 'components/horizontalRule/horizontalRule';

import {
  reactivateUser,
  activateUser,
  deactivateUser,
  deleteUser,
} from 'utils/api/users';

import { getDeleteUserProperties } from 'pages/users/modalConfiguration';

import style from './modifyUserForm.module.scss';

const UserActionMenu = ({ isSubmitting, userInfo, handleModalAction }) => {
  const allowResendInvitation = ['PROVISIONED', 'STAGED'].includes(
    userInfo.status,
  );

  const allowDeactivation = [
    'ACTIVE',
    'RECOVERY',
    'PROVISIONED',
    'LOCKED_OUT',
    'PASSWORD_EXPIRED',
  ].includes(userInfo.status);

  const allowActivation = ['SUSPENDED', 'DEPROVISIONED'].includes(
    userInfo.status,
  );

  const allowUnlock = ['LOCKED_OUT'].includes(userInfo.status);

  const allowDelete = ['STAGED', 'SUSPENDED', 'DEPROVISIONED'].includes(
    userInfo.status,
  );

  const handleAction = action => {
    handleModalAction(action, userInfo);
  };

  return (
    <>
      {isSubmitting ? (
        <ProgressIndicator />
      ) : (
        <>
          {allowDelete ? (
            <RedOutlinedButtonLink
              buttonText="Delete User"
              handleClick={() => handleAction('deleteUser')}
            />
          ) : null}
          {allowDelete && <div className={style.buttonDivider} />}
          {allowDeactivation ? (
            <RedOutlinedButtonLink
              buttonText="Deactivate User"
              handleClick={() => handleAction('deactivateUser')}
            />
          ) : null}
          {allowDeactivation && <div className={style.buttonDivider} />}
          {allowResendInvitation ? (
            <GreenOutlinedButtonLink
              buttonText="Resend Invitation"
              handleClick={() => handleAction('resendInvitation')}
            />
          ) : null}
          {allowResendInvitation && <div className={style.buttonDivider} />}
          {allowUnlock ? (
            <GreenOutlinedButtonLink
              buttonText="Unlock"
              handleClick={() => handleAction('activateUser')}
            />
          ) : null}
          {allowUnlock && <div className={style.buttonDivider} />}
          {allowActivation ? (
            <GreenOutlinedButtonLink
              buttonText="Activate User"
              handleClick={() => handleAction('activateUser')}
            />
          ) : null}
        </>
      )}
    </>
  );
};

const resendInvitation = async (userInfo, handleUserEvent) => {
  const apiInvocation = async () =>
    await reactivateUser(userInfo.id, userInfo.accessToken);
  let successMessage = `Invitation emailed to ${userInfo.firstName} ${
    userInfo.lastName
  } at ${format(
    new Date(),
    "h:mm aaaa 'on' MMMM d, yyyy",
  )}. This user has 10 days to activate their account.`;
  await handleUserEvent(apiInvocation, successMessage);
};

const deactivate = async ({ id, accessToken }, handleUserEvent) => {
  const apiInvocation = async () => await deactivateUser(id, accessToken);
  await handleUserEvent(apiInvocation, '');
};

const activate = async ({ id, accessToken }, handleUserEvent) => {
  const apiInvocation = async () => await activateUser(id, accessToken);
  await handleUserEvent(apiInvocation, 'User has been activated.');
};

const remove = async ({ id, accessToken }, handleUserEvent) => {
  const apiInvocation = async () => await deleteUser(id, accessToken);
  await handleUserEvent(apiInvocation, 'User has been deleted.');
};

const CardHeaderWithTooltip = ({
  className,
  children,
  tooltipText,
  ...otherProps
}) => {
  return (
    <div
      {...otherProps}
      className={`${style.roleAssignmentCardHeader} ${className}`}
    >
      {children}
      <Tooltip title={tooltipText} />
    </div>
  );
};

const ModifyUserForm = props => {
  const {
    userInfo,
    changeStatus,
    onSubmitUserFields,
    onSubmitUserRoles,
    validationSchemaDefinition,
    getCustomUserValidationSchema,
  } = props;

  const [modalProperties, setModalProperties] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const [deleteModalProperties, setDeleteModalProperties] = useState(null);
  const [editUser, setEditUser] = useState(false);
  const [allRowsComplete, setAllRowsComplete] = useState(true);

  let history = useHistory();
  const { lastLogin, status, firstName, lastName, email } = props.userInfo;
  const activity = lastLogin ? FormatISO(lastLogin, DATE_ONLY) : undefined;

  const handleOpenEditUser = () => {
    setEditUser(true);
  };
  const handleCloseEditUser = () => {
    setEditUser(false);
  };

  const EDIT_USER_ROLES_TOOLTIP_TEXT =
    'Roles and permissions are grouped by subscriptions and applications that your company licenses. Subscriptions are containers used to manage and isolate your Healthwise resources in different environments like production or test. If you need further permissions, please reach out to your organization administrator(s). ';

  const RESENT_INVITATION_ERROR_MESSAGE =
    'Error sending invitation to user. Please try again or contact your Healthwise administrator.';

  const handleModalClose = () => {
    setModalOpen(false);
    setDeleteModelOpen(false);
  };

  const allowReset = ['ACTIVE'].includes(userInfo.status);

  const handleUserEvent = async (asyncApiInvocation, successMessage) => {
    const { error } = await asyncApiInvocation();

    const [message, success] = error
      ? [RESENT_INVITATION_ERROR_MESSAGE, false]
      : [successMessage, true];

    success ? setSuccess(message) : setError(message);

    handleModalClose();
  };

  const redirectToTarget = () => {
    setTimeout(() => {
      history.push(USER_MANAGEMENT_PATH);
    }, 1000);
  };

  const configureConfirmationModal = (action, userInfo) => {
    let modalProperties;

    switch (action) {
      case 'resendInvitation':
        modalProperties = {
          title: 'Resend invitation to this user?',
          text: ` will be emailed account set up instructions.`,
          confirmText: 'Resend Invitation',
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          handleConfirm: async () =>
            await resendInvitation(userInfo, handleUserEvent),
        };
        break;

      case 'deactivateUser':
        modalProperties = {
          title: 'Deactivate this user?',
          text: ` will lose the ability to access all applications.`,
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          confirmText: 'Deactivate User',
          color: 'red',
          handleConfirm: async () => {
            await deactivate(userInfo, handleUserEvent);
            changeStatus();
          },
        };
        break;

      case 'activateUser':
        modalProperties = {
          title: 'Activate this user?',
          personFullName: `${userInfo.firstName} ${userInfo.lastName}`,
          email: ` at ${userInfo.email}`,
          text: ` will be able to access any applications or permissions assigned to them.`,
          confirmText: 'Activate user',
          handleConfirm: async () => {
            await activate(userInfo, handleUserEvent);
            changeStatus();
          },
        };
        break;

      case 'deleteUser':
        modalProperties = getDeleteUserProperties(userInfo, async () => {
          await remove(userInfo, handleUserEvent);
          redirectToTarget();
        });
        setDeleteModalProperties(modalProperties);
        setDeleteModelOpen(true);
        return;

      default:
        modalProperties = {
          title: 'Default Title',
          text: 'Default Text',
          confirmText: 'Default Confirm',
          handleConfirm: () => null,
        };
        break;
    }
    setModalProperties(modalProperties);
    setModalOpen(true);
  };

  const nameValidationSchema = getCustomUserValidationSchema({
    firstName: validationSchemaDefinition.firstName,
    lastName: validationSchemaDefinition.lastName,
  });

  const roleValidationSchema = getCustomUserValidationSchema({
    selectedRoles: validationSchemaDefinition.selectedRoles,
  });

  return (
    <main>
      {error ? <Notification error message={error} duration={8000} /> : null}
      {success ? <Notification message={success} /> : null}
      {modalOpen ? (
        <ConfirmationModal
          open={modalOpen}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          {...modalProperties}
        />
      ) : null}
      {deleteModelOpen ? (
        <VerifyModal
          open={deleteModelOpen}
          handleClose={handleModalClose}
          handleCancel={handleModalClose}
          {...deleteModalProperties}
        />
      ) : null}
      <BackToPrevPageLink
        text="Back to User Management"
        path={USER_MANAGEMENT_PATH}
      />
      <UserCardv2 className={style.userDetailContainer}>
        <Formik
          enableReinitialize
          initialValues={{
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
          }}
          validationSchema={nameValidationSchema}
          onSubmit={async (values, actions) => {
            const success = await onSubmitUserFields(values, actions);
            if (success) {
              handleCloseEditUser();
            }
          }}
          children={formikProps => (
            <>
              <UserCardContentv2 className={style.userDetail}>
                {!editUser ? (
                  <span className={style.userName}>
                    {`${firstName} ${lastName}`}
                    <EditIconButton
                      className={style.editButton}
                      onClick={handleOpenEditUser}
                    />
                  </span>
                ) : (
                  <UserCardContentv2 className={style.editUserContainer}>
                    <UserDetails {...userInfo} />
                    <div className={style.editUserBtnContainer}>
                      <GreenFormSubmitButton
                        buttonText="Save"
                        {...props}
                        {...formikProps}
                      />
                      <CancelButton
                        buttonText={`Cancel`}
                        handleClick={handleCloseEditUser}
                        className={style.cancelButtonSmall}
                      />
                    </div>
                  </UserCardContentv2>
                )}
                <div className={style.loginActivity}>
                  <StatusChip status={status} />
                  {activity ? <span>Last Login: {activity}</span> : null}
                </div>
                <span className={style.userEmail}>{`Email: ${email}`}</span>
                {allowReset ? (
                  <ResetPasswordWorkflow
                    forOrgAdmin={false}
                    userInfo={userInfo}
                  ></ResetPasswordWorkflow>
                ) : null}
              </UserCardContentv2>
              <UserCardContentv2 className={style.userDetailActions}>
                <UserActionMenu
                  {...props}
                  {...formikProps}
                  handleModalAction={configureConfirmationModal}
                />
              </UserCardContentv2>
            </>
          )}
        />
      </UserCardv2>

      <UserCardv2 variant="outlined" className={style.roleAssignment}>
        <UserCardHeaderv2
          title="User Roles and Permissions"
          tooltipText={EDIT_USER_ROLES_TOOLTIP_TEXT}
          component={CardHeaderWithTooltip}
        />
        <UserCardContentv2>
          <Formik
            enableReinitialize
            initialValues={{
              selectedRoles: userInfo.selectedRoles,
              organizationId: userInfo.organizationId,
            }}
            validationSchema={roleValidationSchema}
            onSubmit={onSubmitUserRoles}
            children={formikProps => (
              <>
                <RoleAssignment
                  tooltipText={EDIT_USER_ROLES_TOOLTIP_TEXT}
                  setAllRowsComplete={setAllRowsComplete}
                  {...props}
                  {...formikProps}
                />
                {formikProps.dirty || !allRowsComplete ? (
                  <>
                    {!allRowsComplete ? <HorizontalRule /> : null}
                    <div className={style.submitFormButtonContainer}>
                      {formikProps.isSubmitting ? (
                        <ProgressIndicator />
                      ) : (
                        <GreenFormSubmitButton
                          buttonText="Save Changes"
                          {...formikProps}
                          isValid={formikProps.isValid && allRowsComplete}
                        />
                      )}
                    </div>
                  </>
                ) : null}
              </>
            )}
          />
        </UserCardContentv2>
      </UserCardv2>

      {allowReset ? (
        <UserCardv2>
          <UserCardHeaderv2 title={'Emergency Password Reset'} />
          <UserCardContentv2>
            <ForceResetPasswordWorkflow
              forOrgAdmin={false}
              userInfo={userInfo}
            ></ForceResetPasswordWorkflow>
          </UserCardContentv2>
        </UserCardv2>
      ) : null}
    </main>
  );
};
export default ModifyUserForm;
