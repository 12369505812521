import React from 'react';
import {
  Button as MaterialButton,
  Link,
  IconButton,
  SvgIcon,
  Radio,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  CancelIcon,
  CloseIcon,
  DoneIcon,
  TrashCanIcon,
  ChevronRightIcon,
} from 'components/icons/icons';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ExpandMore, Edit } from '@mui/icons-material';
import { DEFAULT_BTN_COLOR_CONFIRM_GREEN } from 'utils/dictionary/overview';
import { styled } from '@mui/material/styles';
import style from './button.module.scss';

export const CancelIconButton = props => {
  return (
    <GenericIconButton aria-label="cancel" {...props}>
      <CancelIcon />
    </GenericIconButton>
  );
};

export const CloseIconButton = props => {
  return (
    <GenericIconButton aria-label="close" {...props}>
      <CloseIcon />
    </GenericIconButton>
  );
};

export const CancelButton = ({ buttonText, handleClick, className }) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.buttonCancel} ${style.button} ${className}`}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const DoneButton = props => {
  return (
    <GenericIconButton {...props}>
      <DoneIcon className={props.className} />
    </GenericIconButton>
  );
};

export const TrashButton = props => {
  return (
    <GenericIconButton aria-label="Trash" {...props}>
      <TrashCanIcon />
    </GenericIconButton>
  );
};

export const ExpandButton = props => {
  return (
    <GenericIconButton
      className={style.expandButton}
      aria-label={props.ariaLabel}
      {...props}
    >
      {props.expand ? <ExpandMore /> : <ChevronRightIcon />}
    </GenericIconButton>
  );
};

export const EditIconButton = props => {
  return (
    <GenericIconButton aria-label="Edit" {...props}>
      <Edit />
    </GenericIconButton>
  );
};

// TODO: SvgIcon is redundant and should be removed. widths would need to be applied to the HW svgs
const GenericIconButton = ({ children, ...props }) => {
  return (
    <IconButton color="primary" {...props} size="large">
      <SvgIcon>{children}</SvgIcon>
    </IconButton>
  );
};

export const GeneralCancelButton = ({ handleClick, ...props }) => {
  return (
    <MaterialButton
      className={`${style.button} ${style.generalCancelButton}`}
      variant="outlined"
      onClick={handleClick}
      {...props}
    >
      Cancel
    </MaterialButton>
  );
};

export const ConfirmationModalButton = ({
  handleClick,
  buttonText,
  backgroundColor,
  className,
  ...props
}) => {
  let bgColor =
    backgroundColor === DEFAULT_BTN_COLOR_CONFIRM_GREEN
      ? style.backgroundGreen
      : style.backgroundRed;
  return (
    <MaterialButton
      className={`${style.button} ${style.confirmationModalButton} ${bgColor} ${className}`}
      variant="outlined"
      onClick={handleClick}
      {...props}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const GreenGeneralButton = ({
  buttonText,
  handleClick,
  className,
  ...props
}) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.greenButton} ${style.button} ${className}`}
      {...props}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const GeneralIconButton = ({
  buttonText,
  icon,
  className,
  handleClick,
}) => {
  return (
    <MaterialButton
      className={`${style.button} ${className}`}
      variant="contained"
      startIcon={icon}
      onClick={handleClick}
    >
      {buttonText}
    </MaterialButton>
  );
};

// Looks like 'GreenButtonLink' doesn't quite support variant="outlined" yet.
export const GreenButtonLink = ({ children, ...props }) => {
  return (
    <MaterialButton
      className={`${style.button} ${style.greenButton}`}
      component={RouterLink}
      onClick={props?.handleClick}
      {...props}
    >
      {children}
    </MaterialButton>
  );
};

export const GreenOutlinedButtonLink = ({ buttonText, handleClick }) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.button} ${style.outlineButtonGreen}`}
      variant="outlined"
    >
      {buttonText}
    </MaterialButton>
  );
};

export const RedOutlinedButtonLink = ({ buttonText, handleClick }) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.button} ${style.outlineButtonRed}`}
      variant="outlined"
    >
      {buttonText}
    </MaterialButton>
  );
};

export const RedButton = ({ buttonText, handleClick, className, ...props }) => {
  return (
    <MaterialButton
      onClick={handleClick}
      className={`${style.button} ${style.buttonRed} ${className}`}
      {...props}
    >
      {buttonText}
    </MaterialButton>
  );
};

export const GreenFormSubmitButton = props => {
  const { isValid, dirty, values, actions, handleSubmit, buttonText } = props;

  return (
    <>
      <MaterialButton
        className={`${style.button} ${style.greenButton}`}
        type="submit"
        variant="contained"
        onClick={() => handleSubmit(values, actions)}
        disabled={!(isValid && dirty)}
      >
        {buttonText}
      </MaterialButton>
    </>
  );
};

export const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: '#0d8484',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

export const GreenToLink = props => {
  const { to, text, hasUnderline } = props;
  return (
    <span>
      <Link
        className={style.greenLink}
        component={RouterLink}
        to={to}
        underline={hasUnderline ? 'always' : 'none'}
      >
        {text}
      </Link>
    </span>
  );
};

export const RoundedToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: '10px',
      border: 'none',
      '&:not(:first-of-type)': {
        border: '1px solid #595959',
        borderRadius: '16px',
      },
      '&:first-of-type': {
        border: '1px solid #595959',
        borderRadius: '16px',
      },
    },
  }),
);
