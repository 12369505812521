import React, { useState, useContext, useEffect } from 'react';
import { Form, useField } from 'formik';

import PropTypes from 'prop-types';
import { BaselineTextField } from 'components/textInputV2/textInput';
import {
  GeneralCancelButton,
  GreenGeneralButton,
  EditIconButton,
} from 'components/buttons/button';

import { NotificationContext } from 'contexts/notificationContext';
import style from './inlineEditNotes.module.scss';

const NoteInput = props => {
  const [field, meta] = useField(props);
  const { isedit } = props;

  const displayText = field.value !== '' ? field.value : 'Add note (Optional)';
  return (
    <>
      {isedit ? (
        <BaselineTextField
          {...field}
          className={style.noteInput}
          margin="none"
          size="small"
          {...props}
          error={meta.touched && meta.error ? true : false}
          multiline
          rows={2}
        />
      ) : (
        <div className={style.textDisplay}>{displayText}</div>
      )}
    </>
  );
};

const InlineEditNotes = props => {
  const { status, isSubmitting } = props;
  const { success, message = '', messageKey } = status ?? {};
  const [isEdit, setIsEdit] = useState(false);
  const notificationContext = useContext(NotificationContext);

  useEffect(() => {
    if (message) {
      notificationContext.showNotification(message, !success, 5000, messageKey);
      status.message = null;
      if (success) {
        setIsEdit(!isEdit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, message, messageKey]);

  const handleReset = resetForm => {
    resetForm();
    setIsEdit(!isEdit);
  };

  return (
    <div className={style.container}>
      <Form className={style.form}>
        <NoteInput
          id="subscriptionNote"
          name="subscriptionNote"
          isedit={+isEdit}
        />
        {isEdit ? (
          <div className={style.buttonContainer}>
            <GreenGeneralButton
              aria-label="save note"
              type="submit"
              buttonText="Save Note"
              handleClick={null}
              className={style.saveButton}
              disabled={!props.dirty}
            ></GreenGeneralButton>
            <GeneralCancelButton
              aria-label="cancel"
              handleClick={handleReset.bind(null, props.resetForm)}
              disabled={isSubmitting}
            ></GeneralCancelButton>
          </div>
        ) : (
          <EditIconButton
            aria-label="edit"
            onClick={() => setIsEdit(!isEdit)}
            className={style.edit}
          ></EditIconButton>
        )}
      </Form>
    </div>
  );
};

NoteInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
};

export default InlineEditNotes;
