import React from 'react';
import { Grid } from '@mui/material';

import { BaselineTextInput } from 'components/textInputV2/textInput';

import style from './userDetails.module.scss';

/**
 * The user fields provide a common arrangement of the requested user details
 * with some logic baked in. UserFields is just the raw arrangement of the
 * fields without styling or containering.
 */
export const UserFields = props => {
  const textProps = {
    className: style.userField,
    size: 'medium',
    variant: 'standard',
  };
  const { errors } = props;

  return (
    <div>
      <Grid container className={style.fieldRow}>
        <Grid item xs>
          <BaselineTextInput
            id="firstName"
            externalLabel="First Name"
            errorlabel="First Name"
            name="firstName"
            {...textProps}
          />
        </Grid>
        <Grid item xs>
          <BaselineTextInput
            id="lastName"
            externalLabel="Last Name"
            errorlabel="Last Name"
            name="lastName"
            {...textProps}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={`${style.fieldRow} ${
          errors?.firstName || errors?.lastName ? style.addMargin : null
        }`}
      >
        <Grid item xs>
          <BaselineTextInput
            id="email"
            externalLabel="Email Address"
            errorlabel="Email Address"
            name="email"
            {...textProps}
          />
        </Grid>
      </Grid>
    </div>
  );
};

/**
 * UserDetails provides the common arrangement of the requested user details
 *  wrapped in a card for ease of use and styling.
 */
export const UserDetails = props => {
  return (
    <>
      <p className={style.cardHeadline}>1. User Details</p>
      {props.creating ? (
        <p className={style.cardDescription}>
          In order to create a new user, you need to provide their email address
          and assign user roles. The user will receive an invitation to log in.
        </p>
      ) : null}
      <UserFields {...props} />
    </>
  );
};

export default UserDetails;
