import MaterialTable from '@material-table/core';
import {
  getRole,
  createRole,
  updateRole,
  deleteRole,
  getRoles,
} from 'utils/api/roles';
import { AuthContext } from 'contexts/authContext';
import React, { useContext } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';

const EditApplicationRoles = ({
  applicationId,
  rolesList,
  setRolesMethod,
  setStatus,
}) => {
  const authContext = useContext(AuthContext);

  function handleDeleteRoleClick(rowData) {
    if (rowData.roleId !== undefined) {
      getRole(applicationId, rowData.roleId, authContext.accessToken).then(
        (response, data, etag, error) => {
          if (response.error || response.response.status !== 200) {
            setStatus({
              success: false,
              message: 'Failed to load Role',
            });
          }

          deleteRole(
            applicationId,
            rowData.roleId,
            response.etag,
            authContext.accessToken,
          ).then((response, data, error) => {
            if (response.error || response.response.status !== 200) {
              setStatus({
                success: false,
                message: 'Failed to delete Role',
              });
            } else {
              setStatus({
                success: true,
                message: 'Role deleted successfully',
              });
              getApplicationRoles();
            }
          });
        },
      );
    }
  }

  function handleEditRoleClick(rowData) {
    getRole(applicationId, rowData.roleId, authContext.accessToken).then(
      (response, data, etag, error) => {
        if (response.error || response.response.status !== 200) {
          setStatus({
            success: false,
            message: 'Failed to load roles',
          });
        }

        updateRole(
          applicationId,
          rowData.roleId,
          { description: rowData.description },
          response.etag,
          authContext.accessToken,
        ).then((response, data, error) => {
          if (response.error || response.response.status !== 200) {
            setStatus({
              success: false,
              message: 'Failed to update Role',
            });
          } else {
            setStatus({
              success: true,
              message: 'Role updated successfully',
            });
            getApplicationRoles();
          }
        });
      },
    );
  }

  function handleNewRoleClick(newData) {
    let roleToAdd = {
      name: newData.name,
      description: newData.description,
    };
    createRole(applicationId, roleToAdd, authContext.accessToken).then(
      (response, data, error) => {
        if (response.error || response.response.status !== 201) {
          setStatus({
            success: false,
            message: 'Failed to create Role',
          });
        } else {
          setStatus({
            success: true,
            message: 'Role created successfully',
          });
          getApplicationRoles();
        }
      },
    );
  }

  function getApplicationRoles() {
    getRoles(applicationId, authContext.accessToken).then(
      (response, data, error) => {
        if (response.error || response.response.status !== 200) {
          setStatus({
            success: false,
            message: 'Failed to load role',
          });
        }
        setRolesMethod(response.data.data);
      },
    );
  }

  const theme = createTheme({
    overrides: {
      MuiTypography: {
        h6: {
          fontSize: '1.25em',
          color: '#424242',
          fontFamily:
            '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen, Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue, sans-serif',
          fontWeight: '400',
        },
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <MaterialTable
          theme={theme}
          columns={[
            { title: 'id', field: 'roleId', hidden: true },
            { title: 'roleId', field: 'roleId', hidden: true },
            { title: 'Name', field: 'name', editable: 'onAdd' },
            {
              title: 'Description',
              field: 'description',
              editalbe: true,
            },
          ]}
          data={rolesList ?? []}
          title="Roles"
          options={{
            sorting: true,
            paging: true,
            search: false,
            pageSize: 10,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 50],
          }}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  setRolesMethod([...rolesList, newData]);
                  handleNewRoleClick(newData);

                  resolve();
                }, 1000);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...rolesList];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setRolesMethod([...dataUpdate]);
                  handleEditRoleClick(newData);

                  resolve();
                }, 1000);
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...rolesList];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setRolesMethod([...dataDelete]);
                  handleDeleteRoleClick(oldData);

                  resolve();
                }, 1000);
              }),
          }}
        />
      </ThemeProvider>
    </>
  );
};

export default EditApplicationRoles;
