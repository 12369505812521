import React from 'react';
import style from './footer.module.scss';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <>
      <div className={style.footer}>
        <span className={style.copyright}>
          ©2018-{new Date().getFullYear()} Healthwise, Incorporated. Healthwise,
          Healthwise for every health decision, and the Healthwise logo are
          trademarks of Healthwise, Incorporated. Learn more about our{' '}
          <Link
            to={{
              pathname:
                'https://www.healthwise.org/specialpages/legal/privacy.aspx',
            }}
            target="_blank"
            className={style.terms}
          >
            Privacy Policy
          </Link>
        </span>
      </div>
    </>
  );
};

export default Footer;
