import React, { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { AuthContext } from 'contexts/authContext';
import { OrganizationAdministratorPanel } from 'components/organizationManagement/organizationAdministratorPanel/organizationAdministratorPanel';
import { SubscriptionPanel } from 'components/organizationManagement/subscriptionPanel/subscriptionPanel';
import { PageHeader } from 'components/pageHeader/pageHeader';
import OrganizationSubscriptionDetailPanel from 'containers/organizationManagement/editOrganization/organizationSubscriptionDetailPanel/organizationSubscriptionDetailPanel';
import style from './organizationOverview.module.scss';

import {
  useGetOrganization,
  useRetrieveEntitlementPacket,
  useGetOrganizationAdministrators,
  useGetSubscriptionTypes,
} from 'utils/hooks/useToCallApiEndpoints';

const setCompleteOrganizationState = (
  organization,
  organizationToEditLoading,
) => ({
  organization,
  organizationToEditLoading,
});

// TODO: Still using the JWT to parse the orgId out of it. Update it to pull
//    the user information from the backend
const OrganizationOverview = props => {
  /*         ┗┃・ U ・┃            */
  // Note: flipping readOnly to false will require more state to be passed into the
  // various subcomponents
  const readOnly = true;

  const [completeOrganization, setCompleteOrganization] = useState(
    setCompleteOrganizationState({}, true),
  );

  const { organizationId } = useContext(AuthContext);

  const { data: administrators, isLoading: organizationAdministratorsLoading } =
    useGetOrganizationAdministrators(organizationId);

  // TODO: Display error to user
  const { data: organization, isLoading: organizationLoading } =
    useGetOrganization('current');

  //TODO: Display error to user
  const { data: subscriptionTypes, isLoading: subscriptionTypesLoading } =
    useGetSubscriptionTypes();

  // Inject the subscription type name into the organization object so we only have one state object
  useEffect(() => {
    const setupCompleteOrganization = async () => {
      var subscriptions = organization?.data?.subscriptions;
      subscriptions?.forEach(subscription => {
        var particularSubscriptionType = subscriptionTypes.find(
          subscriptionType =>
            subscriptionType.subscriptionTypeId ===
            subscription.subscriptionTypeId,
        );
        if (particularSubscriptionType) {
          subscription['subscriptionType'] = particularSubscriptionType['name'];
        } else {
          // Just in case particularSubscriptionType isn't found
          subscription['subscriptionType'] = '';
        }
      });
      setCompleteOrganization(
        setCompleteOrganizationState(organization.data, false),
      );
    };

    if (!organizationLoading && !subscriptionTypesLoading) {
      setupCompleteOrganization();
    }

    // The organization object is being modified, so that causes an infinite loop. Adding
    // the subscriptionType to the return organization on the service side will solve this problem

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationLoading, subscriptionTypesLoading]);

  // Only needed for the # of prop subscriptions available
  const { data: entitlementPacket } = useRetrieveEntitlementPacket('current');
  const productionSubscriptionsLimit =
    entitlementPacket?.subscriptionsLimits?.production ?? 0;

  const renderSubscriptionDetailPanel = props => {
    return <OrganizationSubscriptionDetailPanel current={true} {...props} />;
  };

  return (
    <Box className={style.container}>
      <PageHeader title="Organization Overview" />
      <OrganizationAdministratorPanel
        administratorsData={{
          administrators,
          organizationAdministratorsLoading,
        }}
        readOnly={readOnly}
      />
      <div id="sub" className={style.spacer}></div>
      <SubscriptionPanel
        readOnly={readOnly}
        organization={completeOrganization}
        productionSubscriptionsLimit={productionSubscriptionsLimit}
        renderSubscriptionDetailPanel={renderSubscriptionDetailPanel}
      />
    </Box>
  );
};

export default OrganizationOverview;
