import React from 'react';
import SubscriptionDetailPanel from 'components/organizationManagement/organizationManagementEditOrganization/subscriptionDetailPanel/subscriptionDetailPanel';
import SubscriptionInlineEditNotes from 'containers/organizationManagement/editOrganization/subscriptionInlineEditNotes/subscriptionInlineEditNotes';
import { useGetSubscription } from 'utils/hooks/useToCallApiEndpoints';

import EditSubscriptionConfigureProducts from 'containers/organizationManagement/editSubscriptionConfigureProducts/editSubscriptionConfigureProducts';
import { useRetrieveSubscriptionEntitlementPacket } from 'utils/hooks/useToCallApiEndpoints';

const OrganizationSubscriptionDetailPanel = props => {
  const { readOnly = false, rowData, organization, current = false } = props;

  const organizationId = current
    ? 'current'
    : organization?.organizationId ?? '';
  const { subscriptionId } = rowData ?? {};

  const { data /*, errorMessage */ } = useRetrieveSubscriptionEntitlementPacket(
    organizationId,
    subscriptionId,
  );

  //endpoint doesn't exist for 'current' at the time
  const { etag: subscriptionEtag, data: subscriptionData } = useGetSubscription(
    organization?.organizationId,
    subscriptionId,
  );

  return (
    <SubscriptionDetailPanel
      readOnly={readOnly}
      entitlementPacketData={data ?? {}}
      rowData={rowData}
      organizationId={organizationId}
      renderSubscriptionInlineEditNotes={props => {
        return (
          <SubscriptionInlineEditNotes
            organizationToEdit={organization}
            subscriptionEtag={subscriptionEtag}
            subscriptionData={subscriptionData}
            {...props}
          />
        );
      }}
      renderSubscriptionEditConfigureProducts={props => {
        return (
          <EditSubscriptionConfigureProducts
            organizationToEdit={organization}
            entitlementPacketData={data ?? {}}
            {...props}
          />
        );
      }}
    />
  );
};

export default OrganizationSubscriptionDetailPanel;
