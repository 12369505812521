import React from 'react';
import { Chip, Autocomplete } from '@mui/material';
import { Field } from 'formik';
import { BaselineTextField } from 'components/textInputV2/textInput';
import { CloseIcon } from 'components/icons/icons';

// TODO Rename 'selectedValue' to pluralize it.
export const MultipleSelectInput = props => {
  const {
    name,
    optionsList,
    touched,
    onChange,
    selectedValue,
    onBlur,
    error,
    errorMsg,
    label,
    helpMsg,
    disabled = false,
    fieldHelperText = () => {
      return <></>;
    },
    filterSelectedOptions = true,
    fullWidth = true,
    disableClearable = false,
    disableCloseOnSelect = false,
    size = 'small',
  } = props;

  const handleChange = (event, value, eventName) => {
    onChange(name, value);
  };

  const handleBlur = () => {
    onBlur(name, true);
  };

  return (
    <Field
      name={name}
      value={selectedValue}
      component={Autocomplete}
      multiple
      fullWidth={fullWidth}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      options={optionsList}
      getOptionLabel={option => option.value}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value?.id;
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      size={size}
      filterSelectedOptions={filterSelectedOptions}
      helpertext={fieldHelperText}
      disabled={disabled}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            label={option.value}
            {...getTagProps({ index })}
            deleteIcon={<CloseIcon fontSize="small" />}
          />
        ));
      }}
      renderInput={params => {
        return (
          <BaselineTextField
            {...params}
            label={label}
            fullWidth
            helperText={touched && !!error ? errorMsg : helpMsg}
            error={!!errorMsg && touched}
            onBlur={handleBlur}
          />
        );
      }}
    />
  );
};

// TODO: Convert to use MultipleSelectInput instead.
//        Note: The way this input is used, value is required to be that name.
export const OrganizationManagementMultipleSelectInput = props => {
  const {
    handleChange,
    handleBlur,
    name,
    options,
    touched,
    errorMsg,
    label,
    defaultValue,
    disabled = false,
    wrapperObject,
    getOptionSelected,
    value,
    key,
    filterSelectedOptions = true,
  } = props;

  const wrapChange = (event, value) => {
    if (wrapperObject) {
      let obj = null;
      if (value?.length >= 1) {
        obj = wrapperObject(value);
      }
      handleChange(name, obj);
    } else {
      handleChange(name, value);
    }
  };

  const wrapBlur = () => {
    handleBlur(name, true);
  };

  return (
    options.length > 0 && (
      <>
        <Autocomplete
          key={key}
          multiple
          id={name}
          size="small"
          disabled={disabled}
          options={options ?? []}
          getOptionLabel={option => option.name}
          defaultValue={defaultValue ?? []}
          disableCloseOnSelect
          onChange={wrapChange}
          isOptionEqualToValue={getOptionSelected}
          filterSelectedOptions={filterSelectedOptions}
          value={value}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
                deleteIcon={<CloseIcon fontSize="small" />}
              />
            ))
          }
          renderInput={params => (
            <BaselineTextField
              {...params}
              label={label}
              fullWidth
              helperText={touched && !!errorMsg ? errorMsg : null}
              error={!!errorMsg && touched ? true : false}
              onBlur={wrapBlur}
            />
          )}
        />
      </>
    )
  );
};

export const ApplicationRoleSelectorSelectInput = props => {
  const {
    selectName,
    optionsList,
    errors,
    touched,
    value = [],
    subscriptionId,
    applicationId,
    handleRoleChanged,
    variant = 'filled',
    label,
    placeholder,
    externalLabel,
    disabled,
    disableClearable,
  } = props;

  // TODO: It's complaining about the lack of a helperText on the Textfield...
  // ODD: Can't pass in an onChange handler here...
  return (
    <Field
      disabled={disabled}
      name={selectName}
      value={value}
      component={Autocomplete}
      options={optionsList}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onChange={(event, value) => {
        handleRoleChanged(value, subscriptionId, applicationId);
      }}
      multiple
      size="small"
      disableCloseOnSelect
      placement="bottom"
      disableClearable={disableClearable}
      filterSelectedOptions={true}
      helpertext={<></>}
      renderInput={params => {
        return (
          <BaselineTextField
            {...params}
            disabled={disabled}
            name={'rolespicker'}
            error={touched['rolespicker'] && !!errors['rolespicker']}
            helperText={touched['rolespicker'] && errors['rolespicker']}
            label={label}
            placeholder={placeholder}
            externalLabel={externalLabel}
            variant={variant}
          />
        );
      }}
    />
  );
};
