import React, { useContext, useRef, useState } from 'react';
import { PageHeader } from 'components/pageHeader/pageHeader';
import { AuthContext } from 'contexts/authContext';
import MaterialTable from '@material-table/core';
import Notification from 'components/notificationV2/notification';
import { getApplications } from 'utils/api/applications';
import { useHistory } from 'react-router-dom';

import { EDIT_APPLICATION_PATH } from 'utils/configuration/links';

function transformResult(apps) {
  return apps?.map((value, index) => {
    return {
      applicationId: value.applicationId,
      sourceId: value.sourceId,
      clientId: value.clientId,
      oktaId: value.oktaId,
      route: value.route,
      versionNumber: value.versionNumber,
      name: value.name,
      description: value.description, //results do not have descriptions
      icon: value.icon,
      fullyQualifiedName: value.fullyQualifiedName,
      owner: value.owner,
      ownerEmail: value.ownerEmail,
      availableFrom: value.availableFrom,
      active: value.active,
      available: value.available,
      redirectUrls: value.redirectUrls,
      roles: value.roles,
    };
  });
}

const ApplicationManagement = () => {
  const authContext = useContext(AuthContext);
  const tableRef = useRef();
  const history = useHistory();

  const [error, setError] = useState(null);

  let columnDefs = [
    { title: 'id', field: 'applicationId', hidden: true },
    { title: 'Name', field: 'name' },
    { title: 'Description', field: 'description' },
    { title: 'Owner', field: 'owner' },
    { title: 'Active', field: 'active' },
    { title: 'Available', field: 'available' },
  ];

  async function populateTable(query) {
    const top = query.pageSize;
    let skip = query.page * query.pageSize;

    const { response, pagination, data, error } = await getApplications(
      skip,
      top,
      authContext.accessToken,
    );

    if (error || response.status !== 200) {
      setError(error || response.error);
      throw new Error('Failed to load applications');
    }

    const users = transformResult(data);
    setError(null);

    return {
      data: users,
      page: pagination.page - 1,
      totalCount: pagination?.available,
    };
  }

  const tableProps = {
    columns: columnDefs,
    data: populateTable,
    options: {
      actionsColumnIndex: -1,
      pageSize: 10,
      emptyRowsWhenPaging: false,
      pageSizeOptions: [10, 20, 50, 100],
      debounceInterval: 600,
      search: false,
      sorting: false,
    },
    isLoading: authContext.loading,
    title: '',
    IconComponent: 'arrow_drop_down',
    actions: [],
  };

  const handleViewApplicationClick = props => {
    history.push(`${EDIT_APPLICATION_PATH}?appId=${props.applicationId}`, {
      applicationId: props.applicationId,
    });
  };

  return (
    <>
      <PageHeader
        title="Application Management"
        secondary={'Update existing applications and manage roles'}
      />
      {error ? (
        <Notification error={error} message={error.message} duration={8000} />
      ) : null}

      {authContext.accessToken ? (
        <MaterialTable
          style={{ boxShadow: '0px 3px 6px #00000029' }}
          tableRef={tableRef}
          {...tableProps}
          actions={[
            {
              icon: 'edit',
              tooltip: 'View Details',
              onClick: (event, rowData) => handleViewApplicationClick(rowData),
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default ApplicationManagement;
