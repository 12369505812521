import React, { Fragment } from 'react';

import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import style from './subscriptionDetailPanel.module.scss';

const GetModulesNames = packages => {
  return packages.reduce((modulesNamesObj, packageItem) => {
    const { modules } = packageItem;
    modules.forEach(moduleItem => {
      const { id, name } = moduleItem;
      modulesNamesObj[id] = name;
    });
    return modulesNamesObj;
  }, {});
};

const GetConceptNames = assets => {
  return (
    Object.entries(assets)?.reduce((assetsObj, asset) => {
      const [key, value] = asset;
      const concepts = value?.add
        ?.flatMap(item => item?.concepts)
        ?.filter(concept => concept);
      assetsObj[key] = concepts?.reduce((conceptsObj, { conceptId, name }) => {
        conceptsObj[conceptId] = name;
        return conceptsObj;
      }, {});

      return assetsObj;
    }, {}) ?? {}
  );
};

const GetListOfContentNames = (licenseAssets, conceptNames) => {
  return Object.entries(licenseAssets)
    ?.flatMap(item => {
      const [key, value] = item;
      return value?.add
        ?.flatMap(item => item?.concepts)
        ?.filter(concept => concept)
        ?.map(({ conceptId }) => conceptNames[key]?.[conceptId]);
    })
    ?.filter(name => name);
};

const GetModulesAndApplications = (
  title,
  applications = [],
  name,
  optional,
) => {
  return {
    title,
    applications: [
      ...applications,
      {
        application: name,
        optionalFeatures: !Array.isArray(optional)
          ? optional
          : optional?.length === 0
          ? 'None selected'
          : optional.map(item => item.name).join(', '),
      },
    ],
  };
};

const GetConfigureProductAndContents = (
  entitlementPacket,
  subscriptionLicense,
) => {
  const noneSelected = 'None selected';

  const { licensing = {} } = entitlementPacket ?? {};
  const { assets: entitlementAssets, packages = [] } = licensing;
  const { licenseKey = {} } = subscriptionLicense ?? {};
  const { payload = {} } = licenseKey;
  const { applications: licenseApplications, assets: licenseAssets } = payload;

  const modulesNames = GetModulesNames(packages);

  const conceptNames = GetConceptNames(entitlementAssets);

  const listOfContentNames = GetListOfContentNames(licenseAssets, conceptNames);

  const modulesAndApplications = {};

  licenseApplications.forEach((app, index) => {
    const {
      features: { optional },
      name,
      modules,
    } = app;

    modules.forEach(moduleItem => {
      const { id } = moduleItem;

      if (!modulesAndApplications[id]) {
        modulesAndApplications[id] = GetModulesAndApplications(
          modulesNames[id],
          [],
          name,
          optional,
        );
      } else {
        const { title, applications } = modulesAndApplications[id];

        modulesAndApplications[id] = GetModulesAndApplications(
          title,
          applications,
          name,
          optional,
        );
      }
    });
  });

  let content =
    listOfContentNames.length >= 1
      ? listOfContentNames.join(', ')
      : noneSelected;

  return {
    contentData: [{ content }],
    applicationsData: Object.values(modulesAndApplications),
  };
};

const SubscriptionDetailPanel = props => {
  const {
    renderSubscriptionInlineEditNotes,
    renderSubscriptionEditConfigureProducts,
    entitlementPacketData,
    rowData,
    organizationId,
    readOnly = false,
  } = props;

  const { entitlementPacket, subscriptionLicense } =
    entitlementPacketData ?? {};

  const NoProductsConfigured = props => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={8}>
          <div className={style.noProduct}>
            No products configured.
            {!readOnly && ' Select Modify Subscription to add products.'}
          </div>
        </Grid>
        <Grid item xs={4}>
          {!readOnly && (
            <div style={{ float: 'right' }}>
              {renderSubscriptionEditConfigureProducts({
                rowData,
                organizationId,
              })}
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  const ProductsAndContentsList = props => {
    const { entitlementPacket, subscriptionLicense } = props;
    const { contentData, applicationsData } = GetConfigureProductAndContents(
      entitlementPacket,
      subscriptionLicense,
    );

    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs container direction="column">
            {applicationsData.map((app, index) => {
              const { title, applications } = app;
              return (
                <Grid
                  item
                  xs
                  container
                  direction="row"
                  key={index}
                  className={style.sectionContainer}
                >
                  <Grid item xs={12}>
                    <div className={style.title}>
                      {title ??
                        (!readOnly && (
                          <span className={style.warning}>
                            This module has been removed from the entitlement
                            packet
                          </span>
                        ))}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={style.colTitle}>Applications</div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={style.colTitle}>Optional Features</div>
                  </Grid>
                  {applications.map((element, index) => {
                    return (
                      <Fragment key={index}>
                        <Grid item xs={6}>
                          <div className={style.data}>
                            {element.application}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className={style.data}>
                            {element.optionalFeatures}
                          </div>
                        </Grid>
                      </Fragment>
                    );
                  })}
                </Grid>
              );
            })}

            <Grid item xs={8}>
              <div className={style.title}>Content</div>
              {contentData.map((element, index) => {
                return (
                  <div className={style.data} key={index}>
                    {element.content}
                  </div>
                );
              })}
            </Grid>
          </Grid>
          {readOnly ? (
            <></>
          ) : (
            <>
              <Grid item>
                {renderSubscriptionEditConfigureProducts({
                  rowData,
                  organizationId,
                })}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  };

  const NoteAndTagSection = () => {
    return (
      <div className={style.notes}>
        <div className={style.title}>Internal Notes</div>
        <div className={style.subTitle}>
          These notes are NOT visible to the client.
        </div>
        {renderSubscriptionInlineEditNotes({
          rowData,
          organizationId,
        })}
      </div>
    );
  };

  return (
    <div className={style.subscriptionContainer}>
      {Object.keys(entitlementPacketData).length ? (
        <>
          {subscriptionLicense ? (
            <ProductsAndContentsList
              entitlementPacket={entitlementPacket}
              subscriptionLicense={subscriptionLicense}
            />
          ) : (
            <NoProductsConfigured />
          )}
          {readOnly ? null : NoteAndTagSection()}
        </>
      ) : (
        <Skeleton variant="rect" height={250} />
      )}
    </div>
  );
};

SubscriptionDetailPanel.propTypes = {
  renderSubscriptionInlineEditNotes: PropTypes.func.isRequired,
  renderSubscriptionEditConfigureProducts: PropTypes.func.isRequired,
  entitlementPacketData: PropTypes.shape({}).isRequired,
  rowData: PropTypes.shape({}).isRequired,
};

export default SubscriptionDetailPanel;
