import { OktaAuth } from '@okta/okta-auth-js';
import {
  REACT_APP_OKTA_CLIENT_ID,
  REACT_APP_OKTA_ISSUER,
  REACT_APP_FRONTDOOR_ISSUER,
} from 'utils/dictionary/env';

const isPkceSupported = OktaAuth.features.isPKCESupported();

export const OktaConfig = {
  clientId: REACT_APP_OKTA_CLIENT_ID,
  issuer: REACT_APP_OKTA_ISSUER,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid', 'profile', 'email', 'frontdoor'],
  pkce: isPkceSupported,
  responseType: isPkceSupported ? ['code'] : ['id_token', 'token'],
  autoRenew: false,
};

export const CallbackConfig = {
  frontDoorUri: REACT_APP_FRONTDOOR_ISSUER || REACT_APP_OKTA_ISSUER,
  oktaIssuerUri: REACT_APP_OKTA_ISSUER,
};
