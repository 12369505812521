import env from '@beam-australia/react-env';

export const REACT_APP_OKTA_CLIENT_ID = env('OKTA_CLIENT_ID');
export const REACT_APP_OKTA_ISSUER = env('OKTA_ISSUER');
export const REACT_APP_FRONTDOOR_ISSUER = env('FRONTDOOR_ISSUER');
export const REACT_APP_API_BASE_URL = env('API_BASE_URL');
export const REACT_APP_HEAP_APP_ID = env('HEAP_APP_ID');
export const REACT_APP_DEV_TOOLS = env('DEV_TOOLS');
export const REACT_APP_INLINE_RUNTIME_CHUNK = env('INLINE_RUNTIME_CHUNK');
export const REACT_APP_IMAGE_INLINE_SIZE_LIMIT = env('IMAGE_INLINE_SIZE_LIMIT');

//Docker file injected environment variables
export const COMMIT_HASH = process.env.REACT_APP_COMMIT_HASH;
export const BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER;
export const BUILD_ID = process.env.REACT_APP_BUILD_ID;
