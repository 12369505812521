import React, { useState, useContext } from 'react';
import { format } from 'date-fns';
import IconModal from 'components/organizationManagement/organizationManagementEditOrganization/iconModal/iconModal';
import { Formik } from 'formik';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { Redirect } from 'react-router-dom';
import { updateOrganization } from 'utils/api/organizations';
import { AuthContext } from 'contexts/authContext';
import { DeleteIcon } from 'components/icons/icons';

const DeleteOrganizationNotes = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const [open, setOpen] = useState(false);
  const { accessToken } = useContext(AuthContext);

  const { organization, noteIndex, organizationEtag, currentNote } = props;
  const { organizationId, notes } = organization;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }
  const deleteOrganizationNotes = async (values, { setStatus }) => {
    const organizationNotes = {
      notes: notes.filter((note, index) => index !== noteIndex),
    };
    const { response } = await updateOrganization(
      organizationId,
      organizationNotes,
      organizationEtag,
      accessToken,
    );

    switch (response?.status) {
      case 200:
        handleClose();
        setStatus({ success: true });
        setRedirect({
          isRedirect: true,
          data: { organizationId },
        });
        break;
      case 412:
        setStatus({
          success: false,
          message:
            'Another user has modified this organization. Please refresh the page and try again.',
        });
        break;
      case 500:
        setStatus({
          success: false,
          message: 'Unable to save modified organization due to server error.',
        });
        break;
      default:
        if (!response) {
          setStatus({ success: false, message: 'Network Error' });
        } else {
          setStatus({
            success: false,
            message: 'Unknown Error. Please refresh the page.',
          });
        }
        break;
    }
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={deleteOrganizationNotes}
      children={props => (
        <IconModal
          {...props}
          disableFeedback
          icon={<DeleteIcon />}
          handleOpen={handleOpen}
          open={open}
          handleClose={handleClose}
          confirmationText="Delete Note"
          title="Delete this note?"
          name={currentNote.updatedBy}
          date={`${format(
            new Date(currentNote.updatedDate),
            " MMM d, yyyy 'at' h:mmaaa",
          )}`}
          handleConfirm={deleteOrganizationNotes}
          noteIndex={noteIndex}
        />
      )}
    />
  );
};

export default DeleteOrganizationNotes;
