import React from 'react';
import { Menu, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';

import { KeyboardArrowDownIcon } from 'components/icons/icons';
import UserInfo from 'components/userInfo/userInfo';
import { theme } from 'utils/styles';

//TODO: height and width needs to not be fixed
const StyledMenu = withStyles({
  paper: {
    border: `1px solid #BCBCBC`,
    minHeight: `200px`,
    minWidth: `250px`,
    boxShadow: `0px 3px 6px #00000029`,
    width: `auto`,
  },
})(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      horizontal: 'center',
      vertical: 'bottom',
    }}
    transformOrigin={{
      horizontal: 'center',
      vertical: 'top',
    }}
    {...props}
  />
));

const UserMenu = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  let userName = user?.firstName?.concat(' ', user?.lastName);
  let userEmail = user.email;
  const handleClick = e => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let userInfo = {
    name: userName,
    email: userEmail,
    title: '',
  };

  return (
    <>
      <IconButton
        aria-controls="userMenu"
        aria-label="my account menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ width: '30px', height: '30px', marginLeft: '5px' }}
      >
        <KeyboardArrowDownIcon style={{ color: theme.colors.white }} />
      </IconButton>
      <StyledMenu
        id="userMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <UserInfo user={userInfo} handleClose={handleClose} />
      </StyledMenu>
    </>
  );
};

export default UserMenu;
