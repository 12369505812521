import React, { useRef, useState, useContext } from 'react';
import SubscriptionSwitchModal from 'components/organizationManagement/organizationManagementEditOrganization/subscriptionSwitchModal/subscriptionSwitchModal';
import { Formik } from 'formik';
import { AuthContext } from 'contexts/authContext';
import { Redirect } from 'react-router-dom';
import { updateSubscription, getSubscription } from 'utils/api/subscriptions';
import {
  SubscriptionSwitchSchema,
  SubscriptionSwitchEmptySchema,
  SubscriptionSwitchInitialValues,
  SubscriptionSwitchEmptyInitialValues,
} from 'components/organizationManagement/organizationManagementEditOrganization/subscriptionSwitchModal/formSchemaAndInitialValues';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';

const SubscriptionSwitch = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false });
  const { accessToken } = useContext(AuthContext);
  const { rowData, organization, closeMenu } = props;
  const closeModal = useRef({ close: false }); //Need to think about better way to handle this, this is the best I can think of at this time
  const { active, subscriptionId, subscriptionName } = rowData ?? {};
  const { organizationId } = organization ?? {};

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const subscriptionActivationDeactivation = async (
    values,
    { setStatus, resetForm },
  ) => {
    const subscription = { active: !active };

    const { etag: subscriptionEtag } = await getSubscription(
      organizationId,
      subscriptionId,
      accessToken,
    );

    const { response } = await updateSubscription(
      organizationId,
      subscriptionId,
      subscription,
      subscriptionEtag,
      accessToken,
    );

    const action = !active ? 'activated.' : 'deactivated.';

    switch (response?.status) {
      case 200:
        setStatus({
          isError: false,
          message: `${subscriptionName} has been successfully ${action}`,
          date: Date.now(),
        });
        closeModal.current = { close: true }; //Conditional to close modal in the create organization modal component. Have to be before resetform to cause a rerender
        resetForm(
          active
            ? SubscriptionSwitchInitialValues
            : SubscriptionSwitchEmptyInitialValues,
        );
        setRedirect({
          isRedirect: true,
          data: { organizationId },
        });
        break;
      default:
        setStatus({
          isError: true,
          message: action, //Passing action to set the appropriate error messages for activation, deactivation and renaming
          date: Date.now(),
        });
        break;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={
        active
          ? SubscriptionSwitchInitialValues
          : SubscriptionSwitchEmptyInitialValues
      }
      validationSchema={
        active ? SubscriptionSwitchSchema : SubscriptionSwitchEmptySchema
      }
      onSubmit={subscriptionActivationDeactivation}
      children={props => (
        <SubscriptionSwitchModal
          {...props}
          closeModal={closeModal}
          active={active}
          closeMenu={closeMenu}
          subscriptionName={subscriptionName}
        />
      )}
    />
  );
};

export default SubscriptionSwitch;
