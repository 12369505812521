import { BUILD_ID, BUILD_NUMBER, COMMIT_HASH } from 'utils/dictionary/env';

const Health = () => {
  return (
    <div style={{ margin: '10px', padding: '10px' }}>
      <div id="CommitHash">Commit Hash: {COMMIT_HASH}</div>
      <div id="BuildNumber">Build Number: {BUILD_NUMBER}</div>
      <div id="BuildId">Build Id: {BUILD_ID}</div>
    </div>
  );
};

export default Health;
