import React, { useState, useEffect, Fragment } from 'react';
import { Button, Typography, Checkbox } from '@mui/material';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogSectionTitle,
  DialogSubSectionText,
  Divider,
} from 'components/dialog/dialog';
import {
  GeneralCancelButton,
  GreenGeneralButton,
} from 'components/buttons/button';
import { SettingsIcon } from 'components/icons/icons';
import { NotificationContext } from 'contexts/notificationContext';
import ModuleCheckboxV2 from 'components/organizationManagement/editSubscriptionConfigureProductsModal/ModuleCheckboxV2';
import FeatureCheckbox from 'components/organizationManagement/editSubscriptionConfigureProductsModal/FeatureCheckbox';
import style from './editSubscriptionConfigureProductsModal.module.scss';

const getConcepts = (assets, assetKey) => {
  const concepts = assets?.[assetKey]?.add
    ?.flatMap(item => item?.concepts)
    ?.filter(item => item);
  return concepts ?? [];
};

const ModuleSection = props => {
  const [checked, setChecked] = useState({});

  const {
    selectedModules,
    filteredModules,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = props;

  useEffect(() => {
    Object.keys(selectedModules).forEach(moduleId => {
      setChecked(currentState => {
        return {
          ...currentState,
          [moduleId]: { checked: true },
        };
      });
    });
  }, [setChecked, selectedModules]);

  function commaSeparate(applications) {
    return applications
      .map(element => {
        return element?.name;
      })
      .join(', ');
  }

  return (
    <>
      {errors.modules && touched.modules ? (
        <Typography className={style.errorMessage}>
          {errors.modules.module}
        </Typography>
      ) : null}

      {filteredModules.map((module, index) => {
        return (
          <Fragment key={index}>
            <div className={style.checkboxContainer}>
              <ModuleCheckboxV2
                {...props}
                selectedModules={selectedModules}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                module={module}
                setChecked={setChecked}
                checked={checked}
                name="modules"
                className={style.checkBoxStyle}
              />
              <Typography
                display="inline"
                align="left"
                className={`${style.moduleText} ${style.boldText} `}
              >
                {module.name}
              </Typography>
            </div>
            <div className={style.applicationSection}>
              Includes: {commaSeparate(module.applications)}
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

const FeatureSection = props => {
  const { selectedModules, filteredModules, setFieldValue, setFieldTouched } =
    props;

  return filteredModules.map((module, index) => {
    return (
      <Fragment key={index}>
        {module.applications.map((application, index2) => {
          return (
            <Fragment key={index2}>
              {application?.features?.optional?.map((optFeature, index3) => {
                return (
                  <div className={style.checkboxContainer} key={index3}>
                    <FeatureCheckbox
                      {...props}
                      selectedModules={selectedModules}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      module={module}
                      feature={optFeature}
                      applicationId={application.id}
                      name={optFeature.name}
                      className={style.checkBoxStyle}
                    />
                    <Typography
                      display="inline"
                      align="left"
                      className={`${style.moduleText} ${style.boldText} `}
                    >
                      {application?.name}&nbsp;
                    </Typography>
                    <Typography display="inline" className={style.moduleText}>
                      - {optFeature?.name}
                    </Typography>
                  </div>
                );
              })}
            </Fragment>
          );
        })}
      </Fragment>
    );
  });
};

const ContentSection = props => {
  const {
    licenseAssets,
    availableAssets,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
  } = props;

  const [selected, setSelected] = useState(
    JSON.parse(JSON.stringify(licenseAssets)),
  );
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    Object.keys(availableAssets).forEach(key => {
      getConcepts(availableAssets, key)?.forEach(item => {
        let hasSelected = selected?.[key]?.add?.[0]?.concepts.findIndex(
          x => x.conceptId === item.conceptId,
        );
        setChecked(currentState => {
          return {
            ...currentState,
            [item.conceptId]: {
              checked: hasSelected !== undefined && hasSelected !== -1,
            },
          };
        });
      });
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setChecked, selected]);

  const setAssets = (concept, assetKey, add) => {
    let copy = { ...selected };

    let hasNew =
      Object.keys(copy).length === 0 ||
      copy[assetKey]?.add[0]?.concepts === undefined;

    let concepts = hasNew ? [] : copy[assetKey].add[0].concepts;

    if (add) {
      concepts.push(concept);
    } else {
      let index = concepts.findIndex(x => x.conceptId === concept.conceptId);
      concepts.splice(index, 1);
    }

    let addObj = {
      add: [
        {
          ...availableAssets?.[assetKey]?.add?.[0],
          concepts,
        },
      ],
    };

    if (hasNew) {
      setSelected(currentState => {
        return {
          ...currentState,
          [assetKey]: addObj,
        };
      });
    } else {
      setSelected(copy);
    }

    let assets = Object.assign({}, props.values.assets);

    if (concepts.length === 0) {
      delete assets[assetKey];
    } else {
      assets[assetKey] = addObj;
    }
    setFieldTouched(`assets[${assetKey}]`);
    setFieldValue('assets', assets);
  };

  return (
    availableAssets && (
      <>
        {errors.assets && touched.assets ? (
          <Typography className={style.errorMessage}>
            {errors.assets.asset}
          </Typography>
        ) : null}
        {Object.keys(availableAssets)?.map((assetKey, index) => {
          let concepts = getConcepts(availableAssets, assetKey);
          return (
            <Fragment key={index}>
              {concepts.map((item, index2) => {
                return (
                  <div className={style.checkboxContainer} key={index2}>
                    <Checkbox
                      color="primary"
                      aria-label={item.name}
                      name={item.name}
                      checked={checked[item.conceptId]?.checked || false}
                      onChange={event => {
                        setAssets(item, assetKey, event.target.checked);
                      }}
                      className={style.checkBoxStyle}
                    />
                    <Typography
                      display="inline"
                      align="left"
                      className={style.moduleText}
                    >
                      {item?.name}
                    </Typography>
                  </div>
                );
              })}
            </Fragment>
          );
        })}
      </>
    )
  );
};

const EditSubscriptionConfigureProductsModalV2 = props => {
  const {
    modalButtonText,
    closeModal,
    isValid,
    isSubmitting,
    status,
    subscription,
    resetFormOnClose,
  } = props;

  const [open, setOpen] = useState(false);
  const { success, message = '' } = status ?? {};
  const notificationContext = React.useContext(NotificationContext);
  const { subscriptionTypeId } = subscription ?? {};
  let counter = 1;

  useEffect(() => {
    if (message) {
      notificationContext.showNotification(message, !success, 5000);
      status.message = null;
    }
  }, [success, message, notificationContext, status]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetFormOnClose();
  };

  const moduleFilter =
    subscription?.subscriptionTypeId === 5 // If it's a management subscription
      ? module => module.isPlatform ?? true // Then show only Platform Modules
      : module => !(module.isPlatform ?? false); // Otherwise, hide only Platform Modules

  const countOptions = () => {
    let optionCount = 0;
    props.availableModules?.filter(moduleFilter).forEach(item => {
      optionCount += item.applications?.reduce(
        (total, app) => total + app?.features?.optional?.length,
        0,
      );
    });
    return optionCount;
  };

  //Close modal when form is submitted successfully
  //Couldn't think of a better way to implement this
  if (closeModal.current.close) {
    closeModal.current = { close: false };
    setOpen(false);
  }

  return (
    <>
      <Button onClick={handleOpen}>
        <SettingsIcon style={{ fill: '#0D8484' }} />
        <Typography className={style.modalButtonText}>
          {modalButtonText}
        </Typography>
      </Button>

      <Dialog
        aria-labelledby="dialog-title"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        scroll={'body'}
        transitionDuration={500}
      >
        <Form>
          <DialogTitle id="dialog-title">Manage Subscription</DialogTitle>
          <DialogContent className={style.dialogContent}>
            {/* Choose Modules */}

            <DialogSectionTitle>{counter++}. Choose Modules</DialogSectionTitle>
            <DialogSubSectionText>
              Modules are a set of Healthwise applications that form a solution.
              Each subscription must include at least 1 module.
            </DialogSubSectionText>
            <ModuleSection
              {...props}
              filteredModules={props.availableModules?.filter(moduleFilter)}
            />

            {/* Choose Features */}
            {countOptions() === 0 ? null : (
              <>
                {/* Divider */}
                <div className={style.gridItemDividr}>
                  <Divider />
                </div>
                <DialogSectionTitle>
                  {counter++}. Select Optional Features
                </DialogSectionTitle>
                <DialogSubSectionText>
                  Optional features are add-ons that can be enabled for a
                  specific Healthwise application.
                </DialogSubSectionText>
                <FeatureSection
                  {...props}
                  filteredModules={props.availableModules?.filter(moduleFilter)}
                />
              </>
            )}

            {/* Select Content */}
            {subscriptionTypeId !== 5 ? (
              <>
                {/* Divider */}
                <div className={style.gridItemDividr}>
                  <Divider />
                </div>
                <DialogSectionTitle>
                  {counter++}. Select Content
                </DialogSectionTitle>
                <DialogSubSectionText>
                  At least 1 content package needs to be selected for a
                  subscription.
                </DialogSubSectionText>
                <ContentSection {...props} />
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            <GeneralCancelButton
              handleClick={handleClose}
              disabled={isSubmitting}
            >
              Cancel
            </GeneralCancelButton>
            <GreenGeneralButton
              variant="contained"
              type="submit"
              disabled={!isValid || isSubmitting}
              buttonText="Save Changes"
            ></GreenGeneralButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

EditSubscriptionConfigureProductsModalV2.propTypes = {
  modalButtonText: PropTypes.string.isRequired,
  closeModal: PropTypes.shape({
    current: PropTypes.shape({
      close: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EditSubscriptionConfigureProductsModalV2;
