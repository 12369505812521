import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import style from './card.module.scss';

export const UserCard = props => {
  return <Card className={style.userCard} {...props} />;
};

export const UserCardHeader = props => {
  return <CardHeader className={style.userCardHeader} {...props} />;
};

export const UserCardContent = props => {
  return <CardContent className={style.userCardContent} {...props} />;
};

export const UserCardv2 = props => {
  const { className = '', ...otherProps } = props;
  return (
    <Card className={`${style.userCardv2} ${className}`} {...otherProps} />
  );
};

export const UserCardHeaderv2 = props => {
  const { className = '', ...otherProps } = props;
  return (
    <CardHeader
      className={`${style.userCardHeaderv2} ${className}`}
      {...otherProps}
    />
  );
};

export const UserCardContentv2 = props => {
  return <CardContent className={style.userCardContentv2} {...props} />;
};

export const OrgCard = props => {
  return <Card className={style.orgCard} {...props} />;
};

export const OrgCardHeader = props => {
  return <CardHeader className={style.orgCardHeader} {...props} />;
};

export const OrgCardHeaderText = props => {
  return <Typography className={style.orgCardHeaderText} {...props} />;
};

export const StyledCardContent = props => {
  return <CardContent className={style.styledCardContent} {...props} />;
};

export const StyledCard = props => {
  return <Card className={style.styledCard} {...props} />;
};

export const Panel = props => {
  return <Card className={style.panel} {...props} />;
};

export const ClickableCard = props => {
  // props are link, title, description, iconLink, and iconAlt
  const { link, title, description, icon } = props;
  return (
    <Card className={style.accountOverviewCard} variant="outlined">
      <CardActionArea
        className={style.cardActionArea}
        component={Link}
        to={link}
      >
        <StyledCardContent>
          <div style={{ display: 'flex' }}>
            <div className={style.icon}>{icon}</div>

            <div style={{ flex: '1' }}>
              <Typography className={style.cardHeaderText} component="h5">
                {title}
              </Typography>

              <Typography className={style.lightGrayType} component="h6">
                {description}
              </Typography>
            </div>
          </div>
        </StyledCardContent>
      </CardActionArea>
    </Card>
  );
};
ClickableCard.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node,
};
