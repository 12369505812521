import React, { useState, useContext } from 'react';
import OrganizationNotes from 'components/organizationManagement/organizationNotes/organizationNotes';
import {
  OrganizationNotesSchema,
  FormInitialValues,
} from 'components/organizationManagement/organizationNotes/formSchemaAndInitialValues';
import { Formik } from 'formik';
import { EDIT_ORGANIZATION_PATH } from 'utils/configuration/links';
import { Redirect } from 'react-router-dom';
import { updateOrganization } from 'utils/api/organizations';
import { AuthContext } from 'contexts/authContext';
import { formatISO } from 'date-fns';

const EditOrganizationNotes = props => {
  const [redirect, setRedirect] = useState({ isRedirect: false, data: {} });
  const { accessToken, user } = useContext(AuthContext);
  const { organizationToEdit, organizationEtag } = props ?? {};

  const { organizationId = '', notes } = organizationToEdit ?? {};
  const { name } = user ?? {};

  if (redirect.isRedirect) {
    return (
      <Redirect
        push
        to={{
          pathname: EDIT_ORGANIZATION_PATH,
          state: { organizationId: redirect?.data?.organizationId },
        }}
      />
    );
  }

  const sendNote = async (values, { setStatus }) => {
    const currentNote = {
      text: values?.note ?? '',
      updatedDate: formatISO(new Date()),
      updatedBy: name,
    };

    const organizationNotes = { notes: [...notes, currentNote] };

    const { response } = await updateOrganization(
      organizationId,
      organizationNotes,
      organizationEtag,
      accessToken,
    );

    //TODO: Need more work, not there yet
    switch (response?.status) {
      case 200:
        setStatus({ success: true });
        setRedirect({ isRedirect: true, data: { organizationId } });
        break;
      case 412:
        setStatus({
          success: false,
          message: 'Error. Please refresh the page',
        });
        break;
      case 500:
        setStatus({ success: false, message: 'Internal Server Error' });
        break;
      default:
        if (!response) {
          setStatus({ success: false, message: 'Network error' });
        } else {
          setStatus({
            success: false,
            message: 'Unknown error. Please refresh the page.',
          });
        }
        break;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={FormInitialValues}
      validationSchema={OrganizationNotesSchema}
      onSubmit={sendNote}
      children={props => <OrganizationNotes {...props} />}
    />
  );
};

export default EditOrganizationNotes;
