import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  GeneralCancelButton,
  ConfirmationModalButton,
} from 'components/buttons/button';
import { DEFAULT_BTN_COLOR_CONFIRM_GREEN } from 'utils/dictionary/overview';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import style from './confirmationModal.module.scss';

const ConfirmationModal = props => {
  const {
    personFullName,
    confirmText,
    handleClose,
    handleCancel,
    handleConfirm,
    open,
    text,
    title,
    email,
    color = DEFAULT_BTN_COLOR_CONFIRM_GREEN,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const internalConfirmationHandler = async eventHandler => {
    setSubmitting(true);
    await eventHandler();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle className={style.title} id="dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={style.bodyText} id="dialog-description">
            <span className={style.fullName}>{personFullName}</span>
            {email}
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={style.rightAlignedButtonGroup}>
          <GeneralCancelButton
            handleClick={handleCancel}
            disableRipple
          ></GeneralCancelButton>
          {submitting ? (
            <ProgressIndicator />
          ) : (
            <ConfirmationModalButton
              handleClick={() => {
                internalConfirmationHandler(handleConfirm);
              }}
              buttonText={confirmText}
              backgroundColor={color}
              disableRipple
            >
              {confirmText}
            </ConfirmationModalButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  confirmText: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  color: PropTypes.string,
  personFullName: PropTypes.string,
  email: PropTypes.string,
};
