import React from 'react';
import { Box } from '@mui/material';
import OrganizationManagementList from 'pages/organizations/components/organizationManagementList/organizationManagementList';
import CreateOrganization from 'containers/organizationManagement/createOrganization';
import { useGetOrganizations } from 'utils/hooks/organization/useGetOrganizations';
import { useGetOrganizationCategories } from 'utils/hooks/useToCallApiEndpoints';

const OrganizationList = () => {
  //TODO: Implement pagination and displaying error to user

  // `data` will always be a valid array, athough it _could_ be empty...
  const response = useGetOrganizations();
  const organizations = response.data;
  const finished = response.finished;
  const { data: categories } = useGetOrganizationCategories();

  return (
    <Box>
      <OrganizationManagementList
        bannerButton={
          <CreateOrganization
            modalButtonText="Create New Organization"
            organizationCategories={categories}
          />
        }
        listOfOrganizations={organizations}
        organizationsRetrieved={finished}
        organizationCategories={categories}
      />
    </Box>
  );
};
export default OrganizationList;
