import React, { useEffect, useState, useContext } from 'react';
import style from './banner.module.scss';
import { ArrowDropDownIcon, ArrowRightIcon } from 'components/icons/icons';
import {
  Link as MaterialLink,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { getAllUserSubscriptions } from 'utils/api/subscriptions';
import { AuthContext } from 'contexts/authContext';
import * as OVERVIEW from 'utils/dictionary/overview';
import { REACT_APP_OKTA_CLIENT_ID } from 'utils/dictionary/env';

export const Banner = props => {
  const { organization } = props;
  const [open, setOpen] = useState(false);
  const { accessToken, permissions, loading } = useContext(AuthContext);
  const { userId } = permissions ?? {};
  const [anchorEl, setAnchorEl] = useState(null);
  const [fullyQualifiedName, setFullyQualifiedName] = useState(null);
  const [mgtSubscriptions, setManagementSubscriptions] = useState([]);
  const organizationId = organization?.organizationId;

  //Filtering only "management" type subscriptions from the API call
  const getManagementApplications = data => {
    return data?.filter(item => item.sourceId === REACT_APP_OKTA_CLIENT_ID);
  };

  useEffect(() => {
    //Filter out the subscription to which the user has currently logged in
    function getFilteredSubscriptions(subscriptions) {
      return subscriptions?.filter(
        item => item.organizationId !== organizationId,
      );
    }

    const response = async () => {
      const { data, response } = await getAllUserSubscriptions(accessToken);

      if (response?.ok) {
        const managementSubscriptons = getManagementApplications(data);
        let subscriptionsArray = [];

        const { subscriptions, fullyQualifiedName } = managementSubscriptons[0];
        subscriptionsArray = getFilteredSubscriptions(subscriptions);
        setFullyQualifiedName(fullyQualifiedName);
        setManagementSubscriptions(subscriptionsArray);
      }
    };

    if (!loading) {
      response();
    }
  }, [userId, accessToken, loading, organizationId]);

  const handleClick = e => {
    setOpen(!open);

    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const goToApplication = (url, subscriptionId) => {
    const path = `${url}${OVERVIEW.EXTERNAL_RELOAD_LOGIN_PATH}?subscription_id=${subscriptionId}`;
    window.location.assign(path);
  };

  return (
    <>
      <div className={style.banner}>
        <span className={style.bannerTitle}>
          {organization.organizationName}
        </span>
        <br></br>
        {mgtSubscriptions?.length > 0 ? (
          <span className={style.switchOrg}>
            <IconButton
              aria-controls="switchOrganization"
              aria-label={open ? 'angle up' : 'angle down'}
              aria-haspopup="true"
              onClick={handleClick}
              className={style.switchOrgIcon}
            >
              {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
            <MaterialLink
              onClick={handleClick}
              color="inherit"
              className={style.switchOrgLink}
              tabIndex={-1}
            >
              Switch Organizations
            </MaterialLink>
            <Menu
              id="sub-menu"
              keepMounted
              className={style.switchOrgMenu}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  boxShadow: '0px 3px 6px #00000029',
                  borderRadius: '4px',
                  color: '#0D8484',
                  width: '200px',
                  paddingTop: '5px',
                },
              }}
            >
              {mgtSubscriptions.map(item => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  selected={false}
                  onClick={() => goToApplication(fullyQualifiedName, item.id)}
                  style={{ whiteSpace: 'normal', backgroundColor: '#ffffff' }}
                  className={style.menuitem}
                >
                  {item.organizationName}
                </MenuItem>
              ))}
            </Menu>
          </span>
        ) : (
          <span></span>
        )}
      </div>
    </>
  );
};

export default Banner;
