import * as configuration from 'utils/configuration/delegates';
import * as utils from 'utils/api/common/invocationUtils';
import { REACT_APP_API_BASE_URL } from 'utils/dictionary/env';

const baseUrl = REACT_APP_API_BASE_URL;
const delegatesUrl = baseUrl + configuration.DELEGATES_ROOT;

/*

Done:
GET /delegates/current
GET /delegates/available
PUT /delegates/{{userId}}/groups -takes array of strings as body (array of okta group ids: ["00gsk4ht5tXkwvUSf0h7","00gra2umhzF6eHjkN0h7"])

*/

export async function getDelegatesCurrent(accessToken) {
  return await utils.getResource(`${delegatesUrl}/current`, accessToken);
}

export async function getDelegatesAvailable(accessToken) {
  return await utils.getResource(`${delegatesUrl}/available`, accessToken);
}

export async function putDelegatesCurrent(userId, roles, accessToken) {
  return await utils.putResource(
    `${delegatesUrl}/${userId}/roles`,
    roles,
    accessToken,
  );
}
