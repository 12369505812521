import * as Yup from 'yup';

export const CreateOrganizationAdministratorSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  firstName: Yup.string()
    .max(15, 'Must be 15 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(15, 'Must be 15 character or less')
    .required('Required'),
});

export const FormInitialValues = {
  email: '',
  firstName: '',
  lastName: '',
};
