import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';

import { IconButton, Card, CardContent } from '@mui/material';

import { AuthContext } from 'contexts/authContext';
import { EditIcon } from 'components/icons/icons';
import {
  CancelButton,
  GreenGeneralButton,
  GreenButtonLink,
} from 'components/buttons/button';
import Notification from 'components/notificationV2/notification';
import { ProgressIndicator } from 'components/progressIndicator/progressIndicator';
import { BaselineTextInput } from 'components/textInputV2/textInput';

import * as OVERVIEW from 'utils/dictionary/overview';
import { getOrganization, updateOrganization } from 'utils/api/organizations';
import style from './editableOrganizationHeader.module.scss';
import btnStyle from 'components/buttons/button.module.scss';
const RenameAccountButtonLink = props => {
  const { setEditable } = props;
  return (
    <IconButton
      color="primary"
      aria-label="rename organization"
      onClick={() => {
        setEditable(true);
      }}
    >
      <EditIcon />
    </IconButton>
  );
};

async function UpdateOrganizationName(values, accessToken) {
  let { id, name } = values;

  let { etag, response } = await getOrganization(id, accessToken);

  if (response.ok) {
    let body = {
      OrganizationName: name,
    };

    let updatedResponse = await updateOrganization(id, body, etag, accessToken);
    // TODO: Adding in the ? while the appservice isn't updated yet
    if (!updatedResponse?.response?.ok) {
      throw new Error(`Unable to rename the organization to ${name}`);
    }
  } else {
    throw new Error(`Unable to retrieve the organization ${id}`);
  }
}

const EditableHeader = props => {
  const { title, id, setError, setSuccess, setEditable, setOrganizationName } =
    props;
  const { accessToken } = useContext(AuthContext);

  const SubmitNameChange = async values => {
    if (values.name !== title) {
      try {
        await UpdateOrganizationName(values, accessToken);
        setOrganizationName(values.name);
        setEditable(false);
        setSuccess('Saved organization name');
      } catch (error) {
        setOrganizationName(title);
        setError(error.message);
      }
    }
  };

  return (
    <Formik initialValues={{ id: id, name: title }} onSubmit={SubmitNameChange}>
      {formikProps => {
        return (
          <Form>
            <div className={style.wrapper}>
              <BaselineTextInput
                type="text"
                name="name"
                className={style.textField}
              />
              {formikProps.isSubmitting ? (
                <ProgressIndicator />
              ) : (
                <>
                  <div className={style.orgHeader}>
                    <GreenGeneralButton
                      buttonText="Rename Organization"
                      disabled={!formikProps.dirty}
                      type="submit"
                    />
                    <CancelButton
                      className={style.cancelButton}
                      buttonText="Cancel"
                      handleClick={() => {
                        setEditable(false);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const OrganizationHeader = props => {
  const { title, id, setEditable } = props;
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [subId, setSubId] = useState('');
  const { adminOrgs } = useContext(AuthContext);

  useEffect(() => {
    if (adminOrgs) {
      const orgAdmin = adminOrgs.filter(org => org.organizationId === id);
      if (orgAdmin.length === 1) {
        setIsOrgAdmin(true);
        setSubId(orgAdmin[0].subscriptionId);
      }
    }
  }, [adminOrgs, id]);

  return (
    <>
      <h1 className={style.title}>{title}</h1>
      {OVERVIEW.HEALTHWISE_ORG !== title && (
        <RenameAccountButtonLink
          id={id}
          name={title}
          setEditable={setEditable}
        />
      )}
      {isOrgAdmin && (
        <GreenButtonLink
          to={`${OVERVIEW.EXTERNAL_RELOAD_LOGIN_PATH}?subscription_id=${subId}`}
          className={`${btnStyle.button} ${btnStyle.outlineButtonGreen} ${style.orgAdminBtn}`}
        >
          View as Organization Administrator
        </GreenButtonLink>
      )}
    </>
  );
};

const EditableOrganizationHeader = props => {
  const { organizationId, organizationName } = props;
  const [editableHeader, setEditableHeader] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState(organizationName);

  useEffect(() => {
    setName(organizationName);
  }, [organizationName]);

  return (
    <>
      {error ? <Notification error message={error} /> : null}
      {success ? <Notification message={success} /> : null}
      <Card className={style.card}>
        <CardContent className={style.cardContent}>
          {editableHeader ? (
            <EditableHeader
              title={name}
              id={organizationId}
              setEditable={setEditableHeader}
              setOrganizationName={setName}
              setError={setError}
              setSuccess={setSuccess}
            />
          ) : (
            <OrganizationHeader
              title={name}
              id={organizationId}
              setEditable={setEditableHeader}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default EditableOrganizationHeader;
