import * as Yup from 'yup';

export const editSubscriptionSchema = Yup.object().shape({
  subscriptionName: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
});

export const editSubscriptionFormInitialValues = (subscriptionName = '') => {
  return {
    subscriptionName,
  };
};

export const OrganizationSubscriptionSchema = Yup.object().shape({
  subscriptionName: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
  selectEnvironment: Yup.object().required('Select an Environment').nullable(),
});

export const FormInitialValues = (subscriptionName = '', selectEnvironment) => {
  return {
    subscriptionName,
    selectEnvironment,
  };
};
