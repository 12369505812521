import React, { useEffect, useState } from 'react';
import OrganizationManagementEditOrganization from 'components/organizationManagement/organizationManagementEditOrganization/organizationManagementEditOrganization';
import {
  useGetOrganization,
  useRetrieveEntitlementPacket,
  useGetOrganizationAdministrators,
  useGetSubscriptionTypes,
} from 'utils/hooks/useToCallApiEndpoints';

import EditOrganizationSubscription from 'containers/organizationManagement/editOrganizationSubscription/editOrganizationSubscription';
import OrganizationSubscriptionDetailPanel from 'containers/organizationManagement/editOrganization/organizationSubscriptionDetailPanel/organizationSubscriptionDetailPanel';

import EditOrganizationNotes from 'containers/organizationManagement/editOrganizationNotes/editOrganizationNotes';

import DeleteOrganization from 'containers/organizationManagement/deleteOrganization';
import DeleteOrganizationNotes from 'containers/organizationManagement/deleteOrganizationNotes';

const setCompleteOrganizationState = (
  organization,
  organizationToEditLoading,
) => ({
  organization,
  organizationToEditLoading,
});

const EditOrganization = props => {
  const [completeOrganization, setCompleteOrganization] = useState(
    setCompleteOrganizationState({}, true),
  );

  const [administrators, setAdministrators] = useState([]);

  // orgToEdit is the orgId.
  const { organizationToEdit } = props;

  //TODO: Display error to user
  // TODO BUG - Need to restrict the subscriptions returned by useGetOrganization to only non-management if user isn't a provisioning manager. See useGetPaginatedSubscriptions for more details.
  const {
    data: organization,
    etag: organizationEtag,
    isLoading: organizationLoading,
  } = useGetOrganization(organizationToEdit);

  const { data: entitlementPacket, isLoading: entitlementPacketLoading } =
    useRetrieveEntitlementPacket(organizationToEdit);

  const {
    data: administratorsData,
    isLoading: organizationAdministratorsLoading,
  } = useGetOrganizationAdministrators(organizationToEdit);

  useEffect(() => {
    setAdministrators(administratorsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationAdministratorsLoading]);

  //TODO: Display error to user
  const { data: subscriptionTypes, isLoading: subscriptionTypesLoading } =
    useGetSubscriptionTypes();

  // Inject the subscription type name into the organization object
  useEffect(() => {
    const setupCompleteOrganization = async () => {
      var subscriptions = organization?.data?.subscriptions;
      subscriptions?.forEach(subscription => {
        var particularSubscriptionType = subscriptionTypes.find(
          subscriptionType =>
            subscriptionType.subscriptionTypeId ===
            subscription.subscriptionTypeId,
        );
        if (particularSubscriptionType) {
          subscription['subscriptionType'] = particularSubscriptionType['name'];
        } else {
          // Just in case particularSubscriptionType isn't found
          subscription['subscriptionType'] = '';
        }
      });
      setCompleteOrganization(
        setCompleteOrganizationState(organization.data, false),
      );
    };

    if (!organizationLoading && !subscriptionTypesLoading) {
      setupCompleteOrganization();
    }
    // The organization object is being modified, so that causes an infinite loop. Adding
    // the subscriptionType to the return organization on the service side will solve this problem

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationLoading, subscriptionTypesLoading]);

  return (
    <OrganizationManagementEditOrganization
      organizationToEdit={completeOrganization}
      organizationEtag={organizationEtag}
      entitlementData={{ entitlementPacket, entitlementPacketLoading }}
      administratorsData={{ administrators, organizationAdministratorsLoading }}
      subscriptionTypesData={{ subscriptionTypes, subscriptionTypesLoading }}
      renderEditOrganizationSubscriptionModal={props => {
        const {
          subscription,
          environments,
          identityProviders,
          productionSubscriptionsLimitInfo,
          organizationId,
          closeMenu,
        } = props;
        return (
          <EditOrganizationSubscription
            subscription={subscription}
            environments={environments}
            identityProviders={identityProviders}
            productionSubscriptionsLimitInfo={productionSubscriptionsLimitInfo}
            organizationId={organizationId}
            closeMenu={closeMenu}
          />
        );
      }}
      renderSubscriptionDetailPanel={props => {
        return <OrganizationSubscriptionDetailPanel {...props} />;
      }}
      renderOrganizationNotes={props => {
        return (
          <EditOrganizationNotes
            {...props}
            organizationEtag={organizationEtag}
          />
        );
      }}
      renderDeleteOrganization={props => {
        return (
          <DeleteOrganization {...props} organizationEtag={organizationEtag} />
        );
      }}
      renderDeleteSaveOrganizationNotes={props => {
        return (
          <DeleteOrganizationNotes
            {...props}
            organizationEtag={organizationEtag}
          />
        );
      }}
    />
  );
};

export default EditOrganization;
