const getStatusName = name => {
  // Backend statuses:
  //
  // UNKNOWN,
  // ACTIVE,
  // DEPROVISIONED,
  // LOCKED_OUT,
  // PROVISIONED,
  // RECOVERY,
  // STAGED,
  // SUSPENDED,
  // PASSWORD_EXPIRED

  switch (name.trim().toLowerCase()) {
    case 'unknown':
      return 'Unknown';
    case 'active':
      return 'Active';
    case 'deprovisioned':
      return 'Removed';
    case 'locked_out':
      return 'Locked Out';
    case 'provisioned':
      return 'Invite Sent';
    case 'recovery':
      return 'Password Recovery';
    case 'staged':
      return 'Resend Invite';
    case 'suspended':
      return 'Inactive';
    case 'password_expired':
      return 'Password Expired';
    default:
      return name;
  }
};

export { getStatusName };
